/**
 * @category Utils
 * @packageDocumentation
 */
import { RateDisplayModel } from 'common/backend/api/general/generalModel';
import { HotelDeal } from 'common/backend/api/hotel/dealModel';
import { PriceRoundingMethod } from 'backend/api/general/generalModel';
import { CurrencyConf } from 'backend/dataModel';

/**
 * Mapping of escaped html codes to their original string representation
 * @param str
 */
const escapedToHTML = (str: string) => {
  switch (str) {
    case '&amp;':
      return '&';
    case '&quot;':
      return '"';
    case '&lt;':
      return '<';
    case '&gt;':
      return '>';
    default:
      return '';
  }
};

export const getRoundingFunction = (roundMethod: PriceRoundingMethod | undefined) => {
  switch (roundMethod) {
    case PriceRoundingMethod.Ceiling:
      return Math.ceil;
    case PriceRoundingMethod.Floor:
      return Math.floor;
    case PriceRoundingMethod.Round:
    default:
      return Math.round;
  }
};

/**
 * Simple de-escaping method for displaying the (temporary?) raw html obtained from server
 * @param str
 */
export const unescapeHTML = (str: string | null | undefined) => {
  if (!str) {
    return '';
  }

  return str.replace(/(&quot;|&lt;|&gt;|&amp;)/g, (_: string, item: string) => escapedToHTML(item));
};

const DIGITS_AFTER_POINT = 2;

/**
 * Customizable function to print the price with a currency
 * @param amount - amount to print
 * @param conf - currency configuration for the locale
 * @param locale - language code for the locale
 * @param roundingFunction - a required rounding function (preventing the situation with large amount of digits after .)
 */
export function printMoney(
  conf: CurrencyConf,
  amount: number | null | undefined,
  locale: string,
  roundingFunction?: (x: number) => number,
): string {
  let am: number | string = amount || 0;
  const decimals: Intl.NumberFormatOptions = {};

  if (roundingFunction) {
    decimals.maximumFractionDigits = 0;
    decimals.minimumFractionDigits = 0;

    am = roundingFunction(am);
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: conf.code,
    ...decimals,
  }).format(am);
}

export function getTotalPrice(rateDisplayModel: RateDisplayModel, deal: HotelDeal | undefined): number | undefined {
  if (!deal) {
    return undefined;
  }

  switch (rateDisplayModel) {
    case RateDisplayModel.AllInclusive:
      return deal.totalPriceWithHotelFees;
    case RateDisplayModel.RoomAndFees:
      return deal.totalRoomsPriceWithHotelFees;
    case RateDisplayModel.Gross:
      return deal.totalPrice;
    case RateDisplayModel.Net:
      return deal.totalRoomsPrice;
    default:
      throw new Error(`Unknown RateDisplayModel ${rateDisplayModel}`);
  }
}

export function roundToNDecimalDigits(value: number, minimumFractionDigits: number = DIGITS_AFTER_POINT) {
  return +value.toFixed(minimumFractionDigits);
}

/**
 * A tool for default approach to display a price in some currency
 * @param conf
 * @param amount
 * @param locale
 * @param roundingMethod
 */
export function printWholeMoney(
  conf: CurrencyConf,
  amount: number | null | undefined,
  locale: string,
  roundingMethod: PriceRoundingMethod,
): string {
  return printMoney(conf, amount, locale, getRoundingFunction(roundingMethod));
}

export const notEmpty = <T>(value: T | undefined): value is T => value !== undefined;
