import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import piggy from 'assets/images/icons/piggy.svg';
import { Text } from 'components/common/Text/Text';
import { TextSize } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import StyledInner from 'components/home/SavingsCounterPanel/SavingsCounterPanel.styled';
import Styled from 'style/styles';

export const SavingsCounterPanel = () => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);

  return (
    <StyledInner.Card className="padding flex flex-column padding-small">
      {!isMobileLayout && <img src={piggy} alt="piggy" className="position-top-left margin-top" />}
      <Text size={TextSize.Large} className="margin-remove" tag="h3">
        {t('home.savings.counter', 'Savings Counter')}
      </Text>
      <Text
        size={TextSize.Small}
        className={`flex-center margin-auto ${isMobileLayout ? 'width-1-1' : 'width-1-2'}`}
        tag="div"
      >
        {t('home.savings.total', 'Our unique algorithm has saved 10+ million dollars and counting for our customers')}
      </Text>

      <div className="flex flex-center margin-medium-top">
        <Styled.Counter>$</Styled.Counter>
        <Styled.Counter>1</Styled.Counter>
        <Styled.Counter>0</Styled.Counter>
        <Styled.CounterSeparator>,</Styled.CounterSeparator>
        <Styled.Counter>9</Styled.Counter>
        <Styled.Counter>6</Styled.Counter>
        <Styled.Counter>8</Styled.Counter>
        <Styled.CounterSeparator>,</Styled.CounterSeparator>
        <Styled.Counter>7</Styled.Counter>
        <Styled.Counter>9</Styled.Counter>
        <Styled.Counter>5</Styled.Counter>
      </div>
    </StyledInner.Card>
  );
};
