import styled, { css } from 'styled-components';
import ButtonStyled from 'components/common/Button/Button.styled';
import media from 'style/media';
import { brandColorDarker } from 'style/variables';

const MobileForm = styled.form`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1;
`;

const MobileSuggestionField = styled.div`
  margin: 0 16px 16px;
`;

const MobileDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const MobileOccupancyPicker = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 1;
`;

const MobileSearchButton = styled.div`
  margin: 16px 16px;
`;

const SearchButtonWrap = styled.div`
  width: 100%;
  height: 100%;

  ${ButtonStyled.DefaultButton}, ${ButtonStyled.PrimaryButton} {
    width: 100%;
    height: 100%;

    &:focus {
      border-color: ${brandColorDarker};
    }

    ${media.mobile} {
      height: 40px;
    }
  }
`;

const Container = styled.div<{ isSticky: boolean }>`
  z-index: 980;
  box-sizing: border-box;
  margin: 0;
  backface-visibility: hidden;
  top: 0;
  width: 100%;
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: fixed;
    `}
`;

export const SearchFormStyled = {
  MobileForm,
  MobileSuggestionField,
  MobileOccupancyPicker,
  MobileDatePicker,
  MobileSearchButton,
  SearchButtonWrap,
  Container,
};
