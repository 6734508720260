import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import callCenterPhone from 'assets/images/icons/call-center-phone.svg';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import Styled from 'components/header-footer/CallCenterBanner.styled';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import { routes } from 'routeList';

const CallCenterBanner = () => {
  const contactTranslations = useContactTranslations();
  const { isMobileLayout } = useContext(LayoutContext);
  const { pageType } = useContext(RouteDataContext);
  const isSpecialDiscountLinkVisible = useMemo<boolean>(() => {
    return [routes.destination.pageType, routes.checkout.pageType].includes(pageType);
  }, [pageType]);

  if (!isSpecialDiscountLinkVisible) {
    return null;
  }

  const phone = isMobileLayout ? (
    <Styled.Phone>{contactTranslations.discountPhone}</Styled.Phone>
  ) : (
    <a href={`tel:${contactTranslations.discountPhone}`} rel="noopener noreferrer">
      <Styled.Phone>{contactTranslations.discountPhone}</Styled.Phone>
    </a>
  );

  const content = (
    <Styled.Banner>
      <img src={callCenterPhone} alt="phone" />
      <Styled.TextContainer>
        <Styled.Text>
          <Trans i18nKey="hotel.special-discount">Special discount when you call</Trans>
        </Styled.Text>
        {phone}
      </Styled.TextContainer>
      {isMobileLayout && <Styled.MobileGap />}
    </Styled.Banner>
  );

  return isMobileLayout ? (
    <Styled.MobileLink
      href={`tel:${contactTranslations.discountPhone}`}
      rel="noopener noreferrer"
      className="margin-bottom"
    >
      {content}
    </Styled.MobileLink>
  ) : (
    content
  );
};

export default CallCenterBanner;
