/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { CardTypes } from 'components/common/Card/types';
import { rem } from 'style/functions';
import media from 'style/media';
import { borderColorLight, borderRadius, white, black, middleGray, disabledBackgroundGray } from 'style/variables';

// It is used in Card component and it has dependency on the Card component
const StyledCardTitle = styled.div``;
const StyledCardHeader = styled.div<{ small?: boolean }>`
  display: flow-root;
  padding: 15px 30px;

  ${media.desktop} {
    padding: ${rem(15)} ${rem(24)};
  }
  ${({ small }) =>
    small &&
    css`
      padding: 20px 20px;
    `}

  & > :last-child {
    margin-bottom: 0;
  }
`;
const StyledCardBody = styled.div`
  display: flow-root;
  padding: 30px 30px;
  ${media.desktop} {
    padding: ${rem(24)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
  & + & {
    border-top: 1px solid ${borderColorLight};
  }
`;
const StyledCardFooter = styled.div<{ small?: boolean }>`
  display: flow-root;
  padding: 15px 30px;

  ${media.desktop} {
    padding: ${rem(15)} ${rem(24)};
  }
  ${({ small }) =>
    small &&
    css`
      padding: 20px 20px;
    `}

  & > :last-child {
    margin-bottom: 0;
  }
`;

const StyledCard = styled.div<{ type?: CardTypes; hover?: boolean; small?: boolean }>`
  position: relative;
  box-sizing: border-box;
  border-radius: ${borderRadius};
  background: ${white};

  ${({ type = CardTypes.Default }) => {
    switch (type) {
      case CardTypes.Main:
        return css`
          border: 1px solid ${borderColorLight};
          background: ${white};
          color: ${black};
          ${StyledCardTitle} {
            color: ${middleGray};
          }
          ${StyledCardHeader} {
            border-bottom: 1px solid ${disabledBackgroundGray};
          }
          > .card-media-top {
            margin: -1px -1px 0 -1px;
            border-radius: ${borderRadius} ${borderRadius} 0 0;
          }
        `;

      case CardTypes.Default:
      default:
        return '';
    }
  }}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      }
    `}

    ${({ small }) =>
    small &&
    css`
      ${StyledCardBody} {
        padding: ${rem(15)};
      }
    `}
`;

export default {
  StyledCard,
  StyledCardTitle,
  StyledCardHeader,
  StyledCardBody,
  StyledCardFooter,
};
