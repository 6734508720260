/**
 * @category Utils
 * @packageDocumentation
 */

import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { useCallback, useContext } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import SearchStorage from 'backend/searchStorage';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { SearchFormQuery, SearchFormUrlParameters } from 'components/searchForm/query';
import { routes } from 'routeList';
import {
  createHotelDetailsUri,
  createHotelSearchUri,
  createReplaceSearchUri,
  HotelDetailsSearchUrlParameters,
} from 'utils/uriUtils';

/**
 * Hook which prepares callback to redirect to hotel list or hotel details page
 */
export default function useSearchFormCallback(placeId: string | undefined) {
  const history = useHistory();
  const location = useLocation();
  const hotelPath = !!matchPath(location.pathname, routes.hotels);
  const { isMobileLayout } = useContext(LayoutContext);

  const updateSearchQuery = useCallback(
    (
      checkin: string | undefined,
      checkout: string | undefined,
      occupancy: RoomOccupancy[],
      formParameters: SearchFormQuery,
    ) => {
      const newFormParameters: SearchFormQuery = {
        ...formParameters,
        checkin,
        checkout,
        occupancy: formParameters.occupancy || occupancy,
      };

      if (hotelPath) {
        history.replace({
          search: createReplaceSearchUri(location.search, {
            ...newFormParameters,
            dealKey: undefined,
            occupancy: JSON.stringify(newFormParameters.occupancy),
          } as HotelDetailsSearchUrlParameters),
        });
      } else {
        history.replace({
          search: createReplaceSearchUri(location.search, {
            ...newFormParameters,
            occupancy: JSON.stringify(newFormParameters.occupancy),
          } as SearchFormUrlParameters),
        });
      }

      return newFormParameters;
    },
    [history, hotelPath, location.search],
  );

  const onSearch = useCallback(
    (
      place: Place | undefined,
      bounds: string | undefined,
      checkin: string,
      checkout: string,
      occupancy: RoomOccupancy[],
    ) => {
      if (place && place.id === placeId && hotelPath) {
        history.push(
          createHotelDetailsUri(location.pathname, checkin, checkout, occupancy, undefined, undefined, undefined),
        );
      } else if (place) {
        const uri = createHotelSearchUri(place?.label, place?.id, undefined, checkin, checkout, occupancy);

        if (isMobileLayout && location.search.includes('bounds')) {
          history.replace(uri);
        } else {
          history.push(uri);
        }
      } else if (bounds) {
        history.replace(createHotelSearchUri(undefined, undefined, bounds, checkin, checkout, occupancy));
      }

      if (place) {
        SearchStorage.instance.store(place, checkin, checkout, occupancy);
      }
    },
    [history, hotelPath, isMobileLayout, location.pathname, location.search, placeId],
  );

  return { onSearch, updateSearchQuery };
}
