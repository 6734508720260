import styled from 'styled-components';
import { black, borderColorLightest, brandColorDark, darkGray, middleGray, gray } from 'style/variables';

const Navbar = styled.header`
  background: white;
  border-bottom: 1px solid ${borderColorLightest};
  z-index: 1000;
`;

const NavbarContent = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 40px;
  padding-right: 40px;
`;

const Menu = styled.div`
  display: flex;
  min-height: 72px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 20px;

  tab-index: 1;
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus {
    border-bottom-color: ${brandColorDark};
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none !important;
    color: ${black};

    &:active {
      color: ${middleGray};
    }
  }
`;

const DropdownList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    padding: 5px 0;
    color: ${gray};

    :hover,
    :active {
      color: ${darkGray};
    }
  }
`;

export default { Navbar, NavbarContent, Menu, MenuItem, DropdownList };
