import styled from 'styled-components';
import { LoaderBackgroundColor } from 'style/variables';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background: ${LoaderBackgroundColor};
`;

export default {
  Wrapper,
};
