export enum LanguageCode {
  EN = 'EN',
  DE = 'DE',
  FR = 'FR',
  ES = 'ES',
  IT = 'IT',
  PT = 'PT',
}

export const UNITED_STATES = 'US';
export const INSURANCE_EXCLUDED_STATES = ['NY', 'HI'];
