import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/common/Text/Text';
import { TextSize } from 'components/common/Text/Text.types';
import { AsSeenOnPanel } from 'components/home/AsSeenOnPanel/AsSeenOnPanel';
import { HomeAboutPanel } from 'components/home/HomeAboutPanel/HomeAboutPanel';
import HomeLocationWidget, { cityLocations } from 'components/home/HomeLocationWidget/HomeLocationWidget';
import { HomePriceGuaranteePanel } from 'components/home/HomePriceGuaranteePanel/HomePriceGuarantee';
import { LeadingHotelPanel } from 'components/home/LeadingHotelPanel/LeadingHotelPanel';
import { SavingsCounterPanel } from 'components/home/SavingsCounterPanel/SavingsCounterPanel';
import Styled from 'style/styles';

export const MobileHomeLocations = () => {
  const [t] = useTranslation();

  return (
    <Styled.HomeViewMobileContainer className="container">
      <div className="padding-large padding-remove-horizontal">
        <Text size={TextSize.Large} className="margin-remove" tag="h2">
          {t('home.trending-destinations', 'Trending Destinations')}
        </Text>

        <div className="grid-small grid grid-row-gap">
          <div className="width-1-3">
            <HomeLocationWidget locationData={cityLocations.paris} cityName={t('home.city.paris', 'Paris')} />
          </div>

          <div className="width-2-3">
            <div className="grid-small grid grid-row-gap">
              <div className="width-1-2">
                <HomePriceGuaranteePanel />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.london} cityName={t('home.city.london', 'London')} />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.sf} cityName={t('home.city.sf', 'San Francisco')} />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.barcelona} cityName={t('home.city.bc', 'Barcelona')} />
              </div>
            </div>
          </div>

          <div className="width-1-3">
            <HomeAboutPanel />
          </div>

          <div className="width-2-3">
            <div className="grid-small grid grid-row-gap">
              <div className="width-1-2">
                <HomeLocationWidget
                  locationData={cityLocations.lasvegas}
                  cityName={t('home.city.lasvegas', 'Las Vegas')}
                />
              </div>

              <div className="width-1-2">
                <LeadingHotelPanel />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.miami} cityName={t('home.city.miami', 'Miami')} />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.rome} cityName={t('home.city.rome', 'Rome')} />
              </div>
            </div>
          </div>

          <div className="width-2-3">
            <div className="grid-small grid grid-row-gap">
              <div className="width-1-2">
                <SavingsCounterPanel />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.newYork} cityName={t('home.city.ny', 'New York')} />
              </div>

              <div className="width-1-2">
                <HomeLocationWidget locationData={cityLocations.orlando} cityName={t('home.city.orlando', 'Orlando')} />
              </div>
            </div>
          </div>

          <div className="width-1-3">
            <AsSeenOnPanel />
          </div>
        </div>
      </div>
    </Styled.HomeViewMobileContainer>
  );
};
