import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ContactTranslations {
  emailUs: string;
  partnerWithUs: string;
  phone: string;
  discountPhone: string;
  career: string;
  [key: string]: string;
}

// eslint-disable-next-line import/prefer-default-export
export const useContactTranslations = (): ContactTranslations => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      emailUs: t('contact.emailUs', 'support@gosplitty.com'),
      partnerWithUs: t('contact.partnerWithUs', 'info@gosplitty.com'),
      phone: t('gosplitty.contact.phone', '+1 (888) 631-2106'),
      discountPhone: t('gosplitty.contact.discountPhone', '+1 (844) 441-0866'),
      career: t('contact.career', 'https://www.comeet.com/jobs/holisto/76.001'),
    }),
    [t],
  );
};
