/**
 * @category Search Form
 * @packageDocumentation
 */
import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import adultsIcon from 'assets/images/icons/adults.svg';
import minusIcon from 'assets/images/icons/searchbar/feather-minus-square.svg';
import plusIcon from 'assets/images/icons/searchbar/feather-plus-square.svg';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextTransform } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import Styled from 'components/searchForm/OccupancyPicker/OccupancyPicker.styled';
import PeoplePicker from 'components/searchForm/PeoplePicker/PeoplePicker';
import StyledPeoplePicker from 'components/searchForm/PeoplePicker.styles';
import { env } from 'environments/environment';
import { createOccupancyOrDefault, sumGuests } from 'utils/occupancyUtils';

interface OccupancyPickerProps {
  overflow: boolean;
}

/**
 * Allows to select a count of rooms and people.
 */
export const OccupancyPicker = ({ overflow }: OccupancyPickerProps) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { occupancy, setOccupancy } = useContext(SearchFormContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const occupancyChanger = useCallback(
    (increase: boolean) => {
      if (increase) {
        if (occupancy.length < env.searchBar.maxOccupancy) {
          setOccupancy([...occupancy, createOccupancyOrDefault(env.searchBar.defaultAdultsCount)]);
        }
      } else if (occupancy.length > 1) {
        setOccupancy(occupancy.slice(0, -1));
      }
    },
    [occupancy, setOccupancy],
  );

  const adultChanger = useCallback(
    (index: number, increase: boolean) => {
      if (increase) {
        if (occupancy[index].adults < env.searchBar.maxAdults) {
          setOccupancy(occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults + 1 } : o)));
        }
      } else if (occupancy[index].adults > 1) {
        setOccupancy(occupancy.map((o, idx) => (idx === index ? { ...o, adults: o.adults - 1 } : o)));
      }
    },
    [occupancy, setOccupancy],
  );

  const childrenChanger = useCallback(
    (index: number, increase: boolean) => {
      const children = occupancy[index].children || [];

      if (increase) {
        if (children.length < env.searchBar.maxChildren) {
          setOccupancy(
            occupancy.map((o, idx) =>
              idx === index
                ? {
                    ...o,
                    children: [...children, env.searchBar.defaultChildAge],
                  }
                : o,
            ),
          );
        }
      } else if (children.length > 0) {
        setOccupancy(
          occupancy.map((o, idx) =>
            idx === index
              ? {
                  ...o,
                  children: children.slice(0, -1),
                }
              : o,
          ),
        );
      }
    },
    [occupancy, setOccupancy],
  );

  const setAge = useCallback(
    (index: number, childIndex: number, newAge: string) => {
      const newChildren = (occupancy[index].children || []).map((age, i) => (i === childIndex ? +newAge : age));

      setOccupancy(occupancy.map((o, i) => (i === index ? { ...o, children: newChildren } : o)));
    },
    [occupancy, setOccupancy],
  );

  const main = (
    <div className="padding-small">
      <div className="flex flex-middle flex-between width-1-1">
        <div className="width-1-2">
          <Trans
            i18nKey="search-bar.rooms-plural"
            defaults="{ rooms, plural, =1 {Room} other {Rooms} }"
            values={{ rooms: occupancy.length }}
          />
        </div>
        <div
          className="width-1-2 flex flex-middle flex-between"
          style={{ maxWidth: '8rem' }}
          data-testid="occupancy-rooms-controls"
        >
          <StyledPeoplePicker.Button type="button" onClick={() => occupancyChanger(false)}>
            <img src={minusIcon} alt="rooms minus" />
          </StyledPeoplePicker.Button>
          <div>{occupancy.length}</div>
          <StyledPeoplePicker.Button type="button" onClick={() => occupancyChanger(true)}>
            <img src={plusIcon} alt="rooms plus" />
          </StyledPeoplePicker.Button>
        </div>
      </div>

      <hr className="margin-medium-top margin-medium-bottom" />

      {occupancy.map((roomOccupancy, roomIdx) => (
        /* eslint-disable react/no-array-index-key */
        <div key={`occupancy_${roomIdx}`}>
          {occupancy.length !== 1 && (
            <>
              <Styled.NumberOfRooms className="flex flex-middle flex-between width-1-1 margin-medium-bottom">
                <div className="width-1-2">
                  <Trans i18nKey="search-bar.room-number" defaults="Room {roomIdx}" values={{ roomIdx: roomIdx + 1 }} />
                </div>
              </Styled.NumberOfRooms>

              <hr className="margin-medium-top margin-medium-bottom" />
            </>
          )}
          <PeoplePicker
            adults={roomOccupancy.adults}
            adultChanger={(increase) => adultChanger(roomIdx, increase)}
            childrenAges={roomOccupancy.children || []}
            childrenChanger={(increase) => childrenChanger(roomIdx, increase)}
            setAge={(index, age) => setAge(roomIdx, index, age)}
          />
          {roomIdx !== occupancy.length - 1 && <hr className="margin-medium-top margin-medium-bottom" />}
        </div>
      ))}
    </div>
  );

  const wrapRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useOutsideClick(wrapRef, () => setExpanded(false), true, !expanded);

  useEffect(() => {
    if (overflow) {
      return () => null;
    }

    const updateOffset = () => mainRef.current && setOffset(mainRef.current.getBoundingClientRect().y);

    if (expanded) {
      updateOffset();
    }

    window.addEventListener('scroll', updateOffset);

    return () => window.removeEventListener('scroll', updateOffset);
  }, [expanded, overflow]);

  if (isMobileLayout) {
    return <StyledPeoplePicker.MobileContainer>{main}</StyledPeoplePicker.MobileContainer>;
  }

  return (
    <div className="position-relative" ref={wrapRef}>
      <div>
        <StyledPeoplePicker.ButtonPadding
          ref={buttonRef}
          tabIndex={4}
          onClick={() => setExpanded(true)}
          onFocus={() => setExpanded(true)}
          className="flex-left width-expand"
          type="button"
        >
          <span>
            <img className="margin-medium-right" src={adultsIcon} alt="" />
            <Text
              data-testid="occupancy-text"
              alignment={TextAlignment.Left}
              transform={TextTransform.Lowercase}
              className="flex-column flex"
            >
              <Trans
                i18nKey="search-bar.rooms-with-param"
                defaults="{ rooms, plural, =1 {# room} other {# rooms} }"
                values={{ rooms: occupancy.length }}
              />
              {', '}
              <Trans
                i18nKey="search-bar.guests-with-param-numeric"
                defaults="{ guests, plural, =1 {# guest} other {# guests} }"
                values={{ guests: sumGuests(occupancy) }}
              />
            </Text>
          </span>
        </StyledPeoplePicker.ButtonPadding>
        {expanded && (
          <Styled.OccupancyDropdown isOpen={expanded} position="bottom-left">
            <StyledPeoplePicker.Main ref={mainRef} $overflow={overflow} offset={offset}>
              {main}
            </StyledPeoplePicker.Main>
          </Styled.OccupancyDropdown>
        )}
      </div>
    </div>
  );
};
