import styled from 'styled-components';
import Styled from 'style/styles';

const Card = styled(Styled.Card)`
  text-align: center;
  .width-1-3 {
    width: calc(100% * 1 / 3.001);
  }
  .width-2-3 {
    width: calc(100% * 2 / 3.001);
  }
`;

export default {
  Card,
};
