/* eslint-disable no-magic-numbers */
/**
 * @category Hotel Components
 * @packageDocumentation
 */
import styled, { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';
import { borderColorLight, superDarkGrey } from 'style/variables';

const FormWrapper = styled.div`
  [class*='StripeElement'] {
    display: flex;
    align-items: center;

    > * {
      width: 100%;
    }
  }
`;

const IconWrapper = styled.div`
  width: ${rem(72)};
  flex-shrink: 0;
  text-align: center;
`;

const SecureBadgeIconWrapper = styled.div`
  display: inline-block;
  width: ${rem(58)};
`;

const Header = styled.header<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid ${borderColorLight};

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h2 {
    color: ${superDarkGrey};
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    margin: 0;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.4;
      `}
  }

  ${media.mobile} {
    padding-top: 16px;
    margin-bottom: 16px;

    h2 {
      font-size: 18px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  & + & {
    margin-top: 16px;
  }
`;

export default {
  IconWrapper,
  SecureBadgeIconWrapper,
  FormWrapper,
  Header,
  Row,
};
