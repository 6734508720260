import styled, { css } from 'styled-components';
import hero1 from 'assets/images/hero-1.png';
import hero2 from 'assets/images/hero-2.png';
import { Text } from 'components/common/Text/Text';
import { cover } from 'style/cover.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import { backgroundGray, textColorEmphasis, white } from 'style/variables';

const height = 500;
const heightSmall = 176;

const MobileHeader = styled.img`
  ${cover};
  width: 100%;
`;

const Hero = styled.div<{ isLight?: boolean }>`
  height: ${rem(height)};
  position: relative;
  ${({ isLight }) =>
    isLight &&
    css`
      color: ${white};
    `}
  background:
    url(${hero1}) no-repeat left bottom,
    url(${hero2}) no-repeat right top,
  linear-gradient(180deg, ${white} 0%, ${backgroundGray} 100%) 0 0 no-repeat padding-box;

  ${media.mobile} {
    height: ${rem(heightSmall)};
    background: none;
  }
`;

const Caption2 = styled(Text)`
  color: ${textColorEmphasis};
  width: 600px;
  display: block;
`;

const Greeting = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export default {
  MobileHeader,
  Hero,
  Greeting,
  Caption2,
};
