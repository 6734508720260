import useQuery, { UrlParameters } from 'common/hooks/useQuery';
import useSearchParameter from 'common/hooks/useSearchParameter';
import useValidationCallback from 'common/hooks/useValidationCallback';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useSession } from 'atoms/hooks/useSession';
import { UpdatePasswordRequest } from 'backend/api/user/userRequest';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import {
  InputField,
  InputFieldRef,
  passwordErrorMessage,
  passwordValidationRule,
} from 'components/common/InputField/InputField';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { showMessageError } from 'errors/errorHandlers';
import { logError, processError } from 'errors/errorUtils';
import useUpdatePasswordErrors from 'errors/useUpdatePasswordErrors';

interface ResetPasswordParams extends UrlParameters {
  access_token?: string;
  userId?: string;
}

export const ResetPasswordForm: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const errors = useUpdatePasswordErrors(undefined);

  const passwordRef = useRef<InputFieldRef>();
  const repeatPasswordRef = useRef<InputFieldRef>();

  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const repeatPasswordValidationRule = useCallback((p: string) => p === password, [password]);

  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { updatePassword } = useSession();

  const [working, setWorking] = useState(false);

  const { userId, access_token: accessToken } = useQuery<ResetPasswordParams>();
  const [, setUserIdParam] = useSearchParameter<ResetPasswordParams, string>('userId', { raw: true, replace: true });
  const [, setAccessTokenParam] = useSearchParameter<ResetPasswordParams, string>('access_token', {
    raw: true,
    replace: true,
  });

  useEffect(() => {
    if (!userId || !accessToken) {
      const errorMessage = t('login-popup.reset-password.invalid-link', 'Reset password link is invalid');
      const errorId = logError(new ClientError(ClientErrorCode.InvalidLink, [errorMessage]));

      showMessageError(errorId, errorMessage);
    }
  }, [accessToken, t, userId]);

  const setToast = useContext(ToastContext);

  const { validation } = useValidationCallback({ fields: [passwordRef, repeatPasswordRef] });

  const handleClearUrlParams = useCallback(() => {
    setSignInDialogMode(undefined);
    setUserIdParam(undefined);
    setAccessTokenParam(undefined);
  }, [setAccessTokenParam, setSignInDialogMode, setUserIdParam]);

  const doReset = useCallback(() => {
    if (validation(true).length > 0) {
      return;
    }

    setWorking(true);
    const payload: UpdatePasswordRequest = {
      token: accessToken,
      password,
    };

    updatePassword(payload, Number(userId), true)
      .then(() =>
        setToast(t('login-popup.reset-password-changed', 'Password updated successfully!'), ToastType.Success),
      )
      .catch((reason) => processError(reason, errors))
      .finally(() => setWorking(false))
      .finally(handleClearUrlParams);
  }, [userId, updatePassword, accessToken, errors, handleClearUrlParams, password, setToast, t, validation]);

  return (
    <ModalBody>
      <ModalCloseButton onClick={() => setSignInDialogMode(undefined)} />

      <Text
        weight={TextWeight.SemiBold}
        alignment={TextAlignment.Center}
        size={TextSize.HeadingL}
        className="margin-remove-top"
        tag="div"
      >
        <Trans i18nKey="login-popup.reset-title">Reset your password</Trans>
      </Text>

      <InputField
        id={'id-signin-password'}
        ref={passwordRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.password', 'Password')}
        required={true}
        inputType={'password'}
        value={password}
        onChange={setPassword}
        errorMessage={passwordErrorMessage(t)}
        autocomplete={'off'}
        doBasicValidation={false}
        validationRule={passwordValidationRule}
        allowHotJarRecording={false}
      />
      <InputField
        id={'id-signin-repeat-password'}
        ref={repeatPasswordRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.repeat-password', 'Repeat password')}
        required={true}
        inputType={'password'}
        value={repeatPassword}
        onChange={setRepeatPassword}
        errorMessage={t('login-popup.passwords-do-not-match', 'Passwords do not match')}
        autocomplete={'off'}
        doBasicValidation={false}
        validationRule={repeatPasswordValidationRule}
        allowHotJarRecording={false}
      />

      <Button styleType={ButtonType.Primary} className="margin-large-top" onClick={doReset} disabled={working}>
        <Text size={TextSize.Large} weight={TextWeight.Bold}>
          <Trans i18nKey="login-popup.reset-button">Reset</Trans>
        </Text>
      </Button>

      {children}
    </ModalBody>
  );
};
