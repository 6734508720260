import { Place } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  backDestinationsUrlAtom,
  checkinFromStorageAtom,
  checkoutCheckinInnerAtom,
  checkoutFromStorageAtom,
  destinationAtom,
  occupancyStorageAtom,
  placeIdAtom,
} from 'atoms/searchStateAtoms';
import { calculateCheckinCheckoutValues } from 'atoms/utils/search';
import { getDataProvider } from 'backend/dataProvider';
import { SEARCH_DESTINATION, SEARCH_OCCUPANCY } from 'backend/localStorageKeys';
import useSearchFormCallback from 'components/searchForm/useSearchFormCallback';
import useSearchFormParameters from 'components/searchForm/useSearchFormParameters';
import { env } from 'environments/environment';
import { processError } from 'errors/errorUtils';
import usePlaceErrors from 'errors/usePlaceErrors';
import { getInitialCheckout, getInitialCheckin } from 'utils/dateUtils';
import { defaultOccupancy } from 'utils/occupancyUtils';
import { isHotelOrDestinationRoute } from 'utils/uriUtils';
import useLocalStorage from 'utils/useLocalStorage';

export const useWatchSearchState = () => {
  const { pathname } = useLocation();
  const [placeId, setPlaceId] = useAtom(placeIdAtom);
  const formParameters = useSearchFormParameters();
  const [checkinFromStorage, setCheckinFromStorage] = useAtom(checkinFromStorageAtom);
  const [checkoutFromStorage, setCheckoutFromStorage] = useAtom(checkoutFromStorageAtom);
  const [innerCheckinCheckout, setInnerCheckinCheckout] = useAtom(checkoutCheckinInnerAtom);
  const { checkin, checkout } = innerCheckinCheckout || {};
  const [occupancy, setOccupancy] = useAtom(occupancyStorageAtom);
  const [destination, setDestination] = useAtom(destinationAtom);
  const backDestinationsUrl = useAtomValue(backDestinationsUrlAtom);
  const placeErrors = usePlaceErrors(backDestinationsUrl);
  const [occupancyLocalStorage, setOccupancyLocalStorage] = useLocalStorage<RoomOccupancy[]>(
    SEARCH_OCCUPANCY,
    formParameters?.occupancy || defaultOccupancy(env.searchBar),
  );

  useEffect(() => {
    const initialCheckin = getInitialCheckin(formParameters?.checkin);
    const initialCheckout = getInitialCheckout(initialCheckin, formParameters?.checkout);

    setCheckinFromStorage(initialCheckin);
    setCheckoutFromStorage(initialCheckout);
    setInnerCheckinCheckout({ checkin: initialCheckin, checkout: initialCheckout });
    setOccupancy(occupancyLocalStorage);
    setPlaceId(formParameters?.placeId);

    try {
      const item = window.localStorage.getItem(SEARCH_DESTINATION);

      setDestination(item ? (JSON.parse(item) as Place) : undefined);
    } catch (e) {
      setDestination(undefined);
    }
    // only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { updateSearchQuery } = useSearchFormCallback(placeId);

  // formParameters need deep compare
  useDeepCompareEffect(() => {
    if (formParameters?.occupancy !== undefined) {
      setOccupancy(formParameters.occupancy);
      setOccupancyLocalStorage(formParameters.occupancy);
    }
    if (formParameters?.placeId !== undefined) {
      setPlaceId(formParameters.placeId);
    }
    const { checkin: _checkin, checkout: _checkout } = calculateCheckinCheckoutValues(
      pathname,
      formParameters?.checkin,
      formParameters?.checkout,
      checkinFromStorage,
      checkoutFromStorage,
    );

    setInnerCheckinCheckout({ checkin: _checkin, checkout: _checkout });
  }, [
    checkinFromStorage,
    checkoutFromStorage,
    formParameters,
    pathname,
    setInnerCheckinCheckout,
    setOccupancy,
    setOccupancyLocalStorage,
    setPlaceId,
  ]);

  useEffect(() => {
    if ((!formParameters?.checkin || !formParameters.checkout) && formParameters?.placeId) {
      if (isHotelOrDestinationRoute(pathname) && checkin && checkout && formParameters) {
        updateSearchQuery(checkin, checkout, occupancy, formParameters);
      }
    }
  }, [checkin, checkout, formParameters, occupancy, pathname, updateSearchQuery]);

  useEffect(() => {
    if (placeId && destination?.id !== placeId) {
      setDestination(undefined);
      getDataProvider()
        .then((dataProvider) => dataProvider.getDestination(placeId))
        .then((place) => {
          setPlaceId(place?.id);
          setDestination(place);
        })
        .catch((reason) => processError(reason, placeErrors));
    }
  }, [destination?.id, placeErrors, placeId, setDestination, setPlaceId]);
};
