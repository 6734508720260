import styled from 'styled-components';
import { List } from 'components/common/List/List';
import { cover, coverContainer } from 'style/cover.styled';
import { rem } from 'style/functions';
import Styled from 'style/styles';
import { black, fontSizeHeadingM, brandColor } from 'style/variables';

const height = 130;
const marginTop = 16;

const BaseLineHeight = styled(Styled.BaseLineHeight)`
  font-size: ${fontSizeHeadingM};
  color: ${brandColor};
`;

const BurgerScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const ImageWrapper = styled.div`
  height: ${rem(height)};
`;

// png size, shouldn't change with the font size
const GREETING_IMG_SIZE_PX = 210;
const GreetingCover = styled.div`
  height: ${GREETING_IMG_SIZE_PX}px;
  ${coverContainer}
`;

const GreetingCoverImage = styled.img`
  height: ${GREETING_IMG_SIZE_PX}px;
  ${cover}
`;

const NavList = styled(List)`
  a {
    color: ${black};
  }

  li + li {
    margin-top: ${rem(marginTop)} !important;
  }
`;

export default {
  BurgerScrollContainer,
  GreetingCover,
  GreetingCoverImage,
  ImageWrapper,
  NavList,
  BaseLineHeight,
};
