import styled from 'styled-components';
import { brandColor } from 'style/variables';

const ItineraryLink = styled.a`
  color: ${brandColor};
`;

const PrivacyPolicy = styled.a`
  color: ${brandColor};
`;

export default {
  ItineraryLink,
  PrivacyPolicy,
};
