/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { rem } from 'style/functions';
import { black, lightYellow, yellow } from 'style/variables';

const Content = styled.div`
  background: transparent linear-gradient(90deg, ${lightYellow} 0%, ${yellow} 100%) 0 0 no-repeat;
  padding: ${rem(8)};

  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Image = styled.img`
  margin-right: 8px;
`;

const Link = styled.a`
  color: ${black};
  text-decoration: underline;
`;

const Button = styled.button`
  flex: none;
  display: flex;
  padding: 0;
  border: 0;
  background: transparent;
  margin-left: ${rem(16)};
  width: 20px;
  height: 20px;
`;

export const CovidBannerStyled = {
  Content,
  Image,
  Link,
  Button,
};
