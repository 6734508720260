import styled, { css } from 'styled-components';
import { OverlayTypes } from 'components/common/Overlay/constants';
import { white, overlayBackground1, overlayBackground2 } from 'style/variables';

const OverlayStyled = styled.div<{ type?: OverlayTypes }>`
  padding: 30px;
  ${({ type }) => {
    switch (type) {
      case OverlayTypes.BurgerMenu:
        return css`
          background: ${overlayBackground1};
          padding: 15px;
          h2 {
            color: ${white};
          }
        `;
      case OverlayTypes.DebugPanel:
        return css`
          background: ${overlayBackground2};
        `;
      default:
        return css``;
    }
  }}
  /*
   * Remove margin from the last-child
   */
  > :last-child {
    margin-bottom: 0;
  }
`;

export default {
  OverlayStyled,
};
