import styled, { css } from 'styled-components';
import { black, borderColorLight, darkGray, gray, pink, white } from 'style/variables';

export const StyledCloseButton = styled.button<{ variant?: 'default' | 'pink' }>`
  display: block;
  color: ${gray};
  width: 18px;
  cursor: pointer;
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background-color: initial;
  display: inline-block;
  fill: currentcolor;
  line-height: 0;
  z-index: 9999;
  /* Hover + Focus */
  &:hover,
  &:focus {
    color: ${darkGray};
    outline: none;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'default':
        return css`
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid ${borderColorLight};
          background-color: ${white};
          color: ${black};
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          svg {
            line {
              stroke: ${black};
            }
          }
        `;
      case 'pink':
        return css`
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid ${borderColorLight};
          background-color: ${white};
          color: ${pink};
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          svg {
            line {
              stroke: ${pink};
            }
          }
        `;
      default:
        return css``;
    }
  }}
`;
