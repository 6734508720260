import React from 'react';
import AppRoutes from 'AppRoutes';
import { CookiePanel } from 'components/layouts/CookiePanel/CookiePanel';
import MobilePageHeader from 'components/layouts/mobile/MobilePageHeader';
import { MobilePageLayoutStyled } from 'components/layouts/mobile/MobilePageLayout.styled';
import { SignInDialog } from 'components/signin/SignInDialog/SignInDialog';
import { CovidBanner } from 'components/widgets/CovidBanner';

export const MobilePageLayout = () => (
  <MobilePageLayoutStyled.Root>
    <CookiePanel />
    <CovidBanner />
    <MobilePageHeader />

    <SignInDialog />

    <MobilePageLayoutStyled.Main>
      <AppRoutes />
    </MobilePageLayoutStyled.Main>
  </MobilePageLayoutStyled.Root>
);
