import styled, { css } from 'styled-components';
import ButtonStyled from 'components/common/Button/Button.styled';
import { activeDropdownPeoplePickerBackgroundColor, black, borderColor, light } from 'style/variables';

const Button = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
`;

const ButtonPadding = styled(ButtonStyled.DefaultButton)`
  padding: 15px;
  height: 48px;
  justify-content: flex-start;
  display: flex;
  > span {
    display: flex;
    align-items: center;
  }
`;

const Dropdown = styled.ul`
  display: block;
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 0;
  min-width: 0;

  li {
    padding: 5px 15px;
    transition: background-color 0.15s;
    cursor: pointer;

    &.active {
      background: ${activeDropdownPeoplePickerBackgroundColor};
    }
  }
`;

const Main = styled.div<{ $overflow?: boolean; offset?: number }>`
  width: 256px;
  ${({ $overflow, offset }) =>
    !$overflow &&
    css`
      border-radius: 2px;
      max-height: calc(100vh - ${offset}px - 10px);
      overflow: visible auto;
    `}
`;

const MobileContainer = styled.div`
  margin: 0 16px;
  overflow-x: auto;
  background-color: ${light};
  color: ${black};
  border-radius: 0.28571rem;
  border: 1px solid ${borderColor};
  touch-action: manipulation;
`;

export default {
  Main,
  Button,
  ButtonPadding,
  Dropdown,
  MobileContainer,
};
