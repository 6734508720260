import React, { useCallback, useState } from 'react';
import { ToastContext } from 'TopContexts';
import Toast, { ToastProps } from 'components/common/Toast/Toast';
import { ToastType } from 'components/common/Toast/Toast.types';

const ToastProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [toast, setToast] = useState<ToastProps>();

  const clearToastMessage = useCallback(() => {
    setToast(undefined);
  }, []);

  const setToastMessage = useCallback(
    (message: string | undefined, type?: ToastType) => {
      setToast({
        message,
        clearMessage: clearToastMessage,
        type,
      });
    },
    [clearToastMessage],
  );

  return (
    <ToastContext.Provider value={setToastMessage}>
      {toast?.message && <Toast message={toast.message} clearMessage={clearToastMessage} type={toast.type} />}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
