import { css } from 'styled-components';

/*
 * Works with iframes and embedded content
 * 1. Reset responsiveness for embedded content
 * 2. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
export const cover = css`
  /* 1 */
  max-width: none;
  /* 2 */
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  object-fit: cover;
`;

/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
export const coverContainer = css`
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative;
`;
