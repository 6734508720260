import styled, { css } from 'styled-components';
import StyledInput from 'components/common/InputField/InputField.styled';
import CommonStyled from 'style/Common.styled';
import media from 'style/media';
import { borderColorLight, brandColor, errorColor, white } from 'style/variables';

const Label = styled(StyledInput.InputLabel)`
  transform: translateY(8px);
`;

const ErrorLabel = CommonStyled.ErrorLabel;

const Select = styled.select<{ hasLabel: boolean; fieldOverwrite?: boolean; error?: boolean }>`
  position: relative;
  width: 100%;
  height: 55px;

  outline: 0;
  border-radius: 4px;
  padding: ${({ hasLabel }) => (hasLabel ? '20px' : '0')} 35px 0 21px;
  border: 1px solid ${borderColorLight};

  color: black;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background-image: url('/images/drop-down.svg');
  background-repeat: no-repeat;
  background-color: white;
  background-position: calc(100% - 15px);
  appearance: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${media.mobile} {
    font-size: 14px;
  }

  &:focus {
    border-color: ${brandColor};
  }

  ${({ error }) => error && `border-color: ${errorColor};`}

  ${({ fieldOverwrite }) =>
    fieldOverwrite &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      height: 100%;

      outline: 0;
      border: none;

      color: transparent;
      appearance: none;
      cursor: pointer;

      option {
        color: black;
        background-color: ${white};
      }

      optgroup {
        color: black;
        background-color: ${white};
      }
    `}
`;

const SelectField = styled(StyledInput.InputField)`
  display: block;
  & > select:not(${Select}) {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
  }
`;

export default {
  Label,
  ErrorLabel,
  SelectField,
  Select,
};
