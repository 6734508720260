import styled from 'styled-components';
import { brandColor } from 'style/variables';

const AcceptPolicy = styled.a`
  color: ${brandColor};
`;

const AcceptTerms = styled.a`
  color: ${brandColor};
`;

export default {
  AcceptPolicy,
  AcceptTerms,
};
