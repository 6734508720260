/**
 * @category Utils
 * @packageDocumentation
 */
// according to the old website, grouping by rooms is performed by deal.rateDescription field
import { DealType, HotelDeal, HotelDealExtended } from 'common/backend/api/hotel/dealModel';
import { HotelDeals } from 'backend/api/hotel/dealModel';
import { HotelRoomInfo } from 'backend/dataModel';

/**
 * Looks through the deals array and returns the one with the lowest rate value
 * @param deals
 */
export const findCheapestDeal = <T extends HotelDeal>(deals: T[]): T | undefined =>
  deals.reduce((prev, curr) => {
    if (prev.nightlyPrice > curr.nightlyPrice) {
      return curr;
    }

    return prev;
  }, deals[0]);

/**
 * Hotel room comparator by price, that uses the cheapest deal.rate as a comparison value
 * @param a
 * @param b
 */
const roomSorter = (a: HotelRoomInfo, b: HotelRoomInfo) => {
  const cheapestA = findCheapestDeal(a.deals);
  const cheapestB = findCheapestDeal(b.deals);

  if (!cheapestA && !cheapestB) {
    return 0;
  }
  if (!cheapestA) {
    return 1;
  }
  if (!cheapestB) {
    return -1;
  }

  return cheapestA.nightlyPrice - cheapestB.nightlyPrice;
};

const dealSorter = (a: HotelDealExtended, b: HotelDealExtended) => a.nightlyPrice - b.nightlyPrice;

/**
 * According to the old website data, deals should be grouped by deal.rateDescription into 'room' objects
 * and displayed as rooms which may contain several deals
 * @param hotelDeals
 */
export const groupByRooms = (hotelDeals?: HotelDeals): HotelRoomInfo[] => {
  if (!hotelDeals) {
    return [];
  }

  const map = hotelDeals.deals.reduce((prev, current) => {
    if (current.dealType === DealType.DifferentRoom) {
      return prev;
    }

    const deals = prev.get(current.roomName);

    if (deals) {
      deals.push(current);
    } else {
      prev.set(current.roomName, [current]);
    }

    return prev;
  }, new Map<string, HotelDealExtended[]>());

  const toReturn: HotelRoomInfo[] = [];

  map.forEach((value, key) => {
    toReturn.push({
      deals: value.sort(dealSorter),
      room: hotelDeals.hotelRooms?.find((hotelRoom) => hotelRoom.roomName === key),
    });
  });

  return toReturn.sort(roomSorter);
};

/**
 * Returns a deal in array that has the lowest deal.rate
 * @param dealsArray
 */
export const findDifferentRoomDeal = (dealsArray: HotelDealExtended[]) => {
  const cheapestDeal = findCheapestDeal(dealsArray);

  if (cheapestDeal && cheapestDeal.dealType === DealType.DifferentRoom) {
    return cheapestDeal;
  }

  return undefined;
};
