import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import useOutsideClick from 'common/hooks/useOutsideClick';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import angleLeft from 'assets/images/icons/angle-left.svg';
import angleRight from 'assets/images/icons/angle-right.svg';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';
import { Month } from 'components/datePicker/customDatepicker/Month';
import NavButton from 'components/datePicker/customDatepicker/NavButton';
import { env } from 'environments/environment';
import { shiftDays, startOfMonth } from 'utils/dateUtils';

interface DesktopDatepickerLayoutProps {
  prevNavButtonClick: () => void;
  nextNavButtonClick: () => void;
  displayedMonths: MonthType[];
  firstDayOfWeek: FirstDayOfWeek;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
  nights: number | undefined;
  outsideClickCallback: () => void;
}

export const DesktopDatepickerLayout = ({
  nights,
  displayedMonths,
  firstDayOfWeek,
  setStartMonthRef,
  prevNavButtonClick,
  nextNavButtonClick,
  outsideClickCallback,
}: DesktopDatepickerLayoutProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isPrevMonthDisabled, isNextMonthDisabled] = useMemo(() => {
    if (!displayedMonths[0] || !displayedMonths[1]) {
      return [false, false];
    }

    const [now, firstMonth, secondMonth] = [new Date(), displayedMonths[0].date, displayedMonths[1].date];

    return [
      firstMonth.getTime() <= startOfMonth(now).getTime(),
      secondMonth.getTime() >= startOfMonth(shiftDays(new Date(), env.searchBar.searchDateRange)).getTime(),
    ];
  }, [displayedMonths]);

  useOutsideClick(wrapperRef, outsideClickCallback, true, false);

  return (
    <CustomDatepickerStyle.DatePickerPanel ref={wrapperRef}>
      <div className="flex flex-between">
        <CustomDatepickerStyle.PrevNavButton>
          <NavButton onClick={prevNavButtonClick} disabled={isPrevMonthDisabled}>
            <img src={angleLeft} alt="Previous" />
          </NavButton>
        </CustomDatepickerStyle.PrevNavButton>
        <CustomDatepickerStyle.NextNavButton>
          <NavButton onClick={nextNavButtonClick} disabled={isNextMonthDisabled}>
            <img src={angleRight} alt="Next" />
          </NavButton>
        </CustomDatepickerStyle.NextNavButton>
      </div>
      <div className="child-width-expand flex">
        {displayedMonths.map((month) => (
          <div className="padding-small" key={`${month.year}-${month.month}`}>
            <Month
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
              setStartMonthRef={setStartMonthRef}
            />
          </div>
        ))}
      </div>
      {nights !== undefined && (
        <CustomDatepickerStyle.Duration className="margin-small-top">
          {t('common.misc.nights-plural', '{ nights, plural, =1 {one night} other {# nights} }', { nights })}
        </CustomDatepickerStyle.Duration>
      )}
    </CustomDatepickerStyle.DatePickerPanel>
  );
};
