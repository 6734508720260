import styled from 'styled-components';
import { brandColor } from 'style/variables';

const EmailLink = styled.a`
  color: ${brandColor};
`;

export default {
  EmailLink,
};
