import React, { PropsWithChildren } from 'react';
import { Styled } from 'components/InputCheckbox.styled';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';

export interface InputCheckboxPrp extends PropsWithChildren<Record<string, unknown>> {
  value: boolean;
  setValue: (value: boolean) => void;
  containerClassName?: string;
  muted?: boolean;
  emphasized?: boolean;
  error?: string;
  disabled?: boolean;
  message?: string;
}

export const InputCheckbox = ({
  children,
  value,
  setValue,
  error,
  containerClassName,
  muted,
  emphasized,
  disabled,
  message,
}: InputCheckboxPrp) => (
  <div className={containerClassName || ''}>
    <label className="flex flex-middle">
      <Styled.InputCheckbox
        type="checkbox"
        className={`margin-small-right`}
        muted={muted}
        emphasized={emphasized}
        checked={value}
        onChange={(e) => setValue(e.target.checked)}
        disabled={disabled}
      />
      {children}
    </label>
    {error && (
      <Text size={TextSize.Small} color={TextColor.Danger} tag="div">
        {error}
      </Text>
    )}
    {message && (
      <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
        {message}
      </Text>
    )}
  </div>
);
