import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { matchPath, useLocation } from 'react-router-dom';
import { MetaContext } from 'TopContexts';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { env } from 'environments/environment';
import { routes } from 'routeList';
import { getPlatformType, PlatformType } from 'utils/deviceUtils';

const VIEWPORT_SETTINGS =
  getPlatformType() === PlatformType.iOS
    ? 'width=device-width, initial-scale=1, maximum-scale=1'
    : 'width=device-width, initial-scale=1';

export const DEFAULT_DESCRIPTION =
  'We create unbeatable deals you will not get anywhere else - so you can save your money for an unforgettable travel experience.';
export const DEFAULT_KEYWORDS = [
  'hotel',
  'hotel deals',
  'hotel rooms',
  'hotels',
  'lodging',
  'accommodation',
  'special deals',
  'weekend breaks',
  'city breaks',
  'deals',
  'budget',
  'cheap',
  'discount',
  'savings',
  'hotels near',
  'near me hotel',
  'bookings',
  'reservations',
  'hotels near',
];

const NOINDEX_ROUTES = [routes.checkout, routes.confirmation];
const HOTEL_ROUTES = [routes.hotels, routes.hotelsRedirect];
const DESTINATIONS_ROUTES = [routes.destination, routes.destinationsRedirect];
const HOTEL_CANONICAL_LINK = `https://${window.location.host}${env.canonicalLinks.hotelPage}`;
const HOTELS_CANONICAL_LINK = `https://${window.location.host}${env.canonicalLinks.destinationsPage}`;
const CRAWLER_USERAGENT_REGEXP = /googlebot/i;

const MetaProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { title: routeTitle } = useContext(RouteDataContext);
  const [title, _setTitle] = useState(routeTitle);
  const [description, setDescription] = useState<string>(DEFAULT_DESCRIPTION);
  const [keywords, setKeywords] = useState<string[]>(DEFAULT_KEYWORDS);
  const { pathname } = useLocation();
  const isNoIndexPage = useMemo(() => NOINDEX_ROUTES.some((route) => !!matchPath(pathname, route)), [pathname]);
  const isHotelDetailsPage = useMemo(() => HOTEL_ROUTES.some((route) => !!matchPath(pathname, route)), [pathname]);
  const isHotelListPage = useMemo(() => DESTINATIONS_ROUTES.some((route) => !!matchPath(pathname, route)), [pathname]);
  const isGoogleCrawler = useMemo(() => RegExp(CRAWLER_USERAGENT_REGEXP).test(window.navigator.userAgent), []);

  useEffect(() => {
    _setTitle(routeTitle);
  }, [routeTitle]);

  const setTitle = useCallback((newTitle: string | undefined) => _setTitle(newTitle || routeTitle), [routeTitle]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content={VIEWPORT_SETTINGS} />
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {keywords && keywords.length && <meta name="keywords" content={keywords.join(', ')} />}
        <meta property="fb:app_id" content={env.oauth.facebookAppId} />
        {isNoIndexPage && <meta name="robots" content="noindex" />}
        {isHotelDetailsPage && <link rel="canonical" href={HOTEL_CANONICAL_LINK} />}
        {isHotelListPage && <link rel="canonical" href={HOTELS_CANONICAL_LINK} />}
      </Helmet>
      <MetaContext.Provider value={{ setTitle, setDescription, setKeywords, isGoogleCrawler }}>
        {children}
      </MetaContext.Provider>
    </HelmetProvider>
  );
};

export default MetaProvider;
