/**
 * @category Authentication
 * @packageDocumentation
 */
import React, { useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import { ReactComponent as ArrowLeftSVG } from 'assets/images/icons/left-arrow.svg';
import StyledModal from 'components/StyledModal';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import { OAuthContext } from 'components/contexts/OAuthContext';
import { OauthSignupForm } from 'components/signin/OauthSignupForm/OauthSignUpForm';
import { RecoverPasswordForm } from 'components/signin/RecoverPasswordForm';
import { ResetPasswordForm } from 'components/signin/ResetPasswordForm';
import { SignInByTripForm } from 'components/signin/SignInByTripForm/SignInByTripForm';
import Styled from 'components/signin/SignInDialog/SignInDialog.styled';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { SignInForm } from 'components/signin/SignInForm';
import { SignupForm } from 'components/signin/SignupForm/SignupForm';
import { PRIVACY_ROUTE } from 'routeList';
import { brandColor } from 'style/variables';

/**
 * Sign-up/sign-in flow dialog, with ability to switch between its states.
 */
export const SignInDialog = () => {
  const { signInDialogMode, setSignInDialogMode } = useContext(SignInDialogContext);
  const [storedEmail, setStoredEmail] = useState('');
  const onHide = useCallback(() => {
    setSignInDialogMode(undefined);
  }, [setSignInDialogMode]);

  const { oauthPayload } = useContext(OAuthContext);

  const signUpFooter = (
    <>
      <div className="margin-large-top">
        <Trans i18nKey="login-popup.has-account">Already have an account?</Trans>{' '}
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setSignInDialogMode(SignInDialogMode.SignIn);
          }}
        >
          <Text color={TextColor.Primary}>
            {' '}
            <Trans i18nKey="login-popup.signin-now">Sign in now!</Trans>{' '}
          </Text>
        </a>
      </div>

      <hr className="width-expand" />

      <div>
        <Trans i18nKey="login-popup.itinerary-link">
          See your booking with your{' '}
          <Styled.ItineraryLink
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setSignInDialogMode(SignInDialogMode.ViewBooking);
            }}
          >
            Email and trip number
          </Styled.ItineraryLink>
        </Trans>
      </div>
    </>
  );

  const resetPasswordFooter = (
    <a
      className="flex flex-middle margin-large-top width-expand"
      href="/"
      onClick={(e) => {
        e.preventDefault();
        setSignInDialogMode(SignInDialogMode.SignIn);
      }}
    >
      <Text color={TextColor.Primary}>
        <ArrowLeftSVG style={{ fill: brandColor }} className="margin-small-right" />
        <Trans i18nKey="login-popup.back-signin">Go back to sign in form</Trans>
      </Text>
    </a>
  );

  return (
    <StyledModal
      onHide={onHide}
      visible={signInDialogMode !== undefined}
      removePadding={true}
      positionToCenter={true}
      updated
    >
      <div>
        {(() => {
          switch (signInDialogMode) {
            case SignInDialogMode.SignUp:
              return oauthPayload ? (
                <OauthSignupForm>{signUpFooter}</OauthSignupForm>
              ) : (
                <SignupForm>{signUpFooter}</SignupForm>
              );
            case SignInDialogMode.RecoverPassword:
              return <RecoverPasswordForm initialEmail={storedEmail}>{resetPasswordFooter}</RecoverPasswordForm>;
            case SignInDialogMode.ResetPassword:
              return <ResetPasswordForm>{resetPasswordFooter}</ResetPasswordForm>;
            case SignInDialogMode.ViewBooking:
              return (
                <SignInByTripForm>
                  <div className="margin-large-top">
                    <Trans i18nKey="login-popup.has-account">Already have an account?</Trans>{' '}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setSignInDialogMode(SignInDialogMode.SignIn);
                      }}
                    >
                      <Text color={TextColor.Primary}>
                        {' '}
                        <Trans i18nKey="login-popup.signin-now">Sign in now!</Trans>{' '}
                      </Text>
                    </a>
                  </div>

                  <hr className="width-expand" />

                  <div>
                    <Trans i18nKey="login-popup.read-privacy-policy">
                      Read our{' '}
                      <Styled.PrivacyPolicy href={PRIVACY_ROUTE} target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </Styled.PrivacyPolicy>
                    </Trans>
                  </div>
                </SignInByTripForm>
              );
            case SignInDialogMode.SignIn:
              return (
                <SignInForm
                  onForgotPassword={(email) => {
                    setStoredEmail(email);
                    setSignInDialogMode(SignInDialogMode.RecoverPassword);
                  }}
                >
                  <div className="margin-large-top">
                    <Trans i18nKey="login-popup.no-account">Don&apos;t have an account?</Trans>{' '}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setSignInDialogMode(SignInDialogMode.SignUp);
                      }}
                    >
                      <Text color={TextColor.Primary}>
                        <Trans i18nKey="login-popup.register-now">Sign up now!</Trans>
                      </Text>
                    </a>
                  </div>

                  <hr className="width-expand" />

                  <div>
                    <Trans i18nKey="login-popup.read-privacy-policy">
                      Read our{' '}
                      <Styled.PrivacyPolicy href={PRIVACY_ROUTE} target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </Styled.PrivacyPolicy>
                    </Trans>
                  </div>
                </SignInForm>
              );
            default:
              return null;
          }
        })()}
      </div>
    </StyledModal>
  );
};
