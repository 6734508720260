import useValidationCallback from 'common/hooks/useValidationCallback';
import { emailValidationRule } from 'common/utils/validation/emailValidationRule';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { getDataProvider } from 'backend/dataProvider';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { InputField, InputFieldRef } from 'components/common/InputField/InputField';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import StyledInner from 'components/signin/SignInByTripForm/SignInByTripForm.styled';
import { processError } from 'errors/errorUtils';
import useEncryptTripIdErrors from 'errors/useEncryptTripIdErrors';
import { createBookingWeakAuthUri } from 'utils/uriUtils';

const tripNumberValidationRule = (p: string) => p.length >= 0;

export const SignInByTripForm: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [t] = useTranslation();
  const contactTranslations = useContactTranslations();
  const emailRef = useRef<InputFieldRef>();
  const tripRef = useRef<InputFieldRef>();

  const [email, setEmail] = useState<string>('');
  const [trip, setTrip] = useState<string>('');

  const [loginError, setLoginError] = useState<string>();
  const errors = useEncryptTripIdErrors(setLoginError);

  const [working, setWorking] = useState(false);

  const history = useHistory();

  const { validation } = useValidationCallback({ fields: [emailRef, tripRef] });

  const setToast = useContext(ToastContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const title = useMemo(() => t('login-popup.signin-trip-number', 'Sign in with your trip number'), [t]);

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setLoginError(undefined);
      setEmail(changedEmail);
    },
    [setLoginError, setEmail],
  );

  const tripChanged = useCallback(
    (p: string) => {
      setLoginError(undefined);
      setTrip(p);
    },
    [setLoginError, setTrip],
  );

  const doSignin = useCallback(() => {
    const signin = (email2login: string, trip2Login: string, successfulHandler: () => void) => {
      setWorking(true);
      getDataProvider()
        .then((dataProvider) => dataProvider.encryptTripId(trip2Login, email2login))
        .then((encryptedTripId) => {
          successfulHandler();
          setTimeout(() =>
            setToast(t('login-popup.successful-login', 'You have logged in successfully!'), ToastType.Success),
          );
          history.push(createBookingWeakAuthUri(email, encryptedTripId));
          hotjarEvent(HotjarEvent.MyBookingClicked);
        })
        .catch((reason) => {
          processError(reason, errors);
          setWorking(false);
        });
    };

    if (validation(true).length === 0) {
      signin(email, trip, () => setSignInDialogMode(undefined));
    }
  }, [validation, history, email, t, setToast, errors, trip, setSignInDialogMode]);

  return (
    <ModalBody>
      <ModalCloseButton onClick={() => setSignInDialogMode(undefined)} />

      <Text
        weight={TextWeight.SemiBold}
        alignment={TextAlignment.Center}
        size={TextSize.HeadingL}
        className="margin-remove-top"
        tag="div"
      >
        {title}
      </Text>

      <InputField
        id={'id-signin-email'}
        ref={emailRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.email', 'Email Address')}
        required={true}
        inputType={'text'}
        inputMode="email"
        value={email}
        onChange={emailChanged}
        errorMessage={t('login-popup.invalid-email', 'Invalid email')}
        autocomplete={'email'}
        doBasicValidation={true}
        validationRule={emailValidationRule}
        allowHotJarRecording={true}
      />

      <InputField
        id={'id-signin-tripnumber'}
        ref={tripRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.trip-number', 'Trip Number')}
        required={true}
        inputType={'text'}
        value={trip}
        onChange={tripChanged}
        errorMessage={t('login-popup.invalid-trip-number', 'Too short trip number')}
        autocomplete={'off'}
        doBasicValidation={false}
        validationRule={tripNumberValidationRule}
        onEnter={doSignin}
        allowHotJarRecording={true}
      />

      {loginError && (
        <Text color={TextColor.Danger} className="margin-top" tag="div">
          {loginError}
        </Text>
      )}

      <Text alignment={TextAlignment.Justify} className="margin-top" tag="div">
        <Trans i18nKey="login-popup.view-itinerary-recover-trip-number">
          Forgot your trip number? Please send an{' '}
          <StyledInner.EmailLink
            href={`mailto:${contactTranslations.emailUs}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            email
          </StyledInner.EmailLink>{' '}
          to our support team and we&apos;ll email your upcoming trip itinerary.
        </Trans>
      </Text>

      <Button styleType={ButtonType.Primary} className="margin-large-top" onClick={doSignin} disabled={working}>
        <Text size={TextSize.Large} weight={TextWeight.SemiBold}>
          <Trans i18nKey="login-popup.view-itinerary-button">See your booking</Trans>
        </Text>
      </Button>

      <Text alignment={TextAlignment.Center} tag="div">
        {children}
      </Text>
    </ModalBody>
  );
};
