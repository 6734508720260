import React, { useCallback, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SignInDialogContext } from 'TopContexts';
import greetingAfternoon from 'assets/images/greetingAfternoon.png';
import greetingEvening from 'assets/images/greetingEvening.png';
import greetingMorning from 'assets/images/greetingMorning.png';
import bookings from 'assets/images/icons/menu-bookmark-test.svg';
import home from 'assets/images/icons/menu-home.svg';
import searchIcon from 'assets/images/icons/menu-search.svg';
import myaccountIcon from 'assets/images/icons/myaccount.svg';
import briefcase from 'assets/images/icons/nav/briefcase.svg';
import email from 'assets/images/icons/nav/email.svg';
import globe from 'assets/images/icons/nav/globe.svg';
import goldCoin from 'assets/images/icons/nav/gold-coin.svg';
import handshake from 'assets/images/icons/nav/handshake.svg';
import newsletter from 'assets/images/icons/nav/newsletter.svg';
import phone from 'assets/images/icons/nav/phone-3.svg';
import privacy from 'assets/images/icons/nav/privacy.svg';
import question from 'assets/images/icons/nav/question.svg';
import signIn from 'assets/images/icons/nav/sign-in.svg';
import translation from 'assets/images/icons/nav/translation.svg';
import userIcon from 'assets/images/icons/nav/user-icon.svg';
import signoutIcon from 'assets/images/icons/signin.svg';
import { useSession } from 'atoms/hooks/useSession';
import { Overlay } from 'components/common/Overlay/Overlay';
import { OverlayTypes } from 'components/common/Overlay/constants';
import { Text } from 'components/common/Text/Text';
import { TextSize } from 'components/common/Text/Text.types';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { CurrencySelector } from 'components/header-footer/CurrencySelector/CurrencySelector';
import { LanguageSelector } from 'components/header-footer/LanguageSelector/LanguageSelector';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import AboutUsLink from 'components/mobile/mobileLinks/AboutUsLink';
import AccountLink from 'components/mobile/mobileLinks/AccountLink';
import BookingLink from 'components/mobile/mobileLinks/BookingLink';
import CareersLink from 'components/mobile/mobileLinks/CareersLink';
import FaqLink from 'components/mobile/mobileLinks/FaqLink';
import PriceGuaranteeLink from 'components/mobile/mobileLinks/PriceGuaranteeLink';
import PrivacyPolicyLink from 'components/mobile/mobileLinks/PrivacyPolicyLink';
import SignInLink from 'components/mobile/mobileLinks/SignInLink';
import SignOutLink from 'components/mobile/mobileLinks/SignOutLink';
import TermsAndConditionsLink from 'components/mobile/mobileLinks/TermsAndConditionsLink';
import BurgerMenuStyled from 'components/mobile/offscreen/BurgerMenu/BurgerMenu.styled';
import BurgerMenuElement from 'components/mobile/offscreen/BurgerMenuElement';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useSignOutCallback from 'components/useSignOutCallback';
import Styled from 'style/styles';

interface GreetingAssetData {
  message: string;
  asset: string;
}

const MORNING_BREAKPOINT = 5;
const DAY_BREAKPOINT = 12;
const EVENING_BREAKPOINT = 17;

export const BurgerMenu = () => {
  const [t] = useTranslation();
  const contactTranslations = useContactTranslations();
  const { session } = useSession();
  const signOut = useSignOutCallback();
  const { setOffscreenMode } = useContext(OffscreenContext);
  const hideOffscreen = useCallback(() => setOffscreenMode(OffscreenMode.hidden), [setOffscreenMode]);
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { hideCurrencySelector } = useContext(RouteDataContext);

  const toHome = (
    <Link id="homeLink" to="/">
      {t('mobile-menu.home', 'Home')}
    </Link>
  );

  const toMobileSearch = (
    <a
      id="searchLink"
      href="/"
      onClick={(e) => {
        e.preventDefault();
        setOffscreenMode(OffscreenMode.search);
      }}
    >
      {t('search-bar.search', 'Search')}
    </a>
  );

  const greetingData = useMemo<GreetingAssetData>(() => {
    const hours = new Date().getHours();

    if (hours >= MORNING_BREAKPOINT && hours < DAY_BREAKPOINT) {
      return {
        message: t('mobile-menu.greeting.morning', 'Good morning'),
        asset: greetingMorning,
      };
    }
    if (hours >= DAY_BREAKPOINT && hours < EVENING_BREAKPOINT) {
      return {
        message: t('mobile-menu.greeting.afternoon', 'Good afternoon'),
        asset: greetingAfternoon,
      };
    }

    return {
      message: t('mobile-menu.greeting.evening', 'Good evening'),
      asset: greetingEvening,
    };
  }, [t]);

  return (
    <BurgerMenuStyled.BurgerScrollContainer>
      <nav className="flex-column">
        <BurgerMenuStyled.GreetingCover className="margin-bottom">
          <BurgerMenuStyled.GreetingCoverImage className="width-expand" src={greetingData.asset} alt="" />
          <Overlay
            type={OverlayTypes.BurgerMenu}
            className="position-top-left width-1-1 height-1-1 padding-small padding-remove-vertical"
          >
            <Text size={TextSize.HeadingXL} className="margin-top" tag="h2">
              {greetingData.message}
            </Text>
          </Overlay>
        </BurgerMenuStyled.GreetingCover>

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuElement icon={home}>{toHome}</BurgerMenuElement>
            <BurgerMenuElement icon={searchIcon}>{toMobileSearch}</BurgerMenuElement>
            <BurgerMenuElement icon={bookings}>
              <BookingLink
                toSignInClick={() => {
                  setSignInDialogMode(SignInDialogMode.ViewBooking);
                }}
              />
            </BurgerMenuElement>
            {session.principal && session.principal.hasUser() && (
              <>
                <BurgerMenuElement icon={myaccountIcon}>
                  <AccountLink onClick={hideOffscreen} />
                </BurgerMenuElement>
                <BurgerMenuElement icon={signoutIcon}>
                  <SignOutLink onClick={signOut} />
                </BurgerMenuElement>
              </>
            )}
            {(!session.principal || !session.principal.hasUser()) && (
              <BurgerMenuElement icon={signIn}>
                <SignInLink
                  onClick={() => {
                    setSignInDialogMode(SignInDialogMode.SignIn);
                  }}
                />
              </BurgerMenuElement>
            )}
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuElement icon={translation}>
              <div className="width-expand">
                <LanguageSelector />
              </div>
            </BurgerMenuElement>
            {!hideCurrencySelector && (
              <BurgerMenuElement icon={globe}>
                <div className="width-expand">
                  <CurrencySelector />
                </div>
              </BurgerMenuElement>
            )}
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuStyled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Styled.MediumText>{t('mobile-menu.company-section', 'Company')}</Styled.MediumText>
            </BurgerMenuStyled.BaseLineHeight>
            <BurgerMenuElement icon={userIcon}>
              <AboutUsLink />
            </BurgerMenuElement>
            <BurgerMenuElement icon={briefcase}>
              <CareersLink />
            </BurgerMenuElement>
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuStyled.BaseLineHeight
              as="h3"
              className="margin-remove-top
                      margin-small-bottom"
            >
              <Styled.MediumText>{t('mobile-menu.support-section', 'Support')}</Styled.MediumText>
            </BurgerMenuStyled.BaseLineHeight>
            <BurgerMenuElement icon={question}>
              <FaqLink onClick={hideOffscreen} />
            </BurgerMenuElement>
            <BurgerMenuElement icon={goldCoin}>
              <PriceGuaranteeLink onClick={hideOffscreen} />
            </BurgerMenuElement>
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuStyled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Styled.MediumText>{t('mobile-menu.legal-section', 'Legal')}</Styled.MediumText>
            </BurgerMenuStyled.BaseLineHeight>

            <BurgerMenuElement icon={newsletter}>
              <TermsAndConditionsLink onClick={hideOffscreen} />
            </BurgerMenuElement>

            <BurgerMenuElement icon={privacy}>
              <PrivacyPolicyLink onClick={hideOffscreen} />
            </BurgerMenuElement>
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <BurgerMenuStyled.NavList>
            <BurgerMenuStyled.BaseLineHeight as="h3" className="margin-remove-top margin-small-bottom">
              <Styled.MediumText>Connect with us</Styled.MediumText>
            </BurgerMenuStyled.BaseLineHeight>
            <BurgerMenuElement icon={phone}>
              <a href={`tel:${contactTranslations.phone}`}>{contactTranslations.phone}</a>
            </BurgerMenuElement>

            <BurgerMenuElement icon={handshake}>
              <a href={`mailto:${contactTranslations.partnerWithUs}`}>
                <Trans i18nKey="footer.partner-with-us">Partner With Us</Trans>
              </a>
            </BurgerMenuElement>

            <BurgerMenuElement icon={email}>
              <a href={`mailto:${contactTranslations.emailUs}`}>
                <Trans i18nKey="footer.email-us">Email Us</Trans>
              </a>
            </BurgerMenuElement>
          </BurgerMenuStyled.NavList>
        </div>

        <hr className="margin-medium-top margin-medium-bottom" />

        <div className="container">
          <Text size={TextSize.Small} className="margin-large-top margin-medium-bottom" tag="div">
            <Trans
              i18nKey="footer.copyright"
              defaults="© {year} Holisto Company LTD. All Rights Reserved."
              values={{ year: new Date().getFullYear() }}
            />
          </Text>
        </div>
      </nav>
    </BurgerMenuStyled.BurgerScrollContainer>
  );
};
