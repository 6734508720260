import { Hotel } from 'common/backend/api/hotel/hotelModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDataProvider } from 'backend/dataProvider';
import { LoadingModal } from 'components/LoadingModal';
import { processError } from 'errors/errorUtils';
import useHotelInfoErrors from 'errors/useHotelInfoErrors';
import { buildHotelPathname, createHotelDetailsUri } from 'utils/uriUtils';
import { HotelRedirectQueryUrlParameters } from 'views/redirect/Redirect';

interface HotelRedirectProps {
  query: HotelRedirectQueryUrlParameters;
  occupancy: RoomOccupancy[];
  checkin: string;
  checkout: string;
}

const HotelRedirect: React.FC<HotelRedirectProps> = ({ query, occupancy, checkin, checkout }) => {
  const history = useHistory();
  const errors = useHotelInfoErrors();

  const [hotelInfo, setHotelInfo] = useState<Hotel>();

  useEffect(() => {
    if (query.hotelId) {
      getDataProvider()
        .then((dataProvider) => dataProvider.getHotelInfo(+query.hotelId))
        .then(setHotelInfo)
        .catch((reason) => processError(reason, errors));
    }
  }, [errors, query.hotelId]);

  useEffect(() => {
    if (!hotelInfo) {
      return;
    }

    const hotelUrl = createHotelDetailsUri(
      buildHotelPathname(
        hotelInfo.location.countryName,
        hotelInfo.location.stateCode || '',
        hotelInfo.location.cityName,
        hotelInfo.id,
        hotelInfo.name,
      ),
      checkin,
      checkout,
      occupancy,
      undefined,
      query.dealKey,
      {
        hotelId: query.hotelId,
        campaignName: query.campaignName,
        numOfRooms: query.numOfRooms,
        priceDisplayedTotal: query.priceDisplayedTotal,
        priceDisplayedTax: query.priceDisplayedTax,
        device: query.device,
        currency: query.currency,
        userCountry: query.userCountry,
        language: query.userLanguage,
        site: query.site,
        rule: query.rule,
        externalCampaignId: query.externalCampaignId,
        promoted: query.promoted,
        defaultDate: query.defaultDate,
        adType: query.adType,
        utm_source: query.utm_source,
      },
    );

    history.replace(hotelUrl);
  }, [checkin, checkout, history, hotelInfo, occupancy, query]);

  return <LoadingModal visible={true} />;
};

export default HotelRedirect;
