/* eslint-disable no-magic-numbers */

import styled, { css } from 'styled-components';
import ButtonStyled from 'components/common/Button/Button.styled';
import { AnimatedModal } from 'components/common/modal/CustomAbstractModal.styled';
import { StyledCloseButton } from 'components/common/modal/ModalCloseButton.styled';
import { ModalBody, ModalFooter, ModalHeader } from 'components/common/modal/ModalSections.styled';
import { fadeTop } from 'style/animations.styled';
import { rem } from 'style/functions';
import { backgroundGray, backgroundLightPink, pink, primaryBackground, white } from 'style/variables';

// 1. Create position context for spinner and close button
// 2. Dimensions
// 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
//    `!important` needed to overwrite `width-auto`. See `#modal-media-image` in tests
// 4. Style

const DialogBase = styled(AnimatedModal)<{
  removePadding?: boolean;
}>`
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: ${white};

  ${({ removePadding }) =>
    removePadding &&
    css`
      padding: 0 !important;
    `}

  margin-top: auto !important;
  margin-bottom: auto !important;

  display: flow-root;
  padding: 30px 30px;

  animation: ${fadeTop} 0.3s linear;
`;

const Dialog = styled(DialogBase)<{
  removePadding?: boolean;
}>`
  border: 0;
  border-radius: ${rem(8)};
  width: 100%;
  max-width: 432px !important;

  div${ModalHeader} {
    padding: ${rem(16)} ${rem(24)};
    background: ${primaryBackground};
    border-radius: ${rem(8)} ${rem(8)} 0 0;
  }

  div${ModalBody} {
    padding: ${rem(24)};
  }

  button${StyledCloseButton} {
    width: ${rem(32)};
    height: ${rem(32)};
    border: 0;
    background: ${backgroundLightPink};
    transform: none;
    position: static;
    margin-right: -${rem(8)};

    svg {
      color: ${pink};
      width: ${rem(10)};
      height: ${rem(10)};
    }
  }

  ${ButtonStyled.PrimaryButton} {
    font-weight: 500;
    box-shadow: none;
    padding: 0 ${rem(35)};
  }

  div${ModalFooter} {
    padding: ${rem(16)} ${rem(24)};
    background: ${backgroundGray};
    border-radius: 0 0 ${rem(8)} ${rem(8)};

    a {
      text-decoration: underline;
    }
  }
`;

const NewDialog = styled(DialogBase)<{
  removePadding?: boolean;
}>`
  border: 0;
  border-radius: 0;
  width: 100%;
  max-width: 460px !important;

  div${ModalBody} {
    padding: ${rem(40)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${ButtonStyled.Button} {
      width: 100%;
      height: 48px;
      border-radius: 5px;
    }
  }

  button${StyledCloseButton} {
    position: absolute;
    margin: 0;
    top: 15px;
    right: 15px;
    background: transparent;
    width: ${rem(18)};
    height: ${rem(18)};

    svg {
      color: ${white};
      width: inherit;
      height: inherit;
    }
  }

  ${ButtonStyled.PrimaryButton} {
    font-weight: 500;
    box-shadow: none;
    padding: 0 ${rem(35)};
  }
`;

export default {
  Dialog,
  NewDialog,
};
