import styled from 'styled-components';
import { white, borderColorLightest } from 'style/variables';

const Header = styled.div`
  height: 4rem;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.header`
  background: ${white};
  border-bottom: 1px solid ${borderColorLightest};
  z-index: 1000;
`;

export const MobilePageHeaderStyled = {
  Container,
  Header,
};
