/**
 * @category Universal Components
 * @packageDocumentation
 */
import { useModalCloseHelper } from 'common/hooks/useModalCloseHelper';
import React, { PropsWithChildren, useCallback } from 'react';
import CustomAbstractModal from 'components/common/modal/CustomAbstractModal';
import StyledDialog from 'components/signin/ModalStyles';

export interface StyledModalProps extends PropsWithChildren<Record<string, unknown>> {
  visible: boolean;
  positionToCenter: boolean;
  removePadding?: boolean;
  onHide?: () => void;
  onShow?: () => void;

  /**
   * The popups were redesigned, but not all of them. If updated is true then new design will be accepted.
   */
  updated?: boolean;
}

/**
 * @param param {@link ModalDialogProps}
 */
const StyledModal = ({
  children,
  visible,
  onHide,
  onShow,
  removePadding,
  positionToCenter,
  updated,
}: StyledModalProps) => {
  const handleHide = useCallback(() => onHide?.(), [onHide]);
  const dialogRef = useModalCloseHelper(handleHide, !visible);

  return (
    <CustomAbstractModal
      visible={visible}
      onHide={onHide}
      onShow={onShow}
      positionToCenter={positionToCenter}
      updated={updated}
    >
      {updated ? (
        <StyledDialog.NewDialog ref={dialogRef} removePadding={removePadding}>
          {children}
        </StyledDialog.NewDialog>
      ) : (
        <StyledDialog.Dialog ref={dialogRef} removePadding={removePadding}>
          {children}
        </StyledDialog.Dialog>
      )}
    </CustomAbstractModal>
  );
};

export default StyledModal;
