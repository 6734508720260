import { css, keyframes } from 'styled-components';

export const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
    visibility: hidden;
  }

  to {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
`;

/*
 * Slide Top
 */
export const fadeTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

/*
 * Fade
 */
export const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const legacyAnimation = css`
  // legacy style, do not use it. Instead use animation from animation.styld.ts
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-20px);
      visibility: hidden;
    }

    to {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
`;
