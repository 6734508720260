import React from 'react';
import { useTranslation } from 'react-i18next';
import news from 'assets/images/icons/news.svg';
import skift from 'assets/images/icons/skift.svg';
import starTribune from 'assets/images/icons/star-tribune.svg';
import bostonGlobe from 'assets/images/icons/the-boston-globe.svg';
import yahooFinance from 'assets/images/icons/yahoo.png';
import { CardTypes } from 'components/common/Card/types';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import Styled from 'style/styles';

export const AsSeenOnPanel = () => {
  const [t] = useTranslation();

  return (
    <Styled.Card type={CardTypes.Main} className="flex flex-column flex-middle flex-center height-1-1 padding-small">
      <img src={news} alt="news" />
      <Text
        color={TextColor.Primary}
        size={TextSize.HeadingS}
        className="margin-small-top margin-small-bottom"
        tag="h3"
      >
        {t('home.news.as-seen-on', 'As Seen On')}
      </Text>
      <Text size={TextSize.Small} className="margin-large-bottom" tag="div">
        {t('home.news.featured', 'Featured on top news and travel portals')}
      </Text>
      <div className="grid-column-large grid-row-medium flex-center grid grid-row-gap">
        <img src={bostonGlobe} alt="the boston globe" className="first-column" />
        <img src={yahooFinance} alt="yahoo finance" />
        <img src={starTribune} alt="star tribune" className="grid-margin first-column" />
        <img src={skift} alt="skift" className="grid-margin" />
      </div>
    </Styled.Card>
  );
};
