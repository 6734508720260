import styled from 'styled-components';
import Styled from 'style/styles';

const Card = styled(Styled.CardPink)`
  text-align: center;
`;

export default {
  Card,
};
