import { Trip, TripStatus } from 'common/backend/api/trip/tripModel';
import { useSessionStorage } from 'common/hooks/useSessionStorage';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DataLayerLogging } from 'utils/logging/DataLayerLogging';

const SESSION_STORAGE_KEY = 'ROKT_HAS_SHOWED';

const validateTripStatus = (status: TripStatus) => {
  switch (status) {
    case TripStatus.Draft:
    case TripStatus.Cancelled:
    case TripStatus.CancelledPaid:
    case TripStatus.Failed:
    case TripStatus.InAttempt:
    case TripStatus.PendingRefund:
      return false;
    default:
      return true;
  }
};

export const useRokt = (tripDetails?: Trip) => {
  const [roktHasBeenShowed, setRoktHasBeenShowed] = useSessionStorage(SESSION_STORAGE_KEY, 'false');

  useEffect(() => {
    if (roktHasBeenShowed !== 'true' && tripDetails && validateTripStatus(tripDetails.status)) {
      setRoktHasBeenShowed('true');
      DataLayerLogging.pushConfirmationPageEvent(tripDetails);
    }
    // need only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roktHasBeenShowed, setRoktHasBeenShowed, tripDetails?.id]);
};

export const useClearRokt = () => {
  const location = useLocation();
  const [, , clear] = useSessionStorage(SESSION_STORAGE_KEY, 'false');
  const [prevPathName, setPrevPathName] = useState<string>('');

  const beforeUnload = useCallback(() => {
    clear();
  }, [clear]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);

    return () => window.removeEventListener('beforeunload', beforeUnload);
  }, [beforeUnload]);

  useEffect(() => {
    if (prevPathName.includes('confirmation') && !location.pathname.includes('confirmation')) {
      clear();
    }
    setPrevPathName(location.pathname);
  }, [clear, location, prevPathName]);
};
