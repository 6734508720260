import { CurrencyCode } from 'common/backend/api/model';
import { PlaceType } from 'common/backend/api/place/placeModel';
import { RoomOccupancy } from 'common/backend/api/trip/tripModel';
import useQuery, { UrlParameters } from 'common/hooks/useQuery';
import React, { useContext, useEffect, useMemo } from 'react';
import { SettingsContext } from 'TopContexts';
import { useCurrencySettings } from 'contexts/settingsHooks/useCurrencySettings';
import { env } from 'environments/environment';
import { getInitialCheckin, getInitialCheckout } from 'utils/dateUtils';
import { createOccupancyOrDefault, validateOccupancy } from 'utils/occupancyUtils';
import { GoogleHotelParameters } from 'utils/uriUtils';
import { validateNumber } from 'utils/validation';
import DestinationRedirect from 'views/redirect/DestinationRedirect';
import HotelRedirect from 'views/redirect/HotelRedirect';

interface GoogleDestinationParameters extends UrlParameters {
  destinationName: string;
  placeId: string;
  placeType: PlaceType;
}

export interface HotelRedirectQueryUrlParameters extends GoogleHotelParameters, GoogleDestinationParameters {
  occupancy?: string;
  checkin: string;
  checkout: string;
  dealKey: string;
  adults: string;
  children: string;
  age?: string[];
  currency?: CurrencyCode;
  /**
   * TTT  (time to travel) - time until the check in date from today.
   * For example, TTT=0 means that the check-in date is today, and TTT>0 means future check-in dates.
   */
  ttt?: string;
  /**
   * LOS (length of stay) - the number of nights.
   */
  los?: string;
}

export enum RedirectLinkType {
  HOTEL = 'hotel',
  DESTINATION = 'destination',
}

const calculateCheckinCheckout = (
  queryCheckin: string,
  queryCheckout: string,
  timeToTravel: string | undefined,
  lengthOfStay: string | undefined,
) => {
  let ttt: number | undefined;
  let los: number | undefined;

  if (timeToTravel) {
    ttt = Number(timeToTravel);

    if (!Number.isFinite(ttt) || ttt < 0) {
      ttt = undefined;
    }
  }

  if (lengthOfStay) {
    los = Number(lengthOfStay);

    if (!Number.isFinite(los) || los <= 0) {
      los = undefined;
    }
  }

  const checkin = getInitialCheckin(queryCheckin, ttt);
  const checkout = getInitialCheckout(checkin, queryCheckout, los);

  return { checkin, checkout };
};

interface RedirectProps {
  type: RedirectLinkType;
}

const Redirect: React.FC<RedirectProps> = ({ type }) => {
  const query = useQuery<HotelRedirectQueryUrlParameters>();
  const { setCountryCode } = useContext(SettingsContext);
  const { resetCurrencyState } = useCurrencySettings();

  const { checkin, checkout } = useMemo(
    () => calculateCheckinCheckout(query.checkin, query.checkout, query.ttt, query.los),
    [query.checkin, query.checkout, query.los, query.ttt],
  );

  useEffect(() => {
    if (query.currency) {
      resetCurrencyState();
    }
  }, [query.currency, resetCurrencyState]);

  useEffect(() => {
    if (query.userCountry) {
      setCountryCode(query.userCountry);
    }
  }, [query.userCountry, setCountryCode]);

  const occupancy = useMemo<RoomOccupancy[]>(() => {
    try {
      const occupancyFromQuery: RoomOccupancy[] | undefined = query.occupancy ? JSON.parse(query.occupancy) : undefined;

      if (validateOccupancy(occupancyFromQuery)) {
        return occupancyFromQuery;
      }
    } finally {
      // to do  nothing
    }

    const numOfRooms = +(query.numOfRooms || 0);
    const adults = +query.adults;
    const children: number[] = Array(+query.children || 0).fill(-1);
    let ages = query.age || [];

    if (!Array.isArray(ages)) {
      ages = [ages];
    }

    ages.forEach((value) => {
      const [idx, age] = value.split('_').map((v) => +v);

      children[idx] = age;
    });

    const numOfRoomsIsValid = validateNumber(numOfRooms, 1, env.searchBar.maxOccupancy);
    const adultsIsValid = validateNumber(adults, 1, env.searchBar.maxAdults);
    const childrenIsValid =
      validateNumber(children.length, 0, env.searchBar.maxChildren) &&
      children.every((age) => validateNumber(age, env.searchBar.minChildAge, env.searchBar.maxChildAge));

    return Array(numOfRoomsIsValid ? numOfRooms : 1).fill(
      createOccupancyOrDefault(adultsIsValid ? adults : undefined, childrenIsValid ? children : undefined),
    );
  }, [query.adults, query.age, query.children, query.numOfRooms, query.occupancy]);

  if (type === RedirectLinkType.HOTEL) {
    return <HotelRedirect query={query} occupancy={occupancy} checkin={checkin} checkout={checkout} />;
  }

  return <DestinationRedirect query={query} occupancy={occupancy} checkin={checkin} checkout={checkout} />;
};

export default Redirect;
