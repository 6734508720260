/**
 * @category Hotel Components
 * @packageDocumentation
 */
// validates the input panel (either sign in or payment panel). focuses on the panel if there is an error
import { MutableRefObject, RefObject } from 'react';
import { ExtendedValidatedPanelRef, ValidatedPanelRef } from 'backend/dataModel';
import { ServerErrorCode } from 'backend/serverError';
import { ClientError, ClientErrorCode } from 'errors/clientError';

/**
 * Returns error code and focuses the invalidated input element if there is an error
 * @param ref
 * @param focus
 */
export const validateInputPanel = (ref: MutableRefObject<ValidatedPanelRef | undefined | null>, focus: boolean) => {
  if (!ref) {
    return new ClientError(ClientErrorCode.InputPanelError);
  }

  if (ref.current) {
    const result: string[] = ref.current.validate(focus);

    if (result.length > 0) {
      return new ClientError(ClientErrorCode.ValidationError, result);
    }
  }

  return undefined;
};

export const invalidateInputPanel = (
  ref: MutableRefObject<ExtendedValidatedPanelRef | undefined | null>,
  reasons: ServerErrorCode | ClientErrorCode,
  message: string,
) => {
  if (!ref) {
    return new ClientError(ClientErrorCode.InputPanelError);
  }

  if (ref.current) {
    ref.current.invalidate(reasons, message);
  }

  return undefined;
};

export const focusInput = (ref: RefObject<HTMLElement>) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'auto', block: 'center' });
    ref.current.focus();
  }
};
