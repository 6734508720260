/* eslint-disable no-magic-numbers */

import styled, { css } from 'styled-components';
import { Card as CardComponent } from 'components/common/Card/Card';
import Common from 'components/common/Divider/Divider.styled';
import { slideDown } from 'style/animations.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import {
  backgroundGray,
  backgroundLightViolet,
  black,
  borderColor,
  borderLightViolet,
  borderRadius,
  boxShadowColor,
  brandColor,
  light,
  purple,
  textColorMuted,
  white,
} from 'style/variables';

const Month = styled.div`
  max-width: ${rem(376)};
  margin: 0 auto 0 auto;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 0;
  padding: 0;
  font-size: 1rem;
  outline: none;
  background: ${white};
  cursor: pointer;
  transition: 0.2s;

  &[disabled] {
    filter: opacity(0);
    cursor: default;
  }
`;

const MonthLabel = styled.div`
  margin: 0 0 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  background: ${backgroundGray};
  padding: 0.5rem;

  span {
    color: ${borderColor};
  }
`;

const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  margin-bottom: 0.8rem;
  font-size: 0.8rem;
  color: ${textColorMuted};

  div {
    text-align: center;
  }
`;

const DayLabel = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
`;

const DayMeta = styled.button`
  height: ${rem(32)};
  border: 0;
  outline: 0;
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
  margin: 0 !important;
`;

const DayDefault = styled(DayMeta)<{ hovered?: boolean }>`
  color: ${black};
  background: ${white};

  ${({ hovered }) =>
    hovered &&
    css`
      ${media.desktop} {
        background: rgba(236, 1, 140, 0.1);
      }
    `}
`;

const DaySelected = styled(DayMeta)`
  position: relative;
  z-index: 0;
  color: ${black};
  background: ${'rgba(236, 1, 140, 0.1)'};
`;

const DaySelectedMeta = styled(DayMeta)`
  position: relative;
  z-index: 1;
  color: ${white};
  background: transparent;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
  }
`;

const DaySelectedFirst = styled(DaySelectedMeta)`
  z-index: 2;

  &:after {
    width: ${rem(32)};
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%);
    background: ${brandColor};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 50%;
    background: ${'rgba(236, 1, 140, 0.1)'};
  }
`;

const DaySelectedLast = styled(DaySelectedMeta)`
  &:after {
    width: ${rem(32)};
    right: 50%;
    border-radius: 50%;
    transform: translate(50%);
    background: ${brandColor};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50%;
    width: 50%;
    background: ${'rgba(236, 1, 140, 0.1)'};
  }
`;

const DayDisabled = styled(DayMeta)`
  color: ${borderColor};
  background: ${white};
  font-weight: normal;
`;

const DatePickerPanel = styled.div`
    position: absolute;
    z-index: 1020;
    box-sizing: border-box;
    background: ${white};
    border-radius: 4px;

    top: calc(100% + 8px);
    display: block;
    animation: ${slideDown} 0.3s;
    width: ${rem(750)};
    border: 0;
    padding ${rem(16)} ${rem(40)};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    color: ${black};
`;

const PrevNavButton = styled.div`
  position: absolute;
  top: ${rem(32)};
  left: ${rem(8)};

  img {
    position: relative;
    left: -1px;
  }
`;

const NextNavButton = styled.div`
  position: absolute;
  top: ${rem(32)};
  right: ${rem(8)};

  img {
    position: relative;
    right: -1px;
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(40)};
  width: ${rem(104)};
  margin-left: auto;
  margin-right: auto;
  background: ${backgroundLightViolet};
  border: 1px solid ${borderLightViolet};
  border-radius: ${borderRadius};
  color: ${purple};
  text-align: center;
`;

const MobileDatepickerWidgetButtonsExpanded = styled.div`
  height: ${rem(52)};
  margin: 0 16px 16px;
`;

const MobileDatepickerWidgetButtons = styled.div`
  height: ${rem(80)};
  margin: 0 16px 16px;
`;

const DatepickerWidgetDivider = styled(Common.Divider)`
  height: 3rem;

  ${media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
    height: 70%;
    border-color: ${brandColor};
  }
`;

const DatepickerDate = styled.h4`
  margin: 0;
  height: 22px;
`;

const WidgetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${borderColor};
  border-radius: 3px;
  background: ${light};
  color: black;
`;

const WidgetButtonDate = styled.div`
  padding: 4px;
  width: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
`;

const WidgetButtonDateDesktop = styled.span`
  height: 46px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
`;

const MobileConfirmPanel = styled.div`
  flex: 0 0;
`;

const MobileScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
`;

const MobileMonthContainer = styled.div`
  overflow-x: auto;
  padding: 0 16px;
`;

const DatepickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const CheckinButton = styled.div`
  margin: 0;
  overflow: visible;
  font: inherit;
  text-transform: none;
  appearance: none;
  box-sizing: border-box;
  padding: 0 28px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  :not(:disabled) {
    cursor: pointer;
  }

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  background-color: ${light};
  color: ${black};
  border-radius: 4px;
  border: 1px solid ${borderColor};

  :hover,
  :focus {
    background-color: ${light};
    color: ${black};
    border-color: ${boxShadowColor};
  }
`;

const Card = styled(CardComponent)`
  display: flex;
`;

export default {
  Duration,
  PrevNavButton,
  NextNavButton,
  DayDefault,
  DayDisabled,
  DaySelected,
  DaySelectedFirst,
  DaySelectedLast,
  DayLabel,
  DatePickerPanel,
  Month,
  MonthLabel,
  NavButton,
  Weekdays,
  MobileDatepickerWidgetButtons,
  MobileDatepickerWidgetButtonsExpanded,
  DatepickerWidgetDivider,
  DatepickerDate,
  WidgetButton,
  WidgetButtonDate,
  WidgetButtonDateDesktop,
  MobileConfirmPanel,
  MobileScrollContainer,
  MobileMonthContainer,
  DatepickerContainer,
  CheckinButton,
  Card,
};
