import styled from 'styled-components';
import DropdownStyled from 'components/common/Dropdown/Dropdown.styled';
import { textColorMuted } from 'style/variables';

const NumberOfRooms = styled.div`
  color: ${textColorMuted};
`;

const OccupancyDropdown = styled(DropdownStyled.Dropdown)`
  padding: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

export default {
  NumberOfRooms,
  OccupancyDropdown,
};
