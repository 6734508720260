import React, { useCallback, useState } from 'react';
import AlertModal from 'components/AlertModal';
import { SanitizedHTML } from 'components/widgets/SanitizedHTML';

export type CustomButton = {
  label: string;
  cb: () => void;
};

export type CustomButtons = {
  ok: CustomButton;
  cancel: CustomButton;
};

export interface ModalAlertProps {
  message: string;
  close: () => void;
  buttons?: CustomButtons;
}

export const ModalAlertContext = React.createContext<{
  setAlert: (message: string, afterClose?: () => void, buttons?: CustomButtons) => void;
}>({
  setAlert: () => undefined,
});

const ModalAlertProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [alertData, setAlertData] = useState<ModalAlertProps>();

  const closeModal = useCallback(() => {
    setAlertData(undefined);
  }, []);

  const setAlert = useCallback(
    (message: string, afterClose?: () => void, buttons?: CustomButtons) => {
      setAlertData({
        message,
        close: () => {
          closeModal();
          afterClose?.();
        },
        buttons,
      });
    },
    [closeModal],
  );

  return (
    <ModalAlertContext.Provider value={{ setAlert }}>
      {alertData && (
        <AlertModal visible={!!alertData} onClose={alertData.close}>
          {/* todo: wrap with purify when HW-1386 is done */}
          <SanitizedHTML tag="div" rawHTML={alertData.message} />
        </AlertModal>
      )}
      {children}
    </ModalAlertContext.Provider>
  );
};

export default ModalAlertProvider;
