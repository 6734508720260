import { useDay } from '@datepicker-react/hooks';
import React, { useContext, useMemo, useRef } from 'react';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';

interface DayProps {
  dayLabel: string;
  month: number;
  date: Date;
}

const Day: React.FC<DayProps> = ({ dayLabel, month, date }) => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isStartDateSelected,
    isEndDateSelected,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const { isSelected, isWithinHoverRange, disabledDate, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const dayProps: React.ButtonHTMLAttributes<HTMLButtonElement> = useMemo(
    () => ({
      onClick,
      onKeyDown,
      onMouseEnter,
      tabIndex,
      type: 'button',
      ref: dayRef,
      hovered: isWithinHoverRange,
    }),
    [isWithinHoverRange, onClick, onKeyDown, onMouseEnter, tabIndex],
  );

  if (!dayLabel) {
    return <div />;
  }

  if (isStartDateSelected(date)) {
    return (
      <CustomDatepickerStyle.DaySelectedFirst data-testid={`month-${month}-day-${dayLabel}`} {...dayProps}>
        {dayLabel}
      </CustomDatepickerStyle.DaySelectedFirst>
    );
  }
  if (isEndDateSelected(date)) {
    return (
      <CustomDatepickerStyle.DaySelectedLast data-testid={`month-${month}-day-${dayLabel}`} {...dayProps}>
        {dayLabel}
      </CustomDatepickerStyle.DaySelectedLast>
    );
  }
  if (isSelected) {
    return (
      <CustomDatepickerStyle.DaySelected data-testid={`month-${month}-day-${dayLabel}`} {...dayProps}>
        {dayLabel}
      </CustomDatepickerStyle.DaySelected>
    );
  }

  if (disabledDate) {
    return (
      <CustomDatepickerStyle.DayDisabled data-testid={`month-${month}-day-${dayLabel}`} {...dayProps}>
        {dayLabel}
      </CustomDatepickerStyle.DayDisabled>
    );
  }

  return (
    <CustomDatepickerStyle.DayDefault data-testid={`month-${month}-day-${dayLabel}`} {...dayProps}>
      {dayLabel}
    </CustomDatepickerStyle.DayDefault>
  );
};

export default Day;
