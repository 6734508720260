/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import arrowCheckboxCheckedDisabled from 'assets/images/icons/ui-kit/arrow-checkbox-checked-disabled.svg';
import arrowCheckboxChecked from 'assets/images/icons/ui-kit/arrow-checkbox-checked.svg';
import arrowCheckboxIndeterminateDisabled from 'assets/images/icons/ui-kit/arrow-checkbox-indeterminate-disabled.svg';
import arrowCheckboxIndeterminate from 'assets/images/icons/ui-kit/arrow-checkbox-indeterminate.svg';
import { rem } from 'style/functions';
import {
  borderColor,
  brandColor,
  disabledBackgroundGray,
  modalBackgroundColor,
  mutedCheckboxBorderColor,
  selectedFocusCheckboxColor,
} from 'style/variables';

const radius = 2;

const InputCheckbox = styled.input<{ muted?: boolean; emphasized?: boolean }>`
  box-sizing: border-box;
  margin: -4px 0 0 0;
  font: inherit;
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  min-width: 16px;
  border-radius: ${rem(radius)};
  border: 1px solid ${({ emphasized }) => (emphasized ? brandColor : borderColor)};

  &:focus {
    outline: none;
    border: 1px solid ${brandColor};
  }

  &:checked,
  &:indeterminate {
    background-color: ${brandColor};
  }

  &:checked:focus,
  &:indeterminate:focus {
    background-color: ${selectedFocusCheckboxColor};
  }

  &:checked {
    background-image: url(${arrowCheckboxChecked});
  }
  &:indeterminate {
    background-image: url(${arrowCheckboxIndeterminate});
  }

  &:disabled {
    background-color: ${modalBackgroundColor};
    border-color: ${disabledBackgroundGray};
  }

  &:disabled:checked {
    background-image: url(${arrowCheckboxCheckedDisabled});
  }
  &:disabled:indeterminate {
    background-image: url(${arrowCheckboxIndeterminateDisabled});
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  ${({ muted }) =>
    muted
      ? css`
          border-color: ${mutedCheckboxBorderColor};
        `
      : ''}
`;

export const Styled = {
  InputCheckbox,
};
