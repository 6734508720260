import useSearchParameter from 'common/hooks/useSearchParameter';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { campaignAtom, campaignIsReadyAtom, updateCampaignAtom } from 'atoms/campaignAtom';
import { useSession } from 'atoms/hooks/useSession';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { CampaignRequest } from 'backend/api/general/generalRequest';
import { getHttpStatus, HttpStatus } from 'backend/serverError';
import { env } from 'environments/environment';
import cookieBasedCampaignStorage from 'utils/storage/cookie/CookieBasedCampaignStorage';

const SEARCH_PARAMETERS_OPTIONS = {
  raw: true,
  replace: true,
};

export interface CampaignNameQuery {
  campaignName?: string;
}

export const useWatchCampaign = () => {
  const setCampaign = useSetAtom(campaignAtom);
  const updateCampaign = useSetAtom(updateCampaignAtom);
  const setReady = useSetAtom(campaignIsReadyAtom);
  const { session } = useSession();
  const { data: locationData } = useUserLocation();
  const [campaignNameParam] = useSearchParameter<CampaignNameQuery, string>('campaignName', SEARCH_PARAMETERS_OPTIONS);

  const updateCampaignCb = useCallback(
    async (payload: CampaignRequest): Promise<void> => {
      try {
        const _campaign = await updateCampaign(payload);

        setCampaign(_campaign);
        cookieBasedCampaignStorage.set(_campaign);

        return Promise.resolve();
      } catch (err) {
        if (getHttpStatus(err) === HttpStatus.NotFound) {
          cookieBasedCampaignStorage.remove();

          if (payload.campaignName) {
            return updateCampaignCb({ ...payload, campaignName: undefined });
          }
        }

        return Promise.resolve(undefined);
      } finally {
        setReady(true);
      }
    },
    [setCampaign, setReady, updateCampaign],
  );

  useEffect(() => {
    if (!session.initialized) {
      return;
    }

    const campaignFromCookie = cookieBasedCampaignStorage.get();

    if (campaignNameParam || !campaignFromCookie) {
      updateCampaignCb({
        authenticated: session.authenticationState,
        countryCode: locationData.countryCode || env.location.fallbackCountryCode,
        campaignName: campaignNameParam,
      });
    } else {
      setCampaign(campaignFromCookie);
      setReady(true);
    }
  }, [
    campaignNameParam,
    locationData.countryCode,
    session.authenticationState,
    session.initialized,
    setCampaign,
    setReady,
    updateCampaignCb,
  ]);
};
