import DOMPurify from 'dompurify';
import React, { memo } from 'react';
import { unescapeHTML } from 'utils/stringUtils';

interface SanitizedHTMLProps {
  tag: React.ElementType;
  rawHTML?: string;
  className?: string;
  style?: Record<string, string>;
}

const purify = DOMPurify(window);

purify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const SanitizedHTML = memo(function SanitizedHTML({ tag, rawHTML, className, style }: SanitizedHTMLProps) {
  return React.createElement(tag, {
    dangerouslySetInnerHTML: { __html: purify.sanitize(unescapeHTML(rawHTML)) },
    className,
    style,
  });
});

export { SanitizedHTML };
