import useSearchParameter from 'common/hooks/useSearchParameter';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import OffscreenBurgerMenu from 'views/offscreens/OffscreenBurgerMenu';
import OffscreenDatepickerMenu from 'views/offscreens/OffscreenDatepickerMenu';
import OffscreenSearchMenu from 'views/offscreens/OffscreenSearchMenu';

interface OffscreenModeParams {
  offscreen: OffscreenMode;
}

export const OffscreenProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const history = useHistory();

  const goToPreviousOffscreenMode = useCallback(() => {
    history.goBack();
  }, [history]);

  const [offscreenModeParam, setOffscreenModeParam] = useSearchParameter<OffscreenModeParams, OffscreenMode>(
    'offscreen',
    { raw: true },
  );

  const [offscreenMode, setOffscreenModeInternal] = useState<OffscreenMode>(offscreenModeParam || OffscreenMode.hidden);

  const setOffscreenMode = useCallback(
    (mode: OffscreenMode) => {
      if (isMobileLayout) {
        if (mode !== OffscreenMode.hidden) {
          setOffscreenModeParam(mode);
        } else {
          setOffscreenModeParam(undefined);
        }
      }
    },
    [isMobileLayout, setOffscreenModeParam],
  );

  useEffect(() => {
    if (!isMobileLayout) {
      setOffscreenModeParam(undefined);
    }
  }, [isMobileLayout, setOffscreenModeParam]);

  useEffect(() => {
    setOffscreenModeInternal(offscreenModeParam || OffscreenMode.hidden);
  }, [offscreenModeParam]);

  return (
    <OffscreenContext.Provider
      value={{
        offscreenMode,
        setOffscreenMode,
        goToPreviousOffscreenMode,
      }}
    >
      {children}

      {isMobileLayout && offscreenMode === OffscreenMode.burger && <OffscreenBurgerMenu />}
      {isMobileLayout &&
        (offscreenMode === OffscreenMode.search || offscreenMode === OffscreenMode.searchDatepicker) && (
          <OffscreenSearchMenu />
        )}
      {isMobileLayout && offscreenMode === OffscreenMode.datepicker && <OffscreenDatepickerMenu />}
    </OffscreenContext.Provider>
  );
};
