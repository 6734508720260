import { createGlobalStyle } from 'styled-components';
import { legacyAnimation } from 'style/animations.styled';
import { tagStyles } from 'style/tag.styled';

import 'style/layout.css';

export const GlobalStyle = createGlobalStyle`
    ${tagStyles}
    ${legacyAnimation}
`;
