/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import { Styled } from 'components/InputCheckbox.styled';
import ButtonStyled from 'components/common/Button/Button.styled';

import StyledCardComponents from 'components/common/Card/Card.styled';
import LabelStyled from 'components/common/Label/Label.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import {
  borderRadius,
  brandColor,
  brandColorDark,
  brandColorDarkFilter,
  textColorMuted,
  dangerBackground,
  disabledBackgroundGray,
  green,
  lightGreen,
  notificationSuccess,
  primaryBackground,
  textColorDanger,
  successBackground,
  textColorSuccess,
  tinyText,
  tripAdvisorColor,
  gray,
  white,
  black,
  veryLightGray,
  backgroundLightViolet,
  borderLightViolet,
  purple,
  borderColorLight,
  lightYellow,
  yellow,
  darkViolet,
  backgroundMediumGray,
  borderColorLightest,
  backgroundGrayBlue,
  backgroundVeryLightBlue,
  backgroundLightPeachPink,
  backgroundPink,
  red2,
  backgroundLightViolet2,
  backgroundLightViolet3,
  backgroundVeryLight,
  brown3,
  boxShadowLightPink,
  backgroundVeryLight2,
  boxShadowLightGray2,
} from 'style/variables';

const TinyText = styled.div`
  font-size: ${tinyText};
`;

const LargeText = styled.div`
  font-size: ${rem(18)};
`;

const XXLargeText = styled.div`
  font-size: ${rem(30)};
  line-height: 1.2;
`;

const BaseTextColor = styled.div`
  color: ${black};

  &:active,
  &:hover {
    color: ${black};
  }
`;

const BookingSummaryColor = styled.h2`
  color: ${darkViolet};
`;

const BaseLineHeight = styled.div`
  line-height: 1.5;
`;

const BaseTextSize = styled(BaseLineHeight)`
  font-size: 1rem;
`;

const OauthButton = styled(ButtonStyled.Button)`
  width: 100%;
  padding: 14px 0;
  height: auto !important;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: ${backgroundMediumGray};
  }

  &:disabled {
    background-color: ${disabledBackgroundGray};
    color: ${gray};

    img {
      opacity: 0.5;
    }
  }

  img {
    height: 24px;
  }

  @media (min-width: 480px) {
    white-space: nowrap;
  }
`;

const MediumText = styled.div`
  font-weight: 500;
`;

const ListIcon = styled.span`
  & > img {
    width: ${rem(30)};
    height: ${rem(30)};
    margin-right: ${rem(6)};
    filter: ${brandColorDarkFilter};
  }
`;

const ListIconBar = styled(ListIcon)`
  position: relative;
  left: 2px;
`;

const TextLineThrough = styled.div`
  text-decoration: line-through;
`;

const MobileDestinationsSearch = styled.div`
  border-bottom: 1px solid ${borderColorLightest};
`;

const DiscountLabel = styled.div`
  position: absolute;
  z-index: 1;
  padding: ${rem(8)} ${rem(12)};
  padding-right: ${rem(21)};
  background-color: ${lightGreen};
  color: ${green};
  font-weight: 400;
  right: -21px;
  border: 1px dashed currentColor;

  &:before {
    content: '';
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    border-top: ${rem(10)} solid ${green};
    border-left: ${rem(10)} solid ${green};
    border-right: ${rem(10)} solid transparent;
    border-bottom: ${rem(10)} solid transparent;
  }
`;

const DiscountLabelInline = styled(DiscountLabel)`
  position: static;
  background: ${notificationSuccess};
  color: ${green};
  font-weight: 500;
  transform: none;
  padding: ${rem(10)} ${rem(15)};
  margin-right: -16px;

  &:after {
    content: none;
  }
`;

const Badge = styled.span`
  position: relative;
  border-radius: ${borderRadius} ${borderRadius} ${borderRadius} 0;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    border-top: ${rem(4)} solid ${textColorSuccess};
    border-left: ${rem(5)} solid ${textColorSuccess};
    border-right: ${rem(4)} solid transparent;
    border-bottom: ${rem(4)} solid transparent;
  }
`;

const RoundedBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rem(12)};
  height: 24px;
  width: 56px;
  font-size: ${rem(12)};
  color: ${white};
  background: rgba(255, 255, 255, 0.44);
`;

const ButtonDefaultActive = styled.button`
  border-color: ${black};

  &:hover {
    border-color: ${black};
  }
`;

const TextUnderline = styled.div`
  text-decoration: underline;
`;

const LabelPrimary = styled(LabelStyled.Label)`
  background-color: ${primaryBackground};
  color: ${brandColor};
  border: 0;
  font-size: 1rem;
  font-weight: 700;
`;

const LabelPurple = styled(LabelStyled.Label)`
  flex-shrink: 0;
  border-radius: ${borderRadius};
  border: 1px solid ${borderLightViolet};
  background-color: ${backgroundLightViolet};
  padding: ${rem(2)} ${rem(8)};
  color: ${purple};
  font-size: ${rem(10)};

  svg {
    margin-right: 5px;
  }
`;

const CardLabelPrimary = styled(LabelPurple)`
  flex-shrink: 0;
  border-radius: ${borderRadius};
  border: 1px solid ${brandColor};
  background-color: ${primaryBackground};
  padding: ${rem(2)} ${rem(8)};
  color: ${brandColor};
  font-size: ${rem(12)};

  svg {
    margin-right: ${rem(4)};
  }
`;

const LabelPurpleSmall = styled(LabelPurple)`
  padding-top: ${rem(2)};
  padding-bottom: ${rem(2)};
`;

const LabelPurpleBorderless = styled(LabelPurple)`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
  padding-right: ${rem(4)};
  font-size: ${rem(12)};
  border: 0;
`;

const LabelWarning = styled(LabelStyled.Label)`
  display: flex;
  color: ${textColorDanger};
  background: ${dangerBackground};
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
  border: 0;
`;

const CheckboxLarge = styled(Styled.InputCheckbox)`
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: ${rem(3)};
  border: 1px solid ${brandColor};

  &:focus {
    border-color: ${brandColor};
  }
`;

const SearchSmall = styled.form`
  position: relative;
  input,
  input:focus {
    border-color: ${black};
    border-radius: ${rem(3)};
    height: 40px;
    padding-left: 15px;
    padding-right: 50px;
  }
  svg {
    width: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const RatingStars = styled.div`
  position: relative;
  top: -2px;
`;

const TripAdvisorRating = styled.span<{ small?: boolean }>`
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  color: transparent;

  width: ${(props) => (props.small ? 7 : 12)}px;
  height: ${(props) => (props.small ? 7 : 12)}px;
  margin-right: ${(props) => (props.small ? 6 : 8)}px;

  background: ${tripAdvisorColor};
  box-shadow: 0 0 0 1.5px ${tripAdvisorColor};
`;

const SignInBanner = styled.div`
  background: ${backgroundGrayBlue};
  border-radius: ${borderRadius};
`;

const AboutBanner = styled.div`
  background: ${backgroundVeryLightBlue};
  padding: ${rem(60)};
`;

const ToggleBox = styled.span`
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
  background: ${borderColorLight};
  border-radius: 12px;
  padding: 2px;
  box-sizing: border-box;
  transition: background-color 0.3s;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${white};
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    transform: translate(2px, -50%);
    transition: transform 0.3s;
  }
`;

const Toggle = styled.label`
  input {
    display: none;
  }

  input:checked + ${ToggleBox} {
    background: ${brandColorDark};

    &:after {
      transform: translate(26px, -50%);
    }
  }
`;

const HeightXSmall = styled.div`
  height: 100px;

  @media (max-width: 767px) {
    height: 64px;
  }
`;

const CardButton = styled(ButtonStyled.Button)`
  color: ${textColorMuted};
  width: 100%;
  background: ${veryLightGray};
  height: 48px;
  border-radius: 0 0 ${borderRadius} ${borderRadius};
`;

const BackgroundSuccess = styled.div`
  background: ${successBackground};
`;

const CardStatus = styled(HeightXSmall)`
  ${LabelStyled.Label} {
    font-size: 14px;
    border-radius: ${rem(16)};
    padding: ${rem(5)} ${rem(15)};
    border: 0;
  }
`;

const CardStatusSuccess = styled(CardStatus)`
  background: ${successBackground};

  ${LabelStyled.Label} {
    background: ${lightGreen};
    color: ${green};
  }
`;

const CardStatusPrimary = styled(CardStatus)`
  background: ${primaryBackground};

  ${LabelStyled.Label} {
    background: transparent;
    color: ${brandColor};
    border: 1px solid ${brandColor};
  }
`;

const CardStatusDanger = styled(CardStatus)`
  background: ${backgroundLightPeachPink};

  ${LabelStyled.Label} {
    background: ${backgroundPink};
    color: ${red2};
  }
`;

const CardStatusPending = styled(CardStatus)`
  background: ${backgroundLightViolet2};

  ${LabelStyled.Label} {
    background: ${backgroundLightViolet3};
    color: ${purple};
  }
`;

const AlignBaseline = styled.div`
  align-items: baseline;
`;

const Text = styled.div`
  padding: ${rem(80)} 0;
  background: transparent linear-gradient(180deg, ${backgroundVeryLight} 0%, ${white} 100%);
  line-height: 1.75;

  p {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: ${rem(8)};
  }

  h1 {
    margin-bottom: ${rem(40)};
    font-size: 20px;
    line-height: 1.3;
  }

  h2 {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(8)};
    font-size: ${rem(14)};
    font-weight: 700;
    line-height: 1.75;
  }

  ul {
    margin-top: 0;
    margin-left: 16px;
    padding-left: ${rem(15)};
  }
`;

const BottomSticky = styled.div`
  bottom: 0;
  left: 0;
`;

const BottomStickyFixed = styled(BottomSticky)`
  position: fixed;
  padding: 0;
  z-index: 4;
`;

const PaddingTinyVertical = styled.div`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
`;

const PaddingTinyHorizontal = styled.div`
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};
`;

const PaddingTiny = styled.div`
  padding: ${rem(10)};
`;

const PaddingExtraTiny = styled.div`
  padding: ${rem(8)};
`;

const BadgeSmall = styled.div`
  font-size: ${rem(10)};
  padding: ${rem(1)} ${rem(10)};
  height: auto;
`;

const MarginTinyNegative = styled.div`
  margin: ${rem(-8)};
`;

const MarginTinyNegativeHorizontal = styled.div`
  margin-left: ${rem(-8)};
  margin-right: ${rem(-8)};
`;

const MarginTinyTop = styled.div`
  margin-top: ${rem(4)};
`;

const MarginTinyVertical = styled.div`
  margin-top: ${rem(4)};
  margin-bottom: ${rem(4)};
  display: inline-block;
`;

const MarginTinyLeft = styled.div`
  margin-left: ${rem(4)};
`;

const MarginTinyRight = styled.div`
  margin-right: ${rem(4)};
`;

const MarginTinyBottom = styled.div`
  margin-bottom: ${rem(4)};
`;

const VerticalAlignTop = styled.div`
  vertical-align: top;
`;

const BadgeDanger = styled.div`
  display: inline-flex;
  height: ${rem(24)};
  color: ${white};
  font-size: ${rem(12)};
  background: ${red2};
`;

const Card = styled(StyledCardComponents.StyledCard)`
  display: block;
  height: 176px;
  border-radius: 0.3rem;

  &.flex {
    display: flex;
  }

  &.height-1-1 {
    height: 100%;
  }
`;

const CardPink = styled(Card)`
  background: ${primaryBackground} !important;
  border: none;

  h3 {
    color: ${brown3};
  }

  img.position-top-left {
    left: ${rem(80)};
  }
`;

const Counter = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${rem(24)};
  height: ${rem(32)};
  color: ${brown3};
  font-size: ${rem(20)};
  font-weight: 700;
  background: ${white};
  overflow: hidden;
  box-shadow: 0 4px 8px ${boxShadowLightPink};
  margin-left: 2px;
  margin-right: 2px;
  border-radius: ${rem(2)};

  div:first-child {
    margin-top: 0;
  }
`;

const CounterSeparator = styled(Counter)`
  background: transparent;
  box-shadow: none;
  justify-content: flex-end;
  line-height: 1.3;
  margin-left: -2px;
  margin-right: -2px;
`;

const Container = styled.div`
  background: ${backgroundVeryLight2};
`;
const HomeViewMobileContainer = styled(Container)`
  [class*='width'] {
    box-sizing: border-box;
    width: 100%;
    /* 1 */
    max-width: 100%;
  }
`;

const GoogleMap = styled.div`
  .gm-style-iw {
    padding: 12px !important;
    min-height: 265px !important;
    border-radius: 6px !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
    max-height: 100% !important;
  }

  .gm-ui-hover-effect {
    top: -7px !important;
    right: -7px !important;
  }
`;

const HomeMobileSearch = styled(StyledCardComponents.StyledCard)`
  border-radius: 16px 16px 0 0;
  margin-top: -16px;
  padding-top: 16px;
`;

const BackgroundLightBlue = styled.div`
  background: ${backgroundVeryLight2};
`;

const AboutCard = styled(StyledCardComponents.StyledCard)`
  box-shadow: 0 2px 2px ${boxShadowLightGray2};

  img {
    height: 72px;
  }
`;

const News = styled.div`
  width: calc(60% + 50px);

  @media (min-width: 992px) {
    margin-top: ${rem(58)};
  }

  .flex {
    height: 72px;

    @media (max-width: 991px) {
      margin-top: 0;
    }
  }
  ${media.mobile} {
    width: 100%;
  }

  > div {
    margin-top: 30px;
    display: flex;
    row-gap: 50px;
    flex-wrap: wrap;
    > div {
      width: calc(100% * 1 / 3.001);
    }
    ${media.mobile} {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }
`;

const CardBestPrice = styled.div`
  background: ${primaryBackground};
  padding: ${rem(14)} ${rem(15)} ${rem(9)};
  margin-top: -${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;

const CardShadow = styled(StyledCardComponents.StyledCard)`
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.25);
`;

const CardRoomInfo = styled.div`
  background: rgba(0, 0, 0, 0.5);
  padding: ${rem(8)};
  border-radius: ${borderRadius};
  color: ${white};
`;

const CardMoveIn = styled.img`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  transform: translate(55%, -40%);
`;

const Covid = styled.div`
  background: transparent linear-gradient(90deg, ${lightYellow} 0%, ${yellow} 100%) 0 0 no-repeat;
  padding: ${rem(8)};

  a {
    color: ${black};
    text-decoration: underline;
  }

  button {
    display: flex;
    padding: 0;
    border: 0;
    background: transparent;
    margin-left: ${rem(16)};
    width: 20px;
    height: 20px;
  }
`;

const EllipsisMultiline = styled.p<{ lines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ForgotLink = styled.a`
  color: inherit;
  &:hover {
    color: ${brandColor};
    text-decoration: none;
  }
`;

export default {
  EllipsisMultiline,
  Covid,
  CardMoveIn,
  CardRoomInfo,
  CardBestPrice,
  MarginTinyTop,
  MarginTinyVertical,
  MarginTinyLeft,
  MarginTinyRight,
  MarginTinyBottom,
  CardLabelPrimary,
  HomeMobileSearch,
  News,
  AboutCard,
  GoogleMap,
  Container,
  HomeViewMobileContainer,
  CounterSeparator,
  Counter,
  Card,
  CardPink,
  BadgeDanger,
  VerticalAlignTop,
  MarginTinyNegative,
  MarginTinyNegativeHorizontal,
  BadgeSmall,
  PaddingTinyHorizontal,
  PaddingTiny,
  BottomSticky,
  BottomStickyFixed,
  Text,
  AlignBaseline,
  CardStatus,
  CardStatusPrimary,
  CardStatusSuccess,
  CardStatusDanger,
  CardStatusPending,
  BackgroundSuccess,
  BackgroundLightBlue,
  CardButton,
  BaseLineHeight,
  HeightXSmall,
  Toggle,
  ToggleBox,
  TinyText,
  MediumText,
  BaseTextSize,
  XXLargeText,
  ListIcon,
  ListIconBar,
  TextLineThrough,
  DiscountLabel,
  DiscountLabelInline,
  Badge,
  RoundedBadge,
  ButtonDefaultActive,
  TextUnderline,
  LabelPrimary,
  LabelPurple,
  LabelPurpleSmall,
  LabelPurpleBorderless,
  MobileDestinationsSearch,
  CheckboxLarge,
  SearchSmall,
  RatingStars,
  TripAdvisorRating,
  LabelWarning,
  BaseTextColor,
  SignInBanner,
  AboutBanner,
  BookingSummaryColor,
  PaddingTinyVertical,
  PaddingExtraTiny,
  LargeText,
  CardShadow,
  OauthButton,
  ForgotLink,
};
