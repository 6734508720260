import styled, { css } from 'styled-components';
import { ButtonSize } from 'components/common/Button/Button.types';
import {
  black,
  borderColor,
  boxShadowColor,
  brandColor,
  disabledBackgroundGray,
  light,
  pinkHoveredBackgroundColor,
} from 'style/variables';

const Button = styled.button<{ size?: ButtonSize }>`
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  :disabled {
    background: ${disabledBackgroundGray} !important;
  }

  :not(:disabled) {
    cursor: pointer;
  }

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
  }

  ${({ size }) => {
    switch (size) {
      case ButtonSize.Small:
        return css`
          height: 36px;
          white-space: nowrap;
          padding: 0 20px;
          font-size: 14px;
          line-height: 18px;
        `;
      case ButtonSize.Large:
        return css`
          height: 48px;
          padding: 0 48px;
          font-size: 18px;
          line-height: 20px;
        `;
      case ButtonSize.Normal:
      default:
        return css`
          height: 40px;
          padding: 0 28px;
          font-size: 14px;
          line-height: 18px;
        `;
    }
  }}
`;

const DefaultButton = styled(Button)`
  background-color: ${light};
  color: black;
  border-radius: 4px;
  border: 1px solid ${borderColor};

  :hover,
  :focus {
    background-color: ${light};
    color: ${black};
    border-color: ${boxShadowColor};
  }
`;

const PrimaryButton = styled(Button)`
  background-color: ${brandColor};
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px ${brandColor}33;
  font-weight: 700;

  :hover,
  :focus {
    background-color: ${pinkHoveredBackgroundColor};
    color: white;
  }
`;

const OutlinedButton = styled(DefaultButton)`
  border-color: ${brandColor};

  :hover,
  :focus {
    border-color: ${brandColor};
  }
`;

const TextButton = styled(Button)`
  padding: 0;
  line-height: 1.5;
  background: none;
  color: black;
  height: auto;

  :hover,
  :focus {
    color: black;
  }
`;

export default { Button, DefaultButton, PrimaryButton, OutlinedButton, TextButton };
