import styled, { css } from 'styled-components';
import { ToastState, ToastType } from 'components/common/Toast/Toast.types';
import { fade } from 'style/animations.styled';
import media from 'style/media';
import {
  dangerBackground,
  notificationError,
  textColorSecondary,
  notificationInfoBackground,
  notificationSuccess,
  notificationWarn,
  notificationWarnBackground,
  successBackground,
} from 'style/variables';

const Container = styled.div<{ type?: ToastType; state?: ToastState }>`
  position: fixed;
  top: 15px;
  z-index: 1040;
  box-sizing: border-box;
  width: 100px;
  overflow: hidden;
  max-width: 580px;
  min-width: 350px;
  transform: translateX(-50%);

  left: 50%;
  margin-left: -50px;

  ${media.mobile} {
    min-width: 280px;
    left: 50% !important;
    width: calc(100% - 30px) !important;
    right: 15px;
    margin: 0;
  }

  box-shadow: none;
  padding: 18px 15px;
  border-radius: 4px;
  border: 1px solid;

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  a {
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: -2px;
    display: inline-flex;
  }

  animation: ${fade} 0.3s linear;

  ${({ state }) => {
    switch (state) {
      case ToastState.Opening:
        return css`
          animation: ${fade} 0.8s linear;
        `;
      case ToastState.Closing:
        return css`
          animation: ${fade} 0.2s linear;
          animation-direction: reverse;
          animation-timing-function: ease-in;
        `;
      case ToastState.Closed:
        return css`
          display: none;
        `;
      default:
        return '';
    }
  }}

  ${({ type }) => {
    switch (type) {
      case ToastType.Success:
        return css`
          border-color: ${notificationSuccess};
          background: ${successBackground};
        `;
      case ToastType.Warn:
        return css`
          border-color: ${notificationWarn};
          background: ${notificationWarnBackground};
        `;
      case ToastType.Error:
        return css`
          border-color: ${notificationError};
          background: ${dangerBackground};
        `;
      case ToastType.Info:
      default:
        return css`
          border-color: ${textColorSecondary};
          background: ${notificationInfoBackground};
        `;
    }
  }}
`;

const Content = styled.div`
  display: flex;
  align-items: start;
  line-height: 1.4;
`;

const NotificationIcon = styled.img`
  margin-top: 1px;
`;

export default { Container, Content, NotificationIcon };
