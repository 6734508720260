import useDateByOptions from 'common/hooks/useDateByOptions';
import useDatepickerDate from 'common/hooks/useDatepickerDate';
import { weekdayShortFormat } from 'common/utils/dateFormats';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import checkinIcon from 'assets/images/icons/checkin.svg';
import checkoutIcon from 'assets/images/icons/checkout.svg';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';

export const DATEPICKER_CHECKIN_ID = 'dpCheckinLink';

interface DatepickerWidgetButtonProps {
  startDate: Date | null;
  endDate: Date | null;
  onClick: React.EventHandler<React.SyntheticEvent>;
  widgetRef: React.RefObject<HTMLDivElement>;
  offscreenMode?: boolean;
}

export const DatepickerWidgetButton: React.FC<DatepickerWidgetButtonProps> = ({
  startDate,
  endDate,
  onClick,
  widgetRef,
  offscreenMode,
}) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const [t, i18n] = useTranslation();

  const startDateLabel = useDatepickerDate({
    lang: i18n.language,
    date: startDate,
    dateType: 'checkin',
  });
  const endDateLabel = useDatepickerDate({
    lang: i18n.language,
    date: endDate,
    dateType: 'checkout',
  });
  const startWeekday = useDateByOptions({
    lang: i18n.language,
    date: startDate,
    options: weekdayShortFormat,
  });
  const endWeekday = useDateByOptions({
    lang: i18n.language,
    date: endDate,
    options: weekdayShortFormat,
  });

  const mobileContent = useMemo(
    () => (
      <CustomDatepickerStyle.WidgetButton onClick={onClick}>
        <CustomDatepickerStyle.WidgetButtonDate id={DATEPICKER_CHECKIN_ID}>
          <div className="width-expand">
            <Text color={TextColor.Muted} className="width-1-1">
              {t('common.misc.startDatePick', 'Check-In')}
            </Text>
            <CustomDatepickerStyle.DatepickerDate data-testid="start-date">
              {startDateLabel.mobile}
            </CustomDatepickerStyle.DatepickerDate>
          </div>
        </CustomDatepickerStyle.WidgetButtonDate>
        <CustomDatepickerStyle.DatepickerWidgetDivider vertical />
        <CustomDatepickerStyle.WidgetButtonDate id="dpCheckoutLink">
          <div className="width-expand">
            <Text color={TextColor.Muted} className="width-1-1">
              {t('common.misc.endDatePick', 'Check-Out')}
            </Text>
            <CustomDatepickerStyle.DatepickerDate data-testid="end-date">
              {endDateLabel.mobile}
            </CustomDatepickerStyle.DatepickerDate>
          </div>
        </CustomDatepickerStyle.WidgetButtonDate>
      </CustomDatepickerStyle.WidgetButton>
    ),
    [onClick, endDateLabel, startDateLabel, t],
  );

  if (isMobileLayout) {
    if (offscreenMode) {
      return (
        <CustomDatepickerStyle.MobileDatepickerWidgetButtonsExpanded>
          {mobileContent}
        </CustomDatepickerStyle.MobileDatepickerWidgetButtonsExpanded>
      );
    }

    return (
      <CustomDatepickerStyle.MobileDatepickerWidgetButtons>
        {mobileContent}
      </CustomDatepickerStyle.MobileDatepickerWidgetButtons>
    );
  }

  return (
    <CustomDatepickerStyle.CheckinButton
      className="flex flex-middle width-expand padding-remove-horizontal height-1-1"
      onFocus={onClick}
      id={DATEPICKER_CHECKIN_ID}
      ref={widgetRef}
      tabIndex={2}
    >
      <CustomDatepickerStyle.WidgetButtonDateDesktop>
        <img className="padding-small padding-remove-right" src={checkinIcon} alt="" />
        <div className="width-expand">
          {startWeekday && <span data-testid={`start-weekday-${startDate?.getDay()}`}>{startWeekday}, </span>}
          <span data-testid="start-date">{startDateLabel?.desktop}</span>
        </div>
      </CustomDatepickerStyle.WidgetButtonDateDesktop>
      <CustomDatepickerStyle.DatepickerWidgetDivider vertical />
      <CustomDatepickerStyle.WidgetButtonDateDesktop id="dpCheckoutLink">
        <img className="padding-small padding-remove-right" src={checkoutIcon} alt="" />
        <div className="width-expand">
          {endWeekday && <span data-testid={`end-weekday-${endDate?.getDay()}`}>{endWeekday}, </span>}
          <span data-testid="end-date">{endDateLabel?.desktop}</span>
        </div>
      </CustomDatepickerStyle.WidgetButtonDateDesktop>
    </CustomDatepickerStyle.CheckinButton>
  );
};
