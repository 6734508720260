/**
 * @category Hotel Components
 * @packageDocumentation
 */
import { Replacement, useMask } from '@react-input/mask';
import { TFunction } from 'i18next';
import React, { ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { FormField } from 'components/common/FormField/FormField';
import Styled from 'components/common/InputField/InputField.styled';
import { FieldState } from 'components/common/InputField/InputField.types';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { env } from 'environments/environment';
import StyledCommon from 'style/Common.styled';
import { placeholderInput } from 'style/placeholderStyles';
import { focusInput } from 'utils/inputValidationUtils';
import { basicInputValidation } from 'utils/validation';

export function passwordValidationRule(password: string) {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$*-]{8,16}$/.test(password);
}

export function passwordErrorMessage(t: TFunction) {
  return t(
    'login-popup.invalid-password',
    'The password must contain 8-16 alphanumeric characters, at least 1 lower case, 1 upper case and 1 digit',
  );
}

function isNumber(value: string) {
  return /^[\d\s]+$/.test(value);
}

export enum LabelTypes {
  Floating = 'Floating',
  Static = 'Static',
}

interface InputFieldProps {
  /**
   * Label above the input field
   */
  label?: string;
  /**
   * Unique id. Required for autocomplete
   */
  id: string;
  /**
   * Name
   */
  name?: string;
  /**
   * Value
   */
  value: string;
  /**
   * The value for which validation is ignored
   */
  ignoredValidationValue?: string;
  /**
   * Placeholder value to display
   */
  placeholder?: string;
  /**
   * Callback to trigger on every value change
   * @param str
   */
  onChange?: (str: string, event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional error message to display if validation rule fails
   */
  errorMessage?: string;
  /**
   * Optional success message
   */
  successMessage?: string;
  /**
   * Optional validation rule (feel free to use regexp here)
   * @param str
   */
  validationRule?: (str: string) => boolean;
  /**
   * Optional style of parent container
   */
  containerStyle?: string;
  /**
   * If true, will display a pink star near the label
   */
  required?: boolean;

  /**
   * Optional message, which, if provided is used instead of standard '{fieldname} is required' message
   */
  requireMessage?: string;

  showRequiredSign?: boolean;

  /**
   * Html input type. Text or email, for example
   */
  inputType?: string;

  /**
   * The input mode content attribute is an enumerated attribute that specifies
   * what kind of input mechanism would be most helpful for users entering content.
   */
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';

  /**
   * Pass an input autocomplete value here. Set explicitly to 'off' if you are sure autocomplete is not required
   * Values reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete: string;
  /**
   * Basic validation just checks that input is at least two symbols long
   */
  doBasicValidation: boolean;
  /**
   * If provided, called when user press enter while the field is focused
   */
  onEnter?: () => void;
  /**
   * If provided, called on input focus
   */
  onFocus?: () => void;
  /**
   * If provided, called on input blur
   */
  onBlur?: () => void;
  /**
   * Optional inactiveness flag. When true, the input is blocked
   */
  disabled?: boolean;

  /**
   * Optional message to be shown below the field
   */
  helpMessage?: string;
  /**
   * Optional. If true, the placeholder is being drawn instead of the input.
   */
  isLoadingExternal?: boolean;

  allowHotJarRecording: boolean;

  maxLength?: number;
  autoFocus?: boolean;
  spellCheck?: boolean;
  labeType?: LabelTypes;
  mask?: string;
  maskReplacement?: Replacement;
}

export interface AbstractValidatedField {
  invalidate: (focus: boolean, checkForEmpty?: boolean) => string | undefined;
}

export interface InputFieldRef extends AbstractValidatedField {
  setIsError: (i18nMessage?: string, focus?: boolean) => void;
  focus?: () => void;
}

/**
 * Displays a (after design - styled) input field that can show the error messages if validationRule() returns false
 * @param label
 * @param initialValue
 * @param placeholder
 * @param onChange
 * @param validationRule
 * @param errorMessage
 * @param containerStyle
 * @param isRequired
 * @param inputType
 * @param onEnter
 * @constructor
 */
export const InputField = forwardRef(function InputField(
  {
    id,
    label,
    name,
    value,
    ignoredValidationValue,
    placeholder,
    onChange,
    doBasicValidation,
    errorMessage,
    containerStyle,
    required,
    showRequiredSign = true,
    inputType,
    inputMode,
    autocomplete,
    validationRule,
    onEnter,
    onFocus,
    onBlur,
    disabled,
    helpMessage,
    requireMessage,
    isLoadingExternal,
    allowHotJarRecording,
    maxLength,
    spellCheck,
    autoFocus,
    labeType: labelType = LabelTypes.Static,
    mask,
    maskReplacement,
  }: InputFieldProps,
  ref,
) {
  const [t] = useTranslation();
  const [focused, setFocused] = useState(false);
  const [state, setState] = useState<FieldState>(FieldState.basic);
  const [dirty, setDirty] = useState<boolean | null>(null);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const commonRef = useRef<HTMLInputElement>(null);
  const maskRef = useMask({ mask, replacement: maskReplacement });
  const inputRef = mask && maskReplacement ? maskRef : commonRef;

  const innerValidation = useCallback(
    (checkForEmpty: boolean) => {
      let _errorText: string | undefined;

      if (!ignoredValidationValue || value !== ignoredValidationValue) {
        if (value.length > 0) {
          if (doBasicValidation && !basicInputValidation(value)) {
            setState(FieldState.basicError);
            _errorText = t('validation.oneSymbolError', '{field} must be longer than 1 letter', {
              field: label || placeholder,
            });
          }
          if (validationRule && !validationRule(value)) {
            setState(FieldState.error);
            _errorText = errorMessage;
          }
        } else if (required && checkForEmpty) {
          setState(FieldState.emptyError);
          _errorText =
            requireMessage || t('validation.emptyField', '{field} is required', { field: label || placeholder });
        }
      } else {
        setState(FieldState.basic);
      }

      setErrorText(_errorText);

      return _errorText;
    },
    [
      ignoredValidationValue,
      value,
      required,
      doBasicValidation,
      validationRule,
      t,
      label,
      placeholder,
      errorMessage,
      requireMessage,
    ],
  );

  useEffect(() => {
    setState(FieldState.basic);
    setErrorText(undefined);
  }, [value, required]);

  // if dirty === null then dirty uninitialized
  // revalidation if validationRule changed
  useEffect(() => {
    setDirty((prev) => prev !== null);
  }, [validationRule]);

  useEffect(() => {
    if (dirty && !focused) {
      innerValidation(false);
      setDirty(false);
    }
  }, [dirty, focused, innerValidation]);

  useImperativeHandle(ref, () => ({
    invalidate(focus: boolean, checkForEmpty?: boolean) {
      const _errorMessage = innerValidation(checkForEmpty ?? true);

      if (_errorMessage && focus) {
        focusInput(inputRef);
      }

      if (!_errorMessage) {
        setState(FieldState.basic);
      }

      return _errorMessage;
    },
    setIsError(i18nMessage?: string, focus = true) {
      if (i18nMessage) {
        setState(FieldState.error);
        setErrorText(i18nMessage ?? errorMessage);
      }
      if (focus) {
        focusInput(inputRef);
      }
    },
    setIsSuccess() {
      setState(FieldState.success);
    },
  }));

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (onChange && (newValue.length === 0 || inputType !== 'number' || isNumber(newValue))) {
        onChange(newValue, e);
      }

      setErrorText(undefined);
    },
    [inputType, onChange],
  );

  const onFocusInput = useCallback(() => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const onBlurInput = useCallback(() => {
    setFocused(false);
    innerValidation(true);
    if (onBlur) {
      onBlur();
    }
  }, [innerValidation, onBlur]);

  const onKeyDownInput = useCallback(
    (e) => {
      if (e.key === 'Enter' && onEnter) {
        onEnter();
      }
    },
    [onEnter],
  );

  // don't use memo, it breaks input mask
  const input = (
    <ReactPlaceholder type="rect" ready={!isLoadingExternal} showLoadingAnimation style={placeholderInput}>
      <Styled.Input
        labeled={labelType === LabelTypes.Floating}
        id={id}
        name={name}
        ref={inputRef}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        className={allowHotJarRecording ? 'data-hj-allow' : 'data-hj-suppress'}
        state={state}
        type={inputType === 'number' ? 'text' : inputType}
        inputMode={inputMode}
        autoCapitalize={inputMode === 'email' ? 'off' : undefined}
        value={value}
        onChange={onChangeValue}
        autoComplete={autocomplete}
        placeholder={label ? '' : placeholder}
        maxLength={maxLength || (doBasicValidation ? env.inputs.basicMaxLength : undefined)}
        disabled={disabled}
        autoFocus={autoFocus}
        spellCheck={spellCheck}
        onKeyDown={onKeyDownInput}
      />
      {label && labelType === LabelTypes.Floating && (
        <Styled.InputLabel htmlFor={id}>{`${label}${required && showRequiredSign ? '*' : ''}`}</Styled.InputLabel>
      )}
      {helpMessage && (
        <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
          {helpMessage}
        </Text>
      )}
      {errorText && <StyledCommon.ErrorLabel>{errorText}</StyledCommon.ErrorLabel>}
    </ReactPlaceholder>
  );

  return (
    <>
      {labelType === LabelTypes.Floating && <Styled.InputField empty={!value}>{input}</Styled.InputField>}
      {labelType === LabelTypes.Static && (
        <FormField className={`width-1-1 ${containerStyle || ''}`} label={label} isRequired={required} fieldId={id}>
          {input}
        </FormField>
      )}
    </>
  );
});
