import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import Styled from 'components/common/Button/Button.styled';
import { ButtonSize, ButtonType } from 'components/common/Button/Button.types';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: ButtonType;
  size?: ButtonSize;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { styleType, children, ...rest },
  ref,
) {
  switch (styleType) {
    case ButtonType.Primary:
      return (
        <Styled.PrimaryButton ref={ref} {...rest}>
          {children}
        </Styled.PrimaryButton>
      );

    case ButtonType.Outlined:
      return (
        <Styled.OutlinedButton ref={ref} {...rest}>
          {children}
        </Styled.OutlinedButton>
      );

    case ButtonType.Text:
      return (
        <Styled.TextButton ref={ref} {...rest}>
          {children}
        </Styled.TextButton>
      );

    case ButtonType.Default:
    default:
      return (
        <Styled.DefaultButton ref={ref} {...rest}>
          {children}
        </Styled.DefaultButton>
      );
  }
});
