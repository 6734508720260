import styled from 'styled-components';
import ButtonStyled from 'components/common/Button/Button.styled';
import { rem } from 'style/functions';
import media from 'style/media';
import { borderRadius, brandColor, white } from 'style/variables';

const offset_16 = 16;
const offset_15 = 15;

const ButtonOutline = styled.button`
  color: ${brandColor};
  border-color: ${brandColor};

  &:hover {
    border-color: ${brandColor};
  }
`;

const Cookie = styled.div`
  display: block;
  align-items: flex-start;
  position: fixed;
  z-index: 9999;
  bottom: ${rem(offset_16)};
  left: ${rem(offset_16)};
  width: auto;
  max-width: 100%;
  padding: ${rem(offset_15)};
  border: 1px solid ${brandColor};
  border-radius: ${borderRadius};
  background: ${white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  right: ${rem(offset_16)};

  img {
    display: none;
  }

  ${media.desktop} {
    display: flex;
    width: 100%;
    max-width: 550px;
    left: ${rem(offset_16)};
    right: auto;
    img {
      display: block;
    }
  }

  ${ButtonStyled.Button} {
    align-self: center;
    margin-left: 0;
    margin-top: ${rem(offset_16)};

    ${media.desktop} {
      margin-top: auto;
    }
  }
`;

export default {
  ButtonOutline,
  Cookie,
};
