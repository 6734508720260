export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Outlined = 'outlined',
  Text = 'text',
}

export enum ButtonSize {
  Small,
  Normal,
  Large,
}
