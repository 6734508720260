import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import rocket from 'assets/images/icons/rocket.svg';
import { CardTypes } from 'components/common/Card/types';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize, TextWeight } from 'components/common/Text/Text.types';
import StyledInner from 'components/home/HomeAboutPanel/HomeAboutPanel.styled';
import { ABOUTUS_ROUTE } from 'routeList';
import Styled from 'style/styles';

export const HomeAboutPanel = () => {
  const [t] = useTranslation();

  return (
    <StyledInner.Card
      type={CardTypes.Main}
      className="flex flex-column flex-middle flex-center height-1-1 padding-small"
    >
      <img src={rocket} alt="rocket" />
      <Text
        color={TextColor.Primary}
        size={TextSize.HeadingS}
        className="margin-small-top margin-small-bottom"
        tag="h3"
      >
        {t('home.mission.title', 'Our Mission')}
      </Text>
      <Text weight={TextWeight.Bold} className="margin-small-bottom" tag="div">
        &ldquo;{t('home.mission.travel-love', 'We love to travel as much as you do.')}&ldquo;
      </Text>
      <Text size={TextSize.Small} tag="div">
        <div>
          {t(
            'about-us.under-title-1',
            'But, spending so much on hotel stays, means we have less money ' +
              'to spend on events, restaurants, attractions or places to see.',
          )}
        </div>
        <div className="margin-small-bottom margin-top">
          {t(
            'about-us.under-title-2',
            'That’s why Splitty was founded, to make hotel stays more affordable - so you, ' +
              'and we, can save our money for more of those travel experiences.',
          )}
        </div>

        <Link to={ABOUTUS_ROUTE}>
          <Styled.BaseTextColor>
            <Styled.TextUnderline>Read more</Styled.TextUnderline>
          </Styled.BaseTextColor>
        </Link>
      </Text>
    </StyledInner.Card>
  );
};
