import React, { PropsWithChildren } from 'react';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';

interface NavButtonProps extends PropsWithChildren<Record<string, unknown>> {
  onClick: () => void;
  disabled?: boolean;
}

const NavButton = ({ children, onClick, disabled }: NavButtonProps) => (
  <CustomDatepickerStyle.NavButton type="button" onClick={onClick} disabled={disabled}>
    {children}
  </CustomDatepickerStyle.NavButton>
);

export default NavButton;
