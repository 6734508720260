import qs from 'qs';
import React, { lazy, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DebugContext } from 'TopContexts';
import { ERRORS_ROUTE_NOT_FOUND, RouteData, routes } from 'routeList';
import HomeView from 'views/home/HomeView';
import { RedirectLinkType } from 'views/redirect/Redirect';

// Debug page
const DebugI18N = lazy(() => import(/* webpackChunkName: "DebugI18N" */ 'views/debug/DebugI18N'));

// Important pages where prefetching is turned on.
// NOTE: Order matters here as prefetching goes in the same order:
const HotelsDetailsView = lazy(
  () =>
    import(
      /* webpackPrefetch:true */ /* webpackChunkName: "HotelsDetailsView" */ 'views/hotelDetails/HotelsDetailsView'
    ),
);
const CheckoutPage = lazy(
  () =>
    import(/* webpackPrefetch:true */ /* webpackChunkName: "CheckoutPage" */ 'components/pages/checkout/CheckoutPage'),
);
const ConfirmationView = lazy(
  () =>
    import(/* webpackPrefetch:true */ /* webpackChunkName: "ConfirmationView" */ 'views/confirmation/ConfirmationView'),
);
const HotelsView = lazy(
  () => import(/* webpackPrefetch:true */ /* webpackChunkName: "HotelsView" */ 'views/hotels/HotelsView'),
);

// Other pages
// NOTE: Do NOT include webpackPrefetch:true when lazy-import other pages!!!
// It is agreed to prefetch exactly these pages above (as they are used oftenly)
const ErrorWithIdView = lazy(() => import(/* webpackChunkName: "ErrorWithIdView" */ 'views/errors/ErrorWithIdView'));
const ErrorNotFoundView = lazy(
  () => import(/* webpackChunkName: "ErrorNotFoundView" */ 'views/errors/ErrorNotFoundView'),
);
const StaticFAQPage = lazy(() => import(/* webpackChunkName: "StaticFAQPage" */ 'views/static/FAQ/StaticFAQPage'));
const StaticTermsPage = lazy(
  () => import(/* webpackChunkName: "StaticTermsPage" */ 'views/static/StaticTermsPage/StaticTermsPage'),
);
const StaticPolicyPage = lazy(
  () => import(/* webpackChunkName: "StaticPolicyPage" */ 'views/static/StaticPolicyPage/StaticPolicyPage'),
);
const StaticAboutUsPage = lazy(
  () => import(/* webpackChunkName: "StaticAboutUsPage" */ 'views/static/AboutUs/StaticAboutUsPage'),
);
const StaticPriceGuaranteePage = lazy(
  () =>
    import(
      /* webpackChunkName: "StaticPriceGuaranteePage" */ 'views/static/PriceGuaranteePage/StaticPriceGuaranteePage'
    ),
);
const BookingPage = lazy(() => import(/* webpackChunkName: "BookingPage" */ 'views/myBookings/BookingPage'));
const UserProfilePage = lazy(
  () => import(/* webpackChunkName: "UserProfilePage" */ 'views/userProfile/UserProfilePage'),
);
const PageRedirect = lazy(() => import(/* webpackChunkName: "Redirect" */ 'views/redirect/Redirect'));
const AdditionalPaymentPage = lazy(
  () => import(/* webpackChunkName: "AdditionalPaymentPage" */ 'components/pages/checkout/AdditionalPaymentPage'),
);
const AdditionalPaymentConfirmationView = lazy(
  () =>
    import(
      /* webpackChunkName: "AdditionalPaymentConfirmationView" */ 'views/confirmation/AdditionalPaymentConfirmationView/AdditionalPaymentConfirmationView'
    ),
);

interface DebugModeTurnOnAndOff {
  super?: string;
}

function isDebugModeInUrl(location: string): boolean | undefined {
  const on = (qs.parse(location, { ignoreQueryPrefix: true }) as DebugModeTurnOnAndOff).super;

  if (on !== undefined) {
    return on === 'true';
  }

  return undefined;
}

/**
 * Extension of the standard Route, which looks for "super" paraemeter
 * and sets/unsets debug mode if it is true or false
 *
 */
const PageRoute: React.FC<RouteData> = ({ path, exact, children }) => {
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

/**
 * Extension of the standard Route, which looks for "super" paraemeter
 * and sets/unsets debug mode if it is true or false
 *
 */
const DebuggableRoute: React.FC<RouteData> = (props) => {
  const location = useLocation();
  const { isDebugMode, setDebugMode } = useContext(DebugContext);

  useEffect(() => {
    const newMode = isDebugModeInUrl(location.search);

    if (newMode !== undefined) {
      setDebugMode(newMode);
    }
  }, [location, isDebugMode, setDebugMode]);

  return <PageRoute {...props} />;
};

/**
 * All the application routes
 */
const AppRoutes = (): React.ReactElement => (
  <Switch>
    <DebuggableRoute {...routes.home}>
      <HomeView />
    </DebuggableRoute>
    <DebuggableRoute {...routes.destination}>
      <HotelsView />
    </DebuggableRoute>

    <PageRoute {...routes.booking}>
      <BookingPage />
    </PageRoute>

    <PageRoute {...routes.faq}>
      <StaticFAQPage />
    </PageRoute>
    <PageRoute {...routes.aboutUs}>
      <StaticAboutUsPage />
    </PageRoute>
    <PageRoute {...routes.terms}>
      <StaticTermsPage />
    </PageRoute>
    <PageRoute {...routes.privacyPolicy}>
      <StaticPolicyPage />
    </PageRoute>
    <PageRoute {...routes.priceGuarantee}>
      <StaticPriceGuaranteePage />
    </PageRoute>

    <PageRoute {...routes.hotelsRedirect}>
      <PageRedirect type={RedirectLinkType.HOTEL} />
    </PageRoute>

    <PageRoute {...routes.destinationsRedirect}>
      <PageRedirect type={RedirectLinkType.DESTINATION} />
    </PageRoute>

    <DebuggableRoute {...routes.hotels}>
      <HotelsDetailsView />
    </DebuggableRoute>

    <PageRoute {...routes.checkout}>
      <CheckoutPage />
    </PageRoute>

    <PageRoute {...routes.confirmation}>
      <ConfirmationView />
    </PageRoute>
    <PageRoute {...routes.additionalPayment}>
      <AdditionalPaymentPage />
    </PageRoute>
    <PageRoute {...routes.additionalPaymentConfirmation}>
      <AdditionalPaymentConfirmationView />
    </PageRoute>

    <PageRoute {...routes.userProfile}>
      <UserProfilePage />
    </PageRoute>

    <PageRoute {...routes.resetPassword}>
      <HomeView resetPassword={true} />
    </PageRoute>

    <PageRoute {...routes.errorWithId}>
      <ErrorWithIdView />
    </PageRoute>
    <PageRoute {...routes.errorNotFound}>
      <ErrorNotFoundView />
    </PageRoute>
    <PageRoute {...routes.i18debug}>
      <DebugI18N />
    </PageRoute>

    <Route>
      <Redirect to={ERRORS_ROUTE_NOT_FOUND} />
    </Route>
  </Switch>
);

export default AppRoutes;
