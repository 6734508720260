import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as BestPrice2SVG } from 'assets/images/icons/best-price-2.svg';
import { CardTypes } from 'components/common/Card/types';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextSize } from 'components/common/Text/Text.types';
import Styled from 'components/home/HomePriceGuaranteePanel/HomePriceGuaranteePanel.styled';
import StyledCheckout from 'components/pages/checkout/Checkout.styled';
import { PRICE_GUARANTEE_ROUTE } from 'routeList';

export const HomePriceGuaranteePanel = () => {
  return (
    <Styled.Card type={CardTypes.Main} hover className="flex flex-center padding-small">
      <StyledCheckout.IconWrapper className="margin-medium-right width-1-3 margin-auto-vertical">
        <BestPrice2SVG className="flex-none" />
      </StyledCheckout.IconWrapper>
      <Text alignment={TextAlignment.Left} className="width-2-3 margin-auto-vertical" tag="div">
        <Text color={TextColor.Primary} size={TextSize.HeadingS} className="margin-small-bottom" tag="h3">
          <Trans i18nKey={'home.guarantee.title'}>Best Price Guarantee</Trans>
        </Text>
        <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
          <Trans i18nKey={'price-guarantee.found-lower-price'}>Found a lower price on your deal?</Trans>{' '}
        </Text>
        <Text size={TextSize.Small} color={TextColor.Muted} tag="div">
          <Trans i18nKey={'price-guarantee.let-us-know'}>Let us know, get a $50 coupon AND a refund</Trans>
        </Text>
        <Link to={PRICE_GUARANTEE_ROUTE}>
          <Text size={TextSize.Small} color={TextColor.Emphasis} className="margin-small-top" tag="div">
            <Trans i18nKey={'price-guarantee.how'}>How does this work?</Trans>
          </Text>
        </Link>
      </Text>
    </Styled.Card>
  );
};
