import styled from 'styled-components';
import phoneFlagsImg from 'assets/images/flags/phone-flags.png';
import phoneFlags2Img from 'assets/images/flags/phone-flags2.png';
import StyledSelectInput from 'components/common/SelectInput/SelectInput.styled';

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TextInputWrapper = styled.div<{ areaWidth: number }>`
  position: relative;
  width: 100%;

  & > span {
    position: absolute;
    left: 20px;
    top: 27.5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: black;
  }

  input {
    padding-left: calc(${(props) => props.areaWidth}px + 28px);
    border-radius: 0 5px 5px 0;
  }

  label {
    transform: translateY(8px);
  }
`;

const staticSelectBackground = `url('/images/drop-down.svg') no-repeat, radial-gradient(transparent 14px, #d9d9d9 14px, #d9d9d9 15px, #fff 15px) no-repeat, linear-gradient(to right, transparent 54px, #fff 54px)`;
const staticSelectBackgroundPosition = 'calc(100% - 15px), top left, top left';
const backgroundPositionOffset = '13px';

const SelectInputWrapper = styled.div`
  & > ${StyledSelectInput.SelectField} {
    width: auto;
    position: initial;
  }

  ${StyledSelectInput.Select} {
    min-width: 100px;
    width: 100px;

    color: transparent;
    border-radius: 3px 0 0 3px;

    option {
      color: black;
    }

    background: ${staticSelectBackground}, url(${phoneFlagsImg});

    &.an {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 5px);
    }

    &.in {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 44px);
    }

    &.us {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 493px);
    }

    &.de {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 4px);
    }

    &.fr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 4px);
    }

    &.es {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 308px);
    }

    &.it {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 226px);
    }

    &.ru {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 374px) calc(${backgroundPositionOffset} - 456px);
    }

    &.gb {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 78px);
    }

    &.af {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 160px) calc(${backgroundPositionOffset} - 117px);
    }

    &.al {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 160px);
    }

    &.dz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 189px);
    }

    &.ad {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 160px) calc(${backgroundPositionOffset} - 43px);
    }

    &.ao {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 199px) calc(${backgroundPositionOffset} - 4px);
    }

    &.ai {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 160px);
    }

    &.ag {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 160px);
    }

    &.ar {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 199px) calc(${backgroundPositionOffset} - 41px);
    }

    &.am {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 160px);
    }

    &.aw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 199px) calc(${backgroundPositionOffset} - 152px);
    }

    &.au {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 199px) calc(${backgroundPositionOffset} - 115px);
    }

    &.at {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 199px) calc(${backgroundPositionOffset} - 78px);
    }

    &.az {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 197px);
    }

    &.bs {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 234px);
    }

    &.bh {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 41px);
    }

    &.bd {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 197px);
    }

    &.bb {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 197px);
    }

    &.by {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 234px);
    }

    &.be {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 197px);
    }

    &.bz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 234px);
    }

    &.bj {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 115px);
    }

    &.bm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 152px);
    }

    &.bt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 234px);
    }

    &.bo {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 234px);
    }

    &.ba {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 197px);
    }

    &.bw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 234px);
    }

    &.br {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 234px);
    }

    &.vg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 485px) calc(${backgroundPositionOffset} - 493px);
    }

    &.bn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 189px);
    }

    &.bg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 263px);
    }

    &.bf {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 4px);
    }

    &.bi {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 236px) calc(${backgroundPositionOffset} - 78px);
    }

    &.kh {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 382px);
    }

    &.cm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 271px);
    }

    &.ca {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 4px);
    }

    &.cv {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 271px);
    }

    &.bq {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 4px);
    }

    &.ky {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 374px);
    }

    &.cf {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 78px);
    }

    &.td {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 493px);
    }

    &.cl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 226px);
    }

    &.cn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 271px);
    }

    &.cx {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 5px);
    }

    &.co {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 271px);
    }

    &.km {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 382px);
    }

    &.cd {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 41px);
    }

    &.cg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 115px);
    }

    &.cr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 271px);
    }

    &.ci {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 189px);
    }

    &.hr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 345px);
    }

    &.cu {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 271px);
    }

    &.cy {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 271px);
    }

    &.cz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 271px);
    }

    &.dk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 78px);
    }

    &.dz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 189px);
    }

    &.dm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 115px);
    }

    &.do {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 152px);
    }

    &.ec {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 226px);
    }

    &.eg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 308px);
    }

    &.sv {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 411px);
    }

    &.gq {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 300px);
    }

    &.er {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 308px);
    }

    &.ee {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 263px);
    }

    &.et {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 308px);
    }

    &.fk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 308px);
    }

    &.fj {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 308px);
    }

    &.fi {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 308px);
    }

    &.pf {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 456px);
    }

    &.ga {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 41px);
    }

    &.gm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 226px);
    }

    &.ge {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 526px);
    }

    &.gh {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 152px);
    }

    &.gi {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 346px) calc(${backgroundPositionOffset} - 189px);
    }

    &.gr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 344px);
    }

    &.gl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 5px);
    }

    &.gd {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 115px);
    }

    &.gt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 345px);
    }

    &.gg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 44px);
    }

    &.gn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 347px) calc(${backgroundPositionOffset} - 263px);
    }

    &.gw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 345px);
    }

    &.gy {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 345px);
    }

    &.ht {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 345px);
    }

    &.hn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 345px);
    }

    &.hk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 345px);
    }

    &.hu {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 345px);
    }

    &.is {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 189px);
    }

    &.id {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 337px) calc(${backgroundPositionOffset} - 345px);
    }

    &.ir {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 152px);
    }

    &.iq {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 115px);
    }

    &.ie {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 4px);
    }

    &.im {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 78px);
    }

    &.il {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 41px);
    }

    &.jm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 300px);
    }

    &.jp {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 382px);
    }

    &.je {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 263px);
    }

    &.jo {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 384px) calc(${backgroundPositionOffset} - 337px);
    }

    &.kz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 382px);
    }

    &.ke {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 382px);
    }

    &.kw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 382px);
    }

    &.kg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 382px);
    }

    &.la {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 337px) calc(${backgroundPositionOffset} - 382px);
    }

    &.lv {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 263px);
    }

    &.lb {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 374px) calc(${backgroundPositionOffset} - 382px);
    }

    &.ls {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 152px);
    }

    &.lr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 115px);
    }

    &.ly {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 300px);
    }

    &.li {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 41px);
    }

    &.lt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 189px);
    }

    &.lu {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 226px);
    }

    &.mo {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 153px) calc(${backgroundPositionOffset} - 526px);
    }

    &.my {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 41px);
    }

    &.mv {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 374px) calc(${backgroundPositionOffset} - 419px);
    }

    &.ml {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 528px) calc(${backgroundPositionOffset} - 409px);
    }

    &.mr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mu {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 337px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mx {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 4px);
    }

    &.fm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 308px);
    }

    &.md {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 419px);
    }

    &.mc {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 374px);
    }

    &.mn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 419px);
    }

    &.me {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 419px);
    }

    &.ms {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 419px);
    }

    &.ma {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 337px);
    }

    &.mz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 78px);
    }

    &.mm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 419px);
    }

    &.na {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 115px);
    }

    &.np {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 337px);
    }

    &.nl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 263px);
    }

    &.nz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 374px);
    }

    &.ni {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 226px);
    }

    &.ne {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 152px);
    }

    &.ng {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 189px);
    }

    &.kp {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 382px);
    }

    &.no {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 300px);
    }

    &.om {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 458px) calc(${backgroundPositionOffset} - 411px);
    }

    &.pk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 82px);
    }

    &.pw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 456px);
    }

    &.pa {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 456px);
    }

    &.pg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 456px);
    }

    &.py {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 300px) calc(${backgroundPositionOffset} - 456px);
    }

    &.pe {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 456px);
    }

    &.ph {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 528px) calc(${backgroundPositionOffset} - 446px);
    }

    &.pl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 456px);
    }

    &.pt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 456px);
    }

    &.pr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 456px);
    }

    &.qa {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 531px) calc(${backgroundPositionOffset} - 300px);
    }

    &.ro {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 337px) calc(${backgroundPositionOffset} - 456px);
    }

    &.rw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 411px) calc(${backgroundPositionOffset} - 456px);
    }

    &.sh {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 115px);
    }

    &.kn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 382px);
    }

    &.lc {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 4px);
    }

    &.vc {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 411px) calc(${backgroundPositionOffset} - 493px);
    }

    &.sm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 83px);
    }

    &.st {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 374px);
    }

    &.sa {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 481px);
    }

    &.sn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 263px);
    }

    &.rs {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 41px) calc(${backgroundPositionOffset} - 530px);
    }

    &.sc {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 4px);
    }

    &.sl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 226px);
    }

    &.sg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 530px);
    }

    &.sx {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 82px) calc(${backgroundPositionOffset} - 82px);
    }

    &.sk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 189px);
    }

    &.si {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 152px);
    }

    &.sb {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 448px) calc(${backgroundPositionOffset} - 456px);
    }

    &.so {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 300px);
    }

    &.za {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 189px);
    }

    &.kr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 527px);
    }

    &.sb {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 448px) calc(${backgroundPositionOffset} - 456px);
    }

    &.lk {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 421px) calc(${backgroundPositionOffset} - 78px);
    }

    &.sd {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 41px);
    }

    &.sr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 337px);
    }

    &.sz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 448px);
    }

    &.se {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 495px) calc(${backgroundPositionOffset} - 78px);
    }

    &.ch {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 273px) calc(${backgroundPositionOffset} - 152px);
    }

    &.sy {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 5px);
    }

    &.tw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 189px) calc(${backgroundPositionOffset} - 493px);
    }

    &.tj {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 115px) calc(${backgroundPositionOffset} - 493px);
    }

    &.tz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 5px);
    }

    &.th {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 44px);
    }

    &.tl {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 83px);
    }

    &.tg {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 78px) calc(${backgroundPositionOffset} - 493px);
    }

    &.to {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 122px);
    }

    &.tt {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 122px);
    }

    &.tn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 122px);
    }

    &.tr {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 122px);
    }

    &.tm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 152px) calc(${backgroundPositionOffset} - 493px);
    }

    &.tc {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 493px);
    }

    &.ug {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 263px) calc(${backgroundPositionOffset} - 493px);
    }

    &.ua {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 226px) calc(${backgroundPositionOffset} - 493px);
    }

    &.ae {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 160px) calc(${backgroundPositionOffset} - 80px);
    }

    &.uy {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 337px) calc(${backgroundPositionOffset} - 493px);
    }

    &.uz {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 374px) calc(${backgroundPositionOffset} - 493px);
    }

    &.vu {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 41px);
    }

    &.ve {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 448px) calc(${backgroundPositionOffset} - 493px);
    }

    &.vn {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 4px);
    }

    &.wf {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 78px);
    }

    &.eh {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 43px);
    }

    &.ye {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 152px);
    }

    &.zm {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 226px);
    }

    &.zw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 532px) calc(${backgroundPositionOffset} - 337px);
    }

    &.ax {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 4px) calc(${backgroundPositionOffset} - 197px);
    }

    &.mw {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 411px) calc(${backgroundPositionOffset} - 419px);
    }

    &.dj {
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 310px) calc(${backgroundPositionOffset} - 41px);
    }

    &.as {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 122px);
    }

    &.aq {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 83px);
    }

    &.io {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 83px);
    }

    &.cc {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 161px);
    }

    &.ck {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 44px);
    }

    &.cw {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 44px);
    }

    &.fo {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 200px);
    }

    &.gf {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 44px);
    }

    &.gp {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 83px);
    }

    &.gu {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 44px);
    }

    &.ki {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 83px);
    }

    &.xk {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 5px);
    }

    &.mh {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 200px);
    }

    &.mq {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 44px);
    }

    &.nr {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 44px);
    }

    &.nu {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 83px);
    }

    &.nf {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 5px);
    }

    &.mp {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 83px);
    }

    &.ps {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 200px);
    }

    &.bl {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 5px);
    }

    &.mf {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 161px);
    }

    &.pm {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 122px) calc(${backgroundPositionOffset} - 161px);
    }

    &.ss {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 200px);
    }

    &.sj {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 200px);
    }

    &.tk {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 161px);
    }

    &.tv {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 5px) calc(${backgroundPositionOffset} - 122px);
    }

    &.va {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 45px) calc(${backgroundPositionOffset} - 122px);
    }

    &.gs {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 122px);
    }

    &.vi {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 122px);
    }

    &.pn {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 161px) calc(${backgroundPositionOffset} - 122px);
    }

    &.ws {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 122px);
    }

    &.yt {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 83px) calc(${backgroundPositionOffset} - 161px);
    }

    &.nc {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 200px) calc(${backgroundPositionOffset} - 200px);
    }

    &.re {
      background: ${staticSelectBackground}, url(${phoneFlags2Img});
      background-position:
        ${staticSelectBackgroundPosition},
        calc(${backgroundPositionOffset} - 44px) calc(${backgroundPositionOffset} - 161px);
    }

    background-size:
      17px 17px,
      55px 55px,
      auto,
      auto !important;
  }
`;

export default {
  Container,
  TextInputWrapper,
  SelectInputWrapper,
};
