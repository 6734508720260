/**
 * @category Views
 * @packageDocumentation
 */
import React, { useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import { useSession } from 'atoms/hooks/useSession';
import { Dropdown } from 'components/common/Dropdown/Dropdown';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import CallCenterBanner from 'components/header-footer/CallCenterBanner';
import { CurrencySelector } from 'components/header-footer/CurrencySelector/CurrencySelector';
import { LanguageSelector } from 'components/header-footer/LanguageSelector/LanguageSelector';
import { PageHeaderLogo } from 'components/header-footer/PageHeaderLogo';
import Styled from 'components/layouts/desktop/DesktopPageHeader.styled';
import AccountLink from 'components/mobile/mobileLinks/AccountLink';
import BookingLink from 'components/mobile/mobileLinks/BookingLink';
import SignInLink from 'components/mobile/mobileLinks/SignInLink';
import SignOutLink from 'components/mobile/mobileLinks/SignOutLink';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import useSignOutCallback from 'components/useSignOutCallback';

/**
 * Global header for all the pages
 */
export const DesktopPageHeader = () => {
  const { hideCurrencySelector } = useContext(RouteDataContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { session } = useSession();

  const myAccountRef = React.useRef(null);
  const [openMyAccountMenu, setOpenMyAccountMenu] = useState(false);

  const openDropdown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setOpenMyAccountMenu(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setOpenMyAccountMenu(false);
  }, []);

  const signOut = useSignOutCallback();

  return (
    <Styled.Navbar>
      <div>
        <Styled.NavbarContent>
          <div>
            <PageHeaderLogo />
          </div>
          <Styled.Menu id={'navbar-menu-items'}>
            <li className="flex flex-middle">
              <CallCenterBanner />
            </li>
            <Styled.MenuItem>
              <LanguageSelector />
            </Styled.MenuItem>
            {!hideCurrencySelector && (
              <Styled.MenuItem>
                <CurrencySelector />
              </Styled.MenuItem>
            )}
            {(!session.principal || !session.principal.hasUser()) && (
              <Styled.MenuItem>
                <SignInLink onClick={() => setSignInDialogMode(SignInDialogMode.SignIn)} />
              </Styled.MenuItem>
            )}
            {session.principal && session.principal.hasUser() && (
              <Styled.MenuItem>
                <a ref={myAccountRef} onClick={openDropdown} href="/">
                  <Trans i18nKey="common.menu.my-account">My Account</Trans>
                </a>
                <Dropdown
                  buttonRef={myAccountRef}
                  position="bottom-left"
                  isOpen={openMyAccountMenu}
                  onClose={closeDropdown}
                >
                  <Styled.DropdownList>
                    <li>
                      <AccountLink onClick={closeDropdown} />
                    </li>
                    <li>
                      <SignOutLink
                        onClick={() => {
                          signOut();
                          closeDropdown();
                        }}
                      />
                    </li>
                  </Styled.DropdownList>
                </Dropdown>
              </Styled.MenuItem>
            )}
            <Styled.MenuItem>
              <BookingLink toSignInClick={() => setSignInDialogMode(SignInDialogMode.ViewBooking)} />
            </Styled.MenuItem>
          </Styled.Menu>
        </Styled.NavbarContent>
      </div>
    </Styled.Navbar>
  );
};
