import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { OauthProvider } from 'backend/api/user/userModel';
import { SignUpOauthRequest } from 'backend/api/user/userRequest';
import { InputCheckbox } from 'components/InputCheckbox';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import { OAuthContext } from 'components/contexts/OAuthContext';
import StyledInner from 'components/signin/OauthSignupForm/OauthSignupForm.styled';
import { processError } from 'errors/errorUtils';
import useOauthSignUpErrors from 'errors/useOauthSignUpErrors';
import { PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';
import guid from 'utils/guid';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';

export function getOauthProviderName(provider?: OauthProvider) {
  switch (provider) {
    case OauthProvider.Facebook:
      return 'Facebook';
    case OauthProvider.Google:
      return 'Google';
    default:
      return '';
  }
}

export const OauthSignupForm: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [t] = useTranslation();
  const errors = useOauthSignUpErrors();

  const setToast = useContext(ToastContext);
  const { signUpOauth } = useSession();
  const { campaign, origin: campaignOrigin } = useCampaign();
  const { oauthPayload, clearOAuthPayload } = useContext(OAuthContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const [acceptPolicyError, setAcceptPolicyError] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState(false);

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeMarketing, setAgreeMarketing] = useState(false);

  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (!oauthPayload) {
      setSignInDialogMode(undefined);
    }

    return () => clearOAuthPayload();
  }, [clearOAuthPayload, oauthPayload, setSignInDialogMode]);

  const customValidation = useCallback(() => {
    let toReturn = true;

    if (!agreePrivacy) {
      setAcceptPolicyError(true);
      toReturn = false;
    }

    if (!agreeTerms) {
      setAcceptTermsError(true);
      toReturn = false;
    }

    return toReturn;
  }, [agreePrivacy, agreeTerms]);

  const clearAndClose = useCallback(() => {
    clearOAuthPayload();
    setSignInDialogMode(undefined);
  }, [clearOAuthPayload, setSignInDialogMode]);

  const register = useCallback(() => {
    if (!customValidation() || !oauthPayload) {
      return;
    }

    setWorking(true);
    const payload: SignUpOauthRequest = {
      ...oauthPayload,
      recaptcha: guid(), // TODO
      agreeTerms,
      agreePrivacy,
      agreeMarketing,
      originCampaign: campaignOrigin ? campaign?.name : undefined,
    };

    signUpOauth(payload)
      .then(() => {
        clearAndClose();
        AmplitudeLogging.pushAccountCreatedEvent(payload.provider);
        setToast(t('login-popup.signed-up', 'You have successfully signed up!'), ToastType.Success);
      })
      .catch((reason) => {
        processError(reason, errors);
        setWorking(false);
      });
  }, [
    customValidation,
    oauthPayload,
    agreeTerms,
    agreePrivacy,
    agreeMarketing,
    campaignOrigin,
    campaign,
    signUpOauth,
    clearAndClose,
    setToast,
    t,
    errors,
  ]);

  return (
    <ModalBody>
      <ModalCloseButton onClick={clearAndClose} />

      <Text
        weight={TextWeight.SemiBold}
        alignment={TextAlignment.Center}
        size={TextSize.HeadingL}
        className="margin-remove-top"
        tag="div"
      >
        {t('login-popup.signup-oauth', 'Sign-Up With {provider}', {
          provider: getOauthProviderName(oauthPayload?.provider),
        })}
      </Text>

      <div className="flex-left width-expand margin-top">
        <InputCheckbox value={agreeMarketing} setValue={setAgreeMarketing}>
          <Trans i18nKey="common.misc.subscription">
            I agree to receive promotions from Holisto Ltd and its associated brands
          </Trans>
        </InputCheckbox>

        <InputCheckbox
          containerClassName="margin-medium-top"
          value={agreePrivacy}
          setValue={(e) => {
            setAcceptPolicyError(false);
            setAgreePrivacy(e);
          }}
          error={acceptPolicyError ? t('login-popup.accept-policy-error', 'Please accept our policy') : undefined}
        >
          <Trans i18nKey="login-popup.accept-policy">
            I accept the &nbsp;
            <StyledInner.AcceptPolicyLink href={PRIVACY_ROUTE} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </StyledInner.AcceptPolicyLink>
          </Trans>
        </InputCheckbox>

        <InputCheckbox
          containerClassName="margin-medium-top"
          value={agreeTerms}
          setValue={(e) => {
            setAcceptTermsError(false);
            setAgreeTerms(e);
          }}
          error={
            acceptTermsError ? t('login-popup.accept-terms-error', 'Please accept our terms and conditions') : undefined
          }
        >
          <Trans i18nKey="login-popup.accept-terms">
            I accept the &nbsp;
            <StyledInner.AcceptTermsLink href={TERMS_ROUTE} target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </StyledInner.AcceptTermsLink>
          </Trans>
        </InputCheckbox>
      </div>

      <Button styleType={ButtonType.Primary} className="margin-large-top" onClick={register} disabled={working}>
        <Text size={TextSize.Large} weight={TextWeight.SemiBold}>
          <Trans i18nKey="login-popup.signup-button">Sign Up</Trans>
        </Text>
      </Button>

      <Text alignment={TextAlignment.Center} tag="div">
        {children}
      </Text>
    </ModalBody>
  );
};
