import styled, { css } from 'styled-components';
import media from 'style/media';
import { flexGapColumn } from 'style/polyfills/flexGap';
import { black, brandColor, primaryBackground } from 'style/variables';

const ImageSize = css`
  ${media.desktop} {
    width: 24px;
    height: 24px;
  }

  ${media.mobile} {
    width: 33px;
    height: 33px;
  }
`;

const Banner = styled.div`
  background-color: ${primaryBackground};
  border-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 4px 8px;

  ${flexGapColumn('8px')}
  img {
    ${ImageSize}
  }

  ${media.desktop} {
    height: 33px;
  }

  ${media.mobile} {
    height: 48px;
  }
`;

const TextContainer = styled.div`
  font-size: 13px;

  flex-grow: 1;
  display: flex;
  align-items: center;

  ${media.desktop} {
    ${flexGapColumn('8px')}
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Text = styled.span`
  color: ${brandColor};
  font-weight: 600;
`;

const MobileGap = styled.div`
  ${ImageSize}
`;

const Phone = styled.span`
  color: ${black};
  font-weight: 500;
`;

const MobileLink = styled.a`
  display: block;
`;

export default {
  Banner,
  TextContainer,
  Text,
  Phone,
  MobileGap,
  MobileLink,
};
