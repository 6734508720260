/* eslint-disable no-magic-numbers */
import { RateDisplayModel } from 'common/backend/api/general/generalModel';
import { CurrencyCode } from 'common/backend/api/model';

export enum AuthenticationState {
  Unauthenticated = 1,
  BasicAuth = 2,
  SocialAuth = 4,
  SmsAuth = 8,
}

export enum PriceRoundingMethod {
  Floor = 1,
  Round = 2,
  Ceiling = 3,
}

export enum Provider {
  Google = 31, // partnerId for GoSplitty_Google_* campaign
}

export interface Campaign {
  id: number;
  name: string;
  partnerId: number;
  currencyCode: CurrencyCode;
  languageCode: string;
  rateDisplayModel: RateDisplayModel;
  uiSettings: {
    priceRoundingMethod: PriceRoundingMethod;
    customLogo?: {
      largeUrl: string;
      smallUrl: string;
    };
  };
}

export interface Translation {
  id: number;
  pack: string;
  languageCode: string;
  dictionary: Record<string, unknown>;
}

export interface AbTestVariation {
  id: number;
  percentage: number;
}

export interface AbTest {
  testId: number;
  variations: AbTestVariation[];
}
