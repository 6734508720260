/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import media from 'style/media';
import { borderColorLight } from 'style/variables';

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  margin-top: auto;
  margin-bottom: auto;
`;

const DisplayValue = styled.span`
  display: flex;
  align-items: center;

  ${media.desktop} {
    height: 68px;
  }

  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;

  ${media.mobile} {
    height: 40px;
    width: 140px;
    box-sizing: border-box;
    padding: 0 20px 0 10px;

    border: 1px solid ${borderColorLight};
    border-radius: 4px;
  }
`;

const DropIcon = styled.img`
  position: absolute;
  width: 7px;
  right: 10px;
  top: 50%;

  transform: translateY(-50%) rotate(-90deg);
`;

export default { MobileContainer, Label, DisplayValue, DropIcon };
