/**
 * @category Views
 * @packageDocumentation
 */
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as BestPrice2SVG } from 'assets/images/icons/best-price-2.svg';
import { ReactComponent as RiskFreeSVG } from 'assets/images/icons/risk-free.svg';
import { ReactComponent as SecuredBadgeSVG } from 'assets/images/icons/secured-badge.svg';
import { ReactComponent as FooterLogoSVG } from 'assets/images/logo/footer-logo.svg';
import { Text } from 'components/common/Text/Text';
import { TextColor, TextSize, TextTransform, TextWeight } from 'components/common/Text/Text.types';
import Styled from 'components/header-footer/PageFooter/PageFooter.styles';
import { useContactTranslations } from 'components/hooks/useContactTranslations';
import CareersLink from 'components/mobile/mobileLinks/CareersLink';
import { ABOUTUS_ROUTE, FAQ_ROUTE, PRICE_GUARANTEE_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';

/**
 * Global footer for all the pages
 */
export const PageFooter = () => {
  const contactTranslations = useContactTranslations();

  return (
    <Styled.Footer>
      <Styled.Top className="padding-large padding-remove-horizontal">
        <div className="container">
          <div className="grid-la grid grid-row-gap">
            <div className="flex flex-middle width-1-3">
              <Styled.ImageWrapper className="margin-medium-right">
                <BestPrice2SVG className="flex-none" />
              </Styled.ImageWrapper>
              <div>
                <Styled.XSmallTitle
                  transform={TextTransform.Uppercase}
                  size={TextSize.Small}
                  color={TextColor.Primary}
                  className="margin-small-bottom"
                  tag="h2"
                >
                  <Trans i18nKey="common.price-guarantee.title">Best Price Guarantee</Trans>
                </Styled.XSmallTitle>

                <div>
                  <Trans i18nKey="common.price-guarantee.found-lower-price">Found a lower price on your deal?</Trans>
                  <span> </span>
                  <Trans i18nKey="common.price-guarantee.let-us-know">
                    Let us know, get a $50 coupon AND a refund!
                  </Trans>
                </div>
              </div>
            </div>

            <div className="flex flex-middle width-1-3">
              <Styled.ImageWrapper className="margin-medium-right">
                <SecuredBadgeSVG className="flex-none" />
              </Styled.ImageWrapper>
              <div>
                <Styled.XSmallTitle
                  transform={TextTransform.Uppercase}
                  size={TextSize.Small}
                  color={TextColor.Primary}
                  className="margin-small-bottom"
                  tag="h2"
                >
                  <Trans i18nKey="common.verified.title">Verified &amp; Secured</Trans>
                </Styled.XSmallTitle>

                <div>
                  <Trans i18nKey="common.verified.text">
                    We will never sell your personal information, Splitty uses secure transmission to protect your
                    personal information
                  </Trans>
                </div>
              </div>
            </div>

            <div className="flex flex-middle width-1-3">
              <Styled.ImageWrapper className="margin-medium-right">
                <RiskFreeSVG className="flex-none" />
              </Styled.ImageWrapper>
              <div>
                <Styled.XSmallTitle
                  transform={TextTransform.Uppercase}
                  size={TextSize.Small}
                  color={TextColor.Primary}
                  className="margin-small-bottom"
                  tag="h2"
                >
                  <Trans i18nKey="common.risk-free.title">100% Risk Free</Trans>
                </Styled.XSmallTitle>

                <div>
                  <Trans i18nKey="common.risk-free.text">
                    Splitting your reservations allows us to cover more search options and find you the best deals
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styled.Top>

      <Styled.Main>
        <div className="container">
          <div className="grid grid-medium">
            <div className="width-1-3 width-1-1">
              <div>
                <FooterLogoSVG className="margin-small-bottom" />
                <Text size={TextSize.Small} tag="div">
                  <Trans i18nKey="common.what-is-splitty.whatIsSpliity">
                    Holisto™ is the world&lsquo;s first online hotel booking platform that allows users to take
                    advantage of amazing hotel deals available only by combining offers from multiple sources to create
                    a unique room package.
                  </Trans>
                </Text>
              </div>
            </div>

            <div className="width-1-2 width-expand">
              <Text
                transform={TextTransform.Uppercase}
                color={TextColor.Primary}
                size={TextSize.HeadingS}
                className="margin-medium-bottom"
                tag="h2"
              >
                <Trans i18nKey="footer.sections.company">COMPANY</Trans>
              </Text>

              <Styled.FooterList>
                <li>
                  <Link to={ABOUTUS_ROUTE}>
                    <Trans i18nKey="footer.about">About Us</Trans>
                  </Link>
                </li>
                <li>
                  <CareersLink />
                </li>
              </Styled.FooterList>

              <Text
                transform={TextTransform.Uppercase}
                color={TextColor.Primary}
                size={TextSize.HeadingS}
                className="margin-medium-bottom"
                tag="h2"
              >
                <Trans i18nKey="footer.sections.legal">Legal</Trans>
              </Text>

              <Styled.FooterList>
                <li>
                  <Link to={TERMS_ROUTE}>
                    <Trans i18nKey="footer.terms">Terms and Conditions</Trans>
                  </Link>
                </li>
                <li>
                  <Link to={PRIVACY_ROUTE}>
                    <Trans i18nKey="footer.privacy">Privacy Policy</Trans>
                  </Link>
                </li>
              </Styled.FooterList>
            </div>

            <div className="width-1-2 width-expand">
              <Text
                transform={TextTransform.Uppercase}
                color={TextColor.Primary}
                size={TextSize.HeadingS}
                className="margin-medium-bottom"
                tag="h2"
              >
                <Trans i18nKey="footer.sections.support">Support</Trans>
              </Text>

              <Styled.FooterList xlargeBottom>
                <li>
                  <Link to={FAQ_ROUTE}>
                    <Trans i18nKey="footer.faq">FAQ</Trans>
                  </Link>
                </li>
                <li>
                  <Link to={PRICE_GUARANTEE_ROUTE}>
                    <Trans i18nKey="footer.price-guarantee">Price Guarantee</Trans>
                  </Link>
                </li>
              </Styled.FooterList>
            </div>

            <div className="width-1-2 width-expand">
              <Text
                transform={TextTransform.Uppercase}
                color={TextColor.Primary}
                size={TextSize.HeadingS}
                className="margin-medium-bottom"
                tag="h2"
              >
                <Trans i18nKey="footer.sections.connect">CONNECT WITH US</Trans>
              </Text>

              <Styled.FooterList xlargeBottom>
                <li>
                  <a href={`tel:${contactTranslations.phone}`}>{contactTranslations.phone}</a>
                </li>
                <li>
                  <a href={`mailto:${contactTranslations.partnerWithUs}`}>
                    <Trans i18nKey="footer.partner-with-us">Partner With Us</Trans>
                  </a>
                </li>
                <li>
                  <a href={`mailto:${contactTranslations.emailUs}`}>
                    <Trans i18nKey="footer.email-us">Email Us</Trans>
                  </a>
                </li>
              </Styled.FooterList>
            </div>
          </div>

          <div className="footer-bottom margin-small-top">
            <Styled.BottomPart>
              <Text size={TextSize.Large} weight={TextWeight.Normal}>
                <Trans
                  i18nKey="footer.copyright"
                  defaults="© {year} Holisto Company LTD. All Rights Reserved."
                  values={{ year: new Date().getFullYear() }}
                />
              </Text>
            </Styled.BottomPart>
          </div>
        </div>
      </Styled.Main>
    </Styled.Footer>
  );
};
