/**
 * @category Views
 * @packageDocumentation
 */
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from 'TopContexts';
import dropIcon from 'assets/images/icons/angle-left.svg';
import SelectInput, { Group, Key } from 'components/common/SelectInput/SelectInput';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/header-footer/CurrencySelector/CurrencySelector.styled';
import { CurrencyGroups, getCurrencyByCodeOrDefault, getCurrencyText, TOP_CURRENCIES } from 'utils/currencyUtils';
import useTranslatedCurrencyList from 'utils/translate/useTranslatedCurrencyList';

/**
 * Shows current currency.
 * Clicking on it opens dropdown where the currency can be changed.
 * (the component only calls it and does nothing else)
 */
export const CurrencySelector: React.FC = () => {
  const { t } = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const currencies = useTranslatedCurrencyList();
  const { currencyCode, setCurrencyCode } = useContext(SettingsContext);
  const setCurrency = useCallback(
    (currency: Key) => {
      if (!currency) {
        return;
      }
      setCurrencyCode(currency, true);
    },
    [setCurrencyCode],
  );

  const selectedOptionText = useMemo(
    () => getCurrencyText(getCurrencyByCodeOrDefault(currencyCode), ' '),
    [currencyCode],
  );

  const currencyGroups = useMemo(() => {
    const topCurrencies: Group = {
      key: CurrencyGroups.topCurrencies,
      name: t('common.currency.top-currencies', 'Top currencies'),
      items: TOP_CURRENCIES.map((topCurrencyCode) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currency = currencies.find((cur) => cur.code === topCurrencyCode)!;

        return {
          value: `${currency.code} - ${currency.name}`,
          key: currency.code,
        };
      }),
    };
    const allCurrencies: Group = {
      key: CurrencyGroups.allCurrencies,
      name: t('common.currency.all-currencies', 'All currencies'),
      items: currencies
        .sort((cur1, cur2) => cur1.code.localeCompare(cur2.code))
        .map((cur) => ({
          value: `${cur.code} - ${cur.name}`,
          key: cur.code,
        })),
    };

    return [topCurrencies, allCurrencies];
  }, [currencies, t]);

  if (isMobileLayout) {
    return (
      <div className="flex flex-between">
        <span className="margin-auto-vertical">Currency</span>

        <div className="notranslate">
          <SelectInput
            testId="currency-selector"
            id="id-currency-dropdown"
            selectedKey={currencyCode}
            displayValue={
              <Styled.DisplayValue>
                {selectedOptionText} <Styled.DropIcon src={dropIcon} />
              </Styled.DisplayValue>
            }
            overwrite
            groups={currencyGroups}
            onSelect={setCurrency}
          />
        </div>
      </div>
    );
  }

  return (
    <a
      href="/"
      className="notranslate"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <SelectInput
        testId="currency-selector"
        id="id-currency-dropdown"
        selectedKey={currencyCode}
        displayValue={<Styled.DisplayValue>{selectedOptionText}</Styled.DisplayValue>}
        overwrite
        groups={currencyGroups}
        onSelect={setCurrency}
      />
    </a>
  );
};
