import styled from 'styled-components';
import { Text } from 'components/common/Text/Text';
import { cover, coverContainer } from 'style/cover.styled';
import Styled from 'style/styles';
import { textShadow } from 'style/variables';

const LocationImage = styled.img`
  ${cover}
`;

const Card = styled(Styled.Card)<{ useCoverStyling?: boolean }>`
  ${({ useCoverStyling }) => (useCoverStyling ? coverContainer : '')}
`;

const Header = styled(Text)`
  text-shadow: 0 2px 2px ${textShadow};
`;

export default {
  LocationImage,
  Card,
  Header,
};
