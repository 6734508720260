import React from 'react';
import StyledInner from 'components/common/FormField/FormField.styled';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import Styled from 'style/FlexInput.styles';

interface FormFieldProps {
  label?: string;
  isRequired?: boolean;
  fieldId: string;
  className?: string;
  children: React.ReactNode;
}

export const FormField = ({ children, label, isRequired, fieldId, className }: FormFieldProps) => (
  <Styled.Container className={className}>
    <Styled.TopItem>
      {label && (
        <StyledInner.Label htmlFor={fieldId}>
          {label}
          {isRequired && <Text color={TextColor.Primary}> *</Text>}
        </StyledInner.Label>
      )}
    </Styled.TopItem>
    {children}
  </Styled.Container>
);
