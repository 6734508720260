import styled, { css } from 'styled-components';
import { rem } from 'style/functions';
import media from 'style/media';
import { backgroundGray, borderColorLightest, darkGray, white } from 'style/variables';

const DROPDOWN_DESKTOP_HEIGHT_PX = 500;

const Container = styled.div`
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  min-width: 200px;
  color: ${darkGray};
  background: ${white};
  border: 1px solid ${borderColorLightest};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 20px;
`;

const List = styled.ul`
  list-style-type: none;
  display: block;
  margin-bottom: 0;
  padding-left: 0;

  ${media.desktop} {
    width: 660px;
    max-width: 660px;
    max-height: ${rem(DROPDOWN_DESKTOP_HEIGHT_PX)};
    overflow-y: auto;
  }
`;

const Item = styled.li<{ selected?: boolean }>`
  display: flex;
  padding: 15px;
  align-items: start;
  cursor: pointer;

  & + & {
    border-top: 1px solid ${borderColorLightest};
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${backgroundGray};
    `}
`;

const Icon = styled.img`
  width: 16px;
  margin-right: 8px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export default { Container, List, Item, Icon, Description };
