/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import arrowLeftIcon from 'assets/images/icons/mobileSearchBack.svg';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { CustomDatepicker } from 'components/datePicker/customDatepicker/CustomDatepicker';
import MobileOffscreen from 'components/mobile/offscreen/MobileOffscreen';
import { SearchFormStyled } from 'components/searchForm/SearchForm.styled';

const OffscreenDatepickerMenu = () => {
  const { goToPreviousOffscreenMode } = useContext(OffscreenContext);

  return (
    <MobileOffscreen>
      <div className="margin-large-top margin-bottom">
        <a
          id="backLink"
          href="/"
          className="padding"
          onClick={(e) => {
            e.preventDefault();
            goToPreviousOffscreenMode();
          }}
        >
          <img src={arrowLeftIcon} alt="Close" className="" />
        </a>
      </div>
      <SearchFormStyled.MobileForm onSubmit={(e) => e.preventDefault()}>
        <SearchFormStyled.MobileDatePicker>
          <CustomDatepicker />
        </SearchFormStyled.MobileDatePicker>
      </SearchFormStyled.MobileForm>
    </MobileOffscreen>
  );
};

export default OffscreenDatepickerMenu;
