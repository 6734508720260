import styled from 'styled-components';
import { errorColor } from 'style/variables';

const ErrorLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${errorColor} !important;
`;

export default {
  ErrorLabel,
};
