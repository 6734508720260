import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks';
import useDateCallback from 'common/hooks/useDateCallback';
import { monthShortFormat, weekdayShortFormat, yearNumericFormat } from 'common/utils/dateFormats';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';
import { DatepickerContext } from 'components/datePicker/customDatepicker/DatepickerContext';
import Day from 'components/datePicker/customDatepicker/Day';
import useCurrentLanguage from 'components/header-footer/useCurrentLanguage';
import { SanitizedHTML } from 'components/widgets/SanitizedHTML';

interface MonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
}

export const Month: React.FC<MonthProps> = ({ year, month, firstDayOfWeek, setStartMonthRef }) => {
  const { isStartMonthSelected } = useContext(DatepickerContext);
  const lang = useCurrentLanguage();
  const weekdayCallback = useDateCallback({
    lang: lang.key,
    options: weekdayShortFormat,
  });
  const monthCallback = useDateCallback({ lang: lang.key, options: monthShortFormat });
  const yearCallback = useDateCallback({ lang: lang.key, options: yearNumericFormat });
  const formatMonth = useCallback(
    (date: Date) => {
      const m = monthCallback(date);
      const y = yearCallback(date);

      return `${m} <span>${y}</span>`;
    },
    [monthCallback, yearCallback],
  );
  const ref = useRef<HTMLDivElement>(null);

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    monthLabelFormat: formatMonth,
    weekdayLabelFormat: weekdayCallback,
  });

  useEffect(() => {
    if (isStartMonthSelected(year, month) && ref.current) {
      setStartMonthRef(ref.current);

      return () => setStartMonthRef(undefined);
    }

    return undefined;
  }, [isStartMonthSelected, month, setStartMonthRef, year]);

  return (
    <div ref={ref}>
      <CustomDatepickerStyle.MonthLabel>
        <SanitizedHTML tag="div" rawHTML={monthLabel} />
      </CustomDatepickerStyle.MonthLabel>
      <CustomDatepickerStyle.Weekdays>
        {weekdayLabels.map((dayLabel) => (
          <div key={dayLabel}>{dayLabel}</div>
        ))}
      </CustomDatepickerStyle.Weekdays>
      <CustomDatepickerStyle.DayLabel>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                month={month}
                date={day.date}
                key={`datepickerDayObj-${day.date.toString()}`}
                dayLabel={day.dayLabel}
              />
            );
          }

          // we have nothing better than an array index for the empty placeholders
          // eslint-disable-next-line react/no-array-index-key
          return <div key={`datepickerDay-${month}-${index}`} />;
        })}
      </CustomDatepickerStyle.DayLabel>
    </div>
  );
};
