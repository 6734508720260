import styled from 'styled-components';

const OffscreenBar = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const MobileOffscreenStyled = {
  OffscreenBar,
};
