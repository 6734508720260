import React, { useContext } from 'react';
import AppRoutes from 'AppRoutes';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { PageFooter } from 'components/header-footer/PageFooter/PageFooter';
import { CookiePanel } from 'components/layouts/CookiePanel/CookiePanel';
import { DesktopPageHeader } from 'components/layouts/desktop/DesktopPageHeader';
import { DesktopPageLayoutStyled } from 'components/layouts/desktop/DesktopPageLayout.styled';
import { SearchForm } from 'components/searchForm/SearchForm';
import { SignInDialog } from 'components/signin/SignInDialog/SignInDialog';
import { CovidBanner } from 'components/widgets/CovidBanner';

export const DesktopPageLayout = () => {
  const { needSearchBar, staticSearchBar } = useContext(RouteDataContext);

  return (
    <DesktopPageLayoutStyled.Root>
      <CookiePanel />
      <CovidBanner />
      <DesktopPageHeader />

      <SignInDialog />

      {needSearchBar && (
        <SearchForm
          onAfterSearch={() => {
            if (document) {
              const el = document.getElementById('searchSubmitButton');

              if (el) {
                el.focus();
              }
            }
          }}
          sticky={!staticSearchBar}
        />
      )}

      <DesktopPageLayoutStyled.Main>
        <AppRoutes />
      </DesktopPageLayoutStyled.Main>
      <PageFooter />
    </DesktopPageLayoutStyled.Root>
  );
};
