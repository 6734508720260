/**
 * @category Views
 * @packageDocumentation
 */
import React, { useCallback, useContext, useMemo } from 'react';
import { SettingsContext } from 'TopContexts';
import dropIcon from 'assets/images/icons/angle-left.svg';
import SelectInput, { Item, Key } from 'components/common/SelectInput/SelectInput';
import { LayoutContext } from 'components/contexts/LayoutContext';
import Styled from 'components/header-footer/LanguageSelector/LanguageSelector.styled';
import useCurrentLanguage from 'components/header-footer/useCurrentLanguage';
import { SUPPORTED_LANGUAGES } from 'i18n';
import useTranslatedLanguageList from 'utils/translate/useTranslatedLanguageList';

/**
 * Shows current language.
 * Clicking on it opens dropdowns where the language can be changed
 * (the component only calls it and does nothing else)
 */
export const LanguageSelector: React.FC = () => {
  const { setLanguageCode, languageCode } = useContext(SettingsContext);
  const { isMobileLayout } = useContext(LayoutContext);

  const languageList = useTranslatedLanguageList();
  const options = useMemo<Item[]>(() => languageList.map((el) => ({ value: el.name, key: el.code })), [languageList]);
  const changeLanguage = useCallback((langCode: string) => setLanguageCode(langCode, true), [setLanguageCode]);

  const lang = useCurrentLanguage();

  const onSelect = useCallback(
    (value: Key) => {
      const foundLanguage = SUPPORTED_LANGUAGES.find((el) => el.code === value);

      if (foundLanguage) {
        changeLanguage(foundLanguage.key);
      }
    },
    [changeLanguage],
  );

  if (isMobileLayout) {
    return (
      <Styled.MobileContainer>
        <Styled.Label>Language</Styled.Label>
        <div>
          <SelectInput
            testId="language-selector"
            id={'langSelectorDropdown'}
            selectedKey={languageCode}
            displayValue={
              <Styled.DisplayValue>
                {lang.name} <Styled.DropIcon src={dropIcon} />
              </Styled.DisplayValue>
            }
            overwrite
            options={options}
            onSelect={onSelect}
          />
        </div>
      </Styled.MobileContainer>
    );
  }

  return (
    <a
      href="/"
      className="notranslate"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <SelectInput
        testId="language-selector"
        id="id-language-selector"
        selectedKey={languageCode}
        displayValue={<Styled.DisplayValue>{lang.name}</Styled.DisplayValue>}
        overwrite
        options={options}
        onSelect={onSelect}
      />
    </a>
  );
};
