import styled, { css } from 'styled-components';
import { FieldState } from 'components/common/InputField/InputField.types';
import {
  white,
  black,
  borderColorLight,
  brandColor,
  textColorMuted,
  disabledBackgroundGray,
  modalBackgroundColor,
  gray,
  textColorDanger,
} from 'style/variables';

const Input = styled.input<{ state: FieldState; labeled?: boolean }>`
  box-sizing: border-box;
  margin: 0;
  border-radius: 4px;
  font: inherit;
  overflow: visible;
  max-width: 100%;
  width: 100%;
  padding: 18px 20px ${({ labeled }) => (labeled ? '0' : '18px')};
  background: ${white};
  color: ${black};
  border: 1px solid ${borderColorLight};
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 55px;
  vertical-align: middle;
  display: inline-block;

  -webkit-appearance: none;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  &::-moz-placeholder {
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: ${gray} !important;
  }
  &::placeholder {
    color: ${gray};
  }

  &:focus {
    outline: none;
    background-color: ${white};
    color: ${black};
    border: 1px solid ${brandColor};
  }

  &:disabled {
    background-color: ${modalBackgroundColor};
    color: ${gray};
    border-color: ${disabledBackgroundGray};
  }

  ${({ state }) =>
    state === FieldState.basicError || state === FieldState.emptyError || state === FieldState.error
      ? css`
          border-color: ${textColorDanger};
          color: ${textColorDanger};

          &:focus {
            border-color: ${textColorDanger};
            color: ${textColorDanger};
          }
        `
      : ''}
`;

const InputLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-left: 20px;
  transform: translateY(19px);
  pointer-events: none;
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform-origin: top left;

  color: ${textColorMuted};
`;

const InputField = styled.div<{ empty?: boolean }>`
  width: 100%;
  position: relative;
  outline: 0;

  ${Input}:focus {
    border: 1px solid ${brandColor};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel} {
    transform: translateY(8px);
  }

  ${(props) =>
    !props.empty &&
    css`
      & > ${Input} + ${InputLabel} {
        transform: translateY(8px);
      }
    `}
`;

export default {
  Input,
  InputLabel,
  InputField,
};
