/**
 * @category Utils
 * @packageDocumentation
 */

import { RoomOccupancy, Trip } from 'common/backend/api/trip/tripModel';
import TagManager from 'react-gtm-module';
import { ServerError } from 'backend/serverError';
import { ClientError } from 'errors/clientError';
import { hashCode256 } from 'utils/hashCode';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';

interface DataLayerProps {
  action?:
    | 'search'
    | 'click'
    | 'sort'
    | 'chooseHotel'
    | 'view'
    | 'close'
    | 'signUp'
    | 'signIn'
    | 'selectRoom'
    | 'purchase';
  component?:
    | 'searchBar'
    | 'footer'
    | 'header'
    | 'faq'
    | 'resultsList'
    | 'roomDetails'
    | 'hotelItem'
    | 'hotelHeader'
    | 'signIn'
    | 'signUp'
    | 'reviewsArea'
    | 'confirmation';
}

function pushDataLayerInteraction(eventName: string) {
  return <T extends DataLayerProps>(payload: T) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        pageType: LogInfoMemoryStorage.pageType,
        currency: LogInfoMemoryStorage.currencyCode,
        language: LogInfoMemoryStorage.languageCode,
        campaignId: LogInfoMemoryStorage.campaignName,
        campaignName: LogInfoMemoryStorage.campaignName,
        userTimezone: LogInfoMemoryStorage.clientLocation?.timezone,
        userId: LogInfoMemoryStorage.userId,
        ...payload,
      },
    });
  };
}

const pushHotelDealInteraction = (eventName: string) => {
  const pushInteraction = pushDataLayerInteraction(eventName);

  return (
    checkin: string | undefined,
    checkout: string | undefined,
    occupancy: RoomOccupancy[],
    props: DataLayerProps,
  ) => {
    pushInteraction({
      checkin,
      checkout,
      adults: occupancy.reduce((adultsNumber, room) => adultsNumber + room.adults, 0),
      children: occupancy.reduce(
        (childrenNumber, room) => childrenNumber + (room.children ? room.children.length : 0),
        0,
      ),
      country: LogInfoMemoryStorage.countryCode,
      ...props,
    } as DataLayerProps);
  };
};

export const DataLayerLogging = {
  pushConfirmationPageEvent: async (trip: Trip) => {
    const pushInteraction = pushDataLayerInteraction('confirmation');
    const email = await hashCode256(trip.guest?.email?.toLowerCase() ?? '');

    pushInteraction({
      email,
      confirmationRef: trip.encryptedTripId ?? '',
      currency: trip.deal?.currencyCode ?? '',
      amount: trip.deal?.totalPrice ?? '',
    } as DataLayerProps);
  },

  pushConversionTrackingEvent: (trip: Trip) => {
    const pushInteraction = pushDataLayerInteraction('purchase');

    pushInteraction({
      transaction_id: trip.id,
      currency: trip.deal.currencyCode,
      value: trip.deal.totalRoomsPrice,
      roomPrice: trip.deal.totalRoomsPrice,
      action: 'purchase',
      component: 'confirmation',
      tax: (trip.deal.totalTaxes || 0) + (trip.deal.totalFees || 0),
      items: trip.hotelRooms.map((room, index) => ({
        item_id: trip.id,
        price: trip.deal.roomPrice,
        location: trip.hotel.location,
        item_name: room.roomName,
        quantity: trip.rooms[index].adults + (trip.rooms[index].children?.length || 0),
      })),
    });
  },

  pushCheckoutEvent: (
    checkin: string,
    checkout: string,
    occupancy: RoomOccupancy[],
    props: {
      clickText: string;
      email?: string;
      phone?: string;
      firstName?: string;
      lastName?: string;
      country?: string;
      postalCode?: string;
    },
  ) => {
    const pushInteraction = pushHotelDealInteraction('checkoutInteraction');

    pushInteraction(checkin, checkout, occupancy, {
      action: 'click',
      ...props,
    });
  },

  pushErrorEvent: (unknownError: ClientError | ServerError | Error | unknown, message: string | void) => {
    const pushInteraction = pushDataLayerInteraction('error');

    let error = unknownError;

    if (error instanceof ClientError || error instanceof ServerError || error instanceof Error) {
      const messages = [];

      if (error.message) {
        messages.push(error.message);
      }
      if (error instanceof ClientError && error.getMessages()) {
        messages.push(...(error.getMessages() || []));
      }
      if (message) {
        messages.push(message);
      }
      error = {
        messages,
        ...error,
      };
    }

    pushInteraction({
      error,
    } as DataLayerProps);
  },
};
