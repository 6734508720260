/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import { List } from 'components/common/List/List';
import { Text } from 'components/common/Text/Text';
import { brown2, footerBackgroundColor, tinyText, white } from 'style/variables';

const Footer = styled.footer`
  background-color: ${footerBackgroundColor};
  color: ${white};
  & a,
  & a:hover,
  & a:visited,
  & a:active {
    color: ${white};
  }
`;

const FooterList = styled(List)<{ xlargeBottom?: boolean }>`
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 400;
  ${({ xlargeBottom }) =>
    xlargeBottom &&
    css`
      margin-bottom: 70px !important;
    `}
`;

const Top = styled.div`
  border-bottom: 1px solid ${brown2};
  font-size: ${tinyText};
`;

const XSmallTitle = styled(Text)`
  font-size: ${tinyText};
`;

const Main = styled.div`
  padding-top: ${40 / 14}rem;
  padding-bottom: ${10 / 14}rem;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 94px;
  }
`;
const BottomPart = styled.div`
  padding: 18px 0;
`;

export default {
  Footer,
  ImageWrapper,
  Top,
  Main,
  XSmallTitle,
  FooterList,
  BottomPart,
};
