import styled, { css } from 'styled-components';
import {
  TextAlignment,
  TextColor,
  TextProps,
  TextSize,
  TextTransform,
  TextWeight,
  TextWrap,
} from 'components/common/Text/Text.types';
import {
  fontSizeLarge,
  lineHeightLarge,
  lineHeightHeadingL,
  lineHeightHeadingM,
  lineHeightHeadingS,
  lineHeightHeadingXL,
  fontSizeSmall,
  textColorDanger,
  textColorEmphasis,
  textColorMuted,
  brandColor,
  textColorSecondary,
  textColorSuccess,
  fontSizeHeadingS,
  fontSizeHeadingM,
  fontSizeHeadingL,
  fontSizeHeadingXL,
  lineHeightSmall,
  fontSizeHeadingLarge,
  fontSizeHeadingXLarge,
  fontSizeH1Large,
  white,
} from 'style/variables';

const Text = styled.span<Omit<TextProps, 'children'>>`
  line-height: ${({ size }) => {
    switch (size) {
      case TextSize.HeadingS:
        return lineHeightHeadingS;
      case TextSize.HeadingM:
        return lineHeightHeadingM;
      case TextSize.HeadingL:
        return lineHeightHeadingL;
      case TextSize.HeadingXL:
        return lineHeightHeadingXL;
      case TextSize.Large:
        return lineHeightLarge;
      case TextSize.Small:
        return lineHeightSmall;
      default:
        return '';
    }
  }};

  font-size: ${({ size }) => {
    switch (size) {
      case TextSize.HeadingS:
        return fontSizeHeadingS;
      case TextSize.HeadingM:
        return fontSizeHeadingM;
      case TextSize.HeadingL:
        return fontSizeHeadingL;
      case TextSize.HeadingXL:
        return fontSizeHeadingXL;
      case TextSize.Large:
        return fontSizeLarge;
      case TextSize.Small:
        return fontSizeSmall;
      default:
        return '';
    }
  }};

  font-weight: ${({ weight }) => {
    switch (weight) {
      case TextWeight.Bold:
        return '700';
      case TextWeight.SemiBold:
        return '600';
      case TextWeight.Medium:
        return '500';
      case TextWeight.Normal:
        return '400';
      default:
        return '';
    }
  }};

  text-transform: ${({ transform }) => {
    switch (transform) {
      case TextTransform.Uppercase:
        return 'uppercase';
      case TextTransform.Lowercase:
        return 'lowercase';
      default:
        return '';
    }
  }};

  color: ${({ color }) => {
    switch (color) {
      case TextColor.Muted:
        return textColorMuted;
      case TextColor.Emphasis:
        return textColorEmphasis;
      case TextColor.Primary:
        return brandColor;
      case TextColor.Secondary:
        return textColorSecondary;
      case TextColor.Success:
        return textColorSuccess;
      case TextColor.Danger:
        return textColorDanger;
      case TextColor.White:
        return white;

      default:
        return '';
    }
  }} !important;

  text-align: ${({ alignment }) => {
    switch (alignment) {
      case TextAlignment.Left:
        return 'left';
      case TextAlignment.Right:
        return 'right';
      case TextAlignment.Center:
        return 'center';
      case TextAlignment.Justify:
        return 'justify';
      default:
        return '';
    }
  }};

  ${({ wrap }) => {
    switch (wrap) {
      case TextWrap.Truncate:
        return css`
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `;

      case TextWrap.Nowrap:
        return css`
          white-space: nowrap;
        `;

      default:
        return '';
    }
  }}

  margin-bottom: ${({ size }) => {
    switch (size) {
      case TextSize.HeadingL:
        return '20px';
      default:
        return '';
    }
  }};

  /* Laptop and bigger */
  @media (min-width: 1200px) {
    ${({ size }) => {
      switch (size) {
        case TextSize.HeadingL:
          return css`
            font-size: ${fontSizeHeadingLarge};
          `;
        case TextSize.HeadingXL:
          return css`
            font-size: ${fontSizeHeadingXLarge};
          `;
        default:
          return '';
      }
    }};

    ${({ tag }) => {
      switch (tag) {
        case 'h1':
          return css`
            @media (min-width: 960px) {
              font-size: ${fontSizeH1Large};
              line-height: 1.2;
            }
          `;
        default:
          return '';
      }
    }};
  }
`;

export default {
  Text,
};
