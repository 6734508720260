import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import barcelona from 'assets/images/cities/Barcelona.webp';
import lasvegas from 'assets/images/cities/LasVegas.webp';
import london from 'assets/images/cities/London.webp';
import miami from 'assets/images/cities/Miami.webp';
import ny from 'assets/images/cities/NewYork.webp';
import orlando from 'assets/images/cities/Orlando.webp';
import paris from 'assets/images/cities/Paris.webp';
import rome from 'assets/images/cities/Rome.webp';
import sf from 'assets/images/cities/SF.webp';
import { useSearchStateCallbacks } from 'atoms/hooks/useSearchStateCallbacks';
import { getDataProvider } from 'backend/dataProvider';
import { TextColor, TextSize } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import StyledInner from 'components/home/HomeLocationWidget/HomeLocationWidget.styled';
import Styled from 'components/home/HomeLocationWidget.styled';

interface HomeLocation {
  image: string;
  id: string;
}

interface CitiesHash {
  [city: string]: HomeLocation;
}

export const cityLocations: CitiesHash = {
  paris: {
    image: paris,
    id: 'R2734',
  },
  sf: {
    image: sf,
    id: 'R3132',
  },
  lasvegas: {
    image: lasvegas,
    id: 'R2008',
  },
  miami: {
    image: miami,
    id: 'R2297',
  },
  orlando: {
    image: orlando,
    id: 'R2693',
  },
  rome: {
    image: rome,
    id: 'R3023',
  },
  barcelona: {
    image: barcelona,
    id: 'R513',
  },
  london: {
    image: london,
    id: 'R2114',
  },
  newYork: {
    image: ny,
    id: 'R2621',
  },
};

interface HomeLocationWidgetPrp {
  locationData: HomeLocation;
  cityName: string;
  useCoverStyling?: boolean;
}

const HomeLocationWidget = ({ locationData, cityName, useCoverStyling }: HomeLocationWidgetPrp) => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { submitDestination } = useSearchStateCallbacks();
  const [t] = useTranslation();
  const setToast = useContext(ToastContext);
  const onWidgetClick = useCallback(() => {
    const fetchDestination = async () => {
      try {
        const destination = await getDataProvider().then((dp) => dp.getDestination(locationData.id));

        submitDestination(destination);
      } catch (e) {
        setToast(t('home.error.destination', 'Sorry, this destination is not currently available'), ToastType.Error);
      }
    };

    fetchDestination();
  }, [locationData.id, setToast, submitDestination, t]);

  return (
    <StyledInner.Card
      as="a"
      href="/"
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        onWidgetClick();
      }}
      useCoverStyling={isMobileLayout || useCoverStyling}
    >
      <StyledInner.Header
        size={TextSize.HeadingM}
        color={TextColor.White}
        className="position-top-left position-small position-z-index"
        tag="h3"
      >
        {cityName}
      </StyledInner.Header>
      {(isMobileLayout || useCoverStyling) && (
        <StyledInner.LocationImage src={locationData.image} className="width-expand" alt="" />
      )}
      {!isMobileLayout && !useCoverStyling && <Styled.LocationImg src={locationData.image} alt="" />}
    </StyledInner.Card>
  );
};

export default HomeLocationWidget;
