/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';
import arrow from 'assets/images/icons/ui-kit/arrow-down-red.svg';
import arrowDisabled from 'assets/images/icons/ui-kit/arrow-picker-disabled.svg';
import { rem } from 'style/functions';
import {
  activeDropdownPeoplePickerBackgroundColor,
  white,
  black,
  borderColor,
  borderColorLight,
  disabledBackgroundGray,
  modalBackgroundColor,
  gray,
  light,
  selectOptionColor,
} from 'style/variables';

const Button = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
`;

const ButtonPadding = styled.button`
  padding: 15px;
`;

const Dropdown = styled.ul`
  display: block;
  list-style-type: none;
  margin: 0;
  width: 100%;
  padding: 0;
  min-width: 0;

  li {
    padding: 5px 15px;
    transition: background-color 0.15s;
    cursor: pointer;

    &.active {
      background: ${activeDropdownPeoplePickerBackgroundColor};
    }
  }
`;

const Main = styled.div<{ $overflow?: boolean; offset?: number }>`
  position: absolute;
  width: 288px;
  ${({ $overflow, offset }) =>
    !$overflow &&
    css`
      border-radius: 2px;
      height: 100vh;
      max-height: calc(100vh - ${offset}px - 10px);
      overflow: visible auto;
    `}
`;

const MobileContainer = styled.div`
  margin: 0 16px;
  overflow-x: auto;
  background-color: ${light};
  color: ${black};
  border-radius: 0.28571rem;
  border: 1px solid ${borderColor};
  touch-action: manipulation;
`;

const Select = styled.select`
  min-width: 8.5rem;
  width: 8.5rem;
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  text-transform: none;
  max-width: 100%;
  border: 1px solid ${borderColorLight};
  padding: 0 10px;
  background: ${white};
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: 100% 50%;

  border-radius: ${rem(4)};
  color: ${black};

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    background-color: ${white};
    color: ${black};
    border-color: ${black};
  }

  &:disabled {
    background-color: ${modalBackgroundColor};
    color: ${gray};
    border-color: ${disabledBackgroundGray};
    background-image: url(${arrowDisabled});
  }

  option {
    color: ${selectOptionColor};
    &:active {
      background-color: ${white};
    }
  }
`;

export default {
  Main,
  Button,
  ButtonPadding,
  Dropdown,
  MobileContainer,
  Select,
};
