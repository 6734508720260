import { Place } from 'common/backend/api/place/placeModel';
import { useKeyNavigation } from 'common/hooks/useKeyNavigation';
import useKeyPress, { Keys } from 'common/hooks/useKeyPress';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import locationIcon from 'assets/images/icons/searchbar/gps-pin.svg';
import hotelIcon from 'assets/images/icons/searchbar/hotel.svg';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import Styled from 'components/searchForm/SuggestionList/SuggestionsList.styled';
import { env } from 'environments/environment';

export interface SuggestionsListProps {
  open: boolean;
  places?: Place[];
  onSelect: (place: Place) => void;
  onEnter: (place: Place, autoSelect: boolean) => void;
}

const getIcon = (suggestion: Place) => {
  if (suggestion.type === 'hotel') {
    return hotelIcon;
  }

  return locationIcon;
};

export const SuggestionList: React.FC<SuggestionsListProps> = ({ places, onSelect, open, onEnter }) => {
  const { t } = useTranslation();

  const additionalHint = useMemo(() => {
    if (places !== undefined && places.length === 0) {
      return t(
        'search-bar.destination-missing',
        'Destination not supported. We keep adding new destinations every day!',
      );
    }

    return undefined;
  }, [places, t]);

  const visiblePlaces = useMemo(() => places?.slice(0, env.searchBar.maxSuggestions) || [], [places]);
  const visible = useMemo(
    () => open && (visiblePlaces.length > 0 || !!additionalHint),
    [additionalHint, open, visiblePlaces.length],
  );

  const selectedIndex = useKeyNavigation(visiblePlaces, !visible);

  const handleEnter = useCallback(() => {
    if (selectedIndex !== undefined) {
      onEnter(visiblePlaces[selectedIndex], false);
    }

    if (visiblePlaces.length > 1) {
      onEnter(visiblePlaces[0], true);
    }
  }, [onEnter, selectedIndex, visiblePlaces]);

  useKeyPress([Keys.Enter], handleEnter, !visible);

  return (
    <Styled.Container id="destinationsList" hidden={!visible}>
      {visiblePlaces.length > 0 && (
        <Styled.List>
          {places?.map((place, index) => (
            <Styled.Item
              key={place.id}
              className="destinationsItem"
              selected={index === selectedIndex}
              onClick={() => onSelect(place)}
            >
              <Styled.Icon src={getIcon(place)} alt="" />
              <Styled.Description>
                <Text color={TextColor.Primary}>{place.name}</Text>
                <Text color={TextColor.Muted}>{place.label || ''}</Text>
              </Styled.Description>
            </Styled.Item>
          ))}
        </Styled.List>
      )}
      {additionalHint && (
        <Styled.List>
          <Styled.Item className="destinationsItem">
            <span>{additionalHint}</span>
          </Styled.Item>
        </Styled.List>
      )}
    </Styled.Container>
  );
};
