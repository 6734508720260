import { useSessionStorageWithObject } from 'common/hooks/useSessionStorage';
import React from 'react';
import { Trans } from 'react-i18next';
import closeIcon from 'assets/images/icons/close-icon.svg';
import rainbow from 'assets/images/icons/rainbow.svg';
import { SHOW_COVID } from 'backend/sessionStorageKeys';
import { CovidBannerStyled } from 'components/widgets/CovidBanner.styled';
import { env } from 'environments/environment';
import { FAQ_ROUTE } from 'routeList';

export const CovidBanner = () => {
  const [showCovid, setShowCovid] = useSessionStorageWithObject(SHOW_COVID, env.misc.showCovid);

  return showCovid ? (
    <CovidBannerStyled.Content>
      <CovidBannerStyled.Image src={rainbow} alt="rainbox" />
      <div>
        <Trans i18nKey="common.menu.covid-header">
          Important information about{' '}
          <CovidBannerStyled.Link href={FAQ_ROUTE}>COVID-19 (coronavirus)</CovidBannerStyled.Link> and your travel plans
        </Trans>
      </div>
      <CovidBannerStyled.Button type="button" onClick={() => setShowCovid(false)}>
        <img src={closeIcon} alt="close" />
      </CovidBannerStyled.Button>
    </CovidBannerStyled.Content>
  ) : null;
};
