import useValidationCallback from 'common/hooks/useValidationCallback';
import { emailValidationRule } from 'common/utils/validation/emailValidationRule';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useCampaign } from 'atoms/hooks/useCampaign';
import { useSession } from 'atoms/hooks/useSession';
import { SignUpRequest } from 'backend/api/user/userRequest';
import { InputCheckbox } from 'components/InputCheckbox';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import {
  InputField,
  InputFieldRef,
  passwordErrorMessage,
  passwordValidationRule,
} from 'components/common/InputField/InputField';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import StyledInner from 'components/signin/SignupForm/SignupForm.styled';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import useSignUpErrors from 'errors/useSignUpErrors';
import { PRIVACY_ROUTE, TERMS_ROUTE } from 'routeList';
import guid from 'utils/guid';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';

export const SignupForm: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [t] = useTranslation();
  const firstNameRef = useRef<InputFieldRef>();
  const lastNameRef = useRef<InputFieldRef>();
  const emailRef = useRef<InputFieldRef>();
  const passwordRef = useRef<InputFieldRef>();
  const repeatPasswordRef = useRef<InputFieldRef>();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [agreeMarketing, setAgreeMarketing] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const [acceptPolicyError, setAcceptPolicyError] = useState<string>();
  const [acceptTermsError, setAcceptTermsError] = useState<string>();
  const [emailBusy, setEmailBusy] = useState(false);

  const [working, setWorking] = useState(false);

  const setToast = useContext(ToastContext);
  const { signUp } = useSession();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { campaign, origin: campaignOrigin } = useCampaign();

  const repeatPasswordValidationRule = useCallback((p: string) => p === password, [password]);

  const emailValidator = useCallback(
    (email2validate: string) => {
      if (!emailValidationRule(email2validate)) {
        return false;
      }

      return !emailBusy;
    },
    [emailBusy],
  );

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setEmailBusy(false);
      setEmail(changedEmail);
    },
    [setEmailBusy, setEmail],
  );

  const { invalidate, validation } = useValidationCallback({
    fields: [firstNameRef, lastNameRef, emailRef, passwordRef, repeatPasswordRef],
  });

  const customValidation = useCallback(() => {
    const toReturn = validation(true);

    if (!agreePrivacy) {
      const message = t('login-popup.accept-policy-error', 'Please accept our policy');

      setAcceptPolicyError(message);
      toReturn.push(message);
    }

    if (!agreeTerms) {
      const message = t('login-popup.accept-terms-error', 'Please accept our terms and conditions');

      setAcceptTermsError(message);
      toReturn.push(message);
    }

    return toReturn;
  }, [validation, agreePrivacy, agreeTerms, t]);

  const recordAlreadyExistErrorCallback = useCallback(() => {
    setEmailBusy(true);
    invalidate(emailRef, true);
  }, [invalidate]);
  const errors = useSignUpErrors(recordAlreadyExistErrorCallback);

  const signUpBtnText = useMemo(() => t('login-popup.signup-button', 'Sign Up'), [t]);

  const register = useCallback(() => {
    const errorMessages = customValidation();

    if (errorMessages.length > 0) {
      processError(new ClientError(ClientErrorCode.ValidationError, errorMessages), errors);

      return;
    }

    setWorking(true);
    const payload: SignUpRequest = {
      username: email,
      password,
      recaptcha: guid(), // TODO
      firstName,
      lastName,
      agreeTerms,
      agreePrivacy,
      agreeMarketing,
      originCampaign: campaignOrigin ? campaign?.name : undefined,
    };

    signUp(payload)
      .then(() => {
        setSignInDialogMode(undefined);
        setToast(t('login-popup.signed-up', 'You have successfully signed up!'), ToastType.Success);
        AmplitudeLogging.pushAccountCreatedEvent('email');
      })
      .catch((reason) => {
        processError(reason, errors);
        setWorking(false);
      });
  }, [
    customValidation,
    email,
    password,
    firstName,
    lastName,
    agreeTerms,
    agreePrivacy,
    agreeMarketing,
    campaignOrigin,
    campaign,
    signUp,
    errors,
    setSignInDialogMode,
    setToast,
    t,
  ]);

  const title = useMemo(() => t('login-popup.signup-email', 'Sign up with your email'), [t]);

  return (
    <ModalBody>
      <ModalCloseButton onClick={() => setSignInDialogMode(undefined)} />

      <Text
        weight={TextWeight.SemiBold}
        alignment={TextAlignment.Center}
        size={TextSize.HeadingL}
        className="margin-remove-top"
        tag="div"
      >
        {title}
      </Text>

      <InputField
        id={'id-first-name'}
        ref={firstNameRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.first-name', 'First Name')}
        required={true}
        inputType={'text'}
        value={firstName}
        onChange={setFirstName}
        allowHotJarRecording={true}
        autocomplete={'given-name'}
        doBasicValidation={true}
      />
      <InputField
        id={'id-last-name'}
        ref={lastNameRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.last-name', 'Last Name')}
        required={true}
        inputType={'text'}
        value={lastName}
        onChange={setLastName}
        allowHotJarRecording={true}
        autocomplete={'family-name'}
        doBasicValidation={true}
      />
      <InputField
        id={'id-signin-email'}
        ref={emailRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.email', 'Email Address')}
        required={true}
        inputType={'text'}
        inputMode="email"
        value={email}
        onChange={emailChanged}
        errorMessage={
          emailBusy
            ? t('login-popup.signup-email-taken', 'This email is already registered')
            : t('login-popup.invalid-email', 'Invalid email')
        }
        autocomplete={'email'}
        doBasicValidation={false}
        validationRule={emailValidator}
        allowHotJarRecording={true}
      />
      <InputField
        id={'id-signin-password'}
        ref={passwordRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.password', 'Password')}
        required={true}
        inputType={'password'}
        value={password}
        onChange={setPassword}
        errorMessage={passwordErrorMessage(t)}
        autocomplete={'off'}
        doBasicValidation={false}
        validationRule={passwordValidationRule}
        allowHotJarRecording={false}
      />
      <InputField
        id={'id-signin-repeat-password'}
        ref={repeatPasswordRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.repeat-password', 'Repeat password')}
        required={true}
        inputType={'password'}
        value={repeatPassword}
        onChange={setRepeatPassword}
        errorMessage={t('login-popup.passwords-do-not-match', 'Passwords do not match')}
        autocomplete={'off'}
        doBasicValidation={false}
        validationRule={repeatPasswordValidationRule}
        allowHotJarRecording={false}
      />

      <div className="flex-left width-expand margin-large-top">
        <InputCheckbox value={agreeMarketing} setValue={setAgreeMarketing} emphasized>
          <Trans i18nKey="common.misc.subscription">
            I agree to receive promotions from Holisto Ltd and its associated brands
          </Trans>
        </InputCheckbox>

        <InputCheckbox
          emphasized
          containerClassName="margin-medium-top"
          value={agreePrivacy}
          setValue={(e) => {
            setAcceptPolicyError(undefined);
            setAgreePrivacy(e);
          }}
          error={acceptPolicyError ? t('login-popup.accept-policy-error', 'Please accept our policy') : undefined}
        >
          <span>
            <Trans i18nKey="login-popup.accept-policy">
              I accept the &nbsp;
              <StyledInner.AcceptPolicy href={PRIVACY_ROUTE} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </StyledInner.AcceptPolicy>
            </Trans>
          </span>
        </InputCheckbox>

        <InputCheckbox
          emphasized
          containerClassName="margin-medium-top"
          value={agreeTerms}
          setValue={(e) => {
            setAcceptTermsError(undefined);
            setAgreeTerms(e);
          }}
          error={acceptTermsError}
        >
          <span>
            <Trans i18nKey="login-popup.accept-terms">
              I accept the &nbsp;
              <StyledInner.AcceptTerms href={TERMS_ROUTE} target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </StyledInner.AcceptTerms>
            </Trans>
          </span>
        </InputCheckbox>
      </div>

      <Button styleType={ButtonType.Primary} className="margin-large-top" onClick={register} disabled={working}>
        <Text size={TextSize.Large} weight={TextWeight.SemiBold}>
          {signUpBtnText}
        </Text>
      </Button>

      <Text alignment={TextAlignment.Center} tag="div">
        {children}
      </Text>
    </ModalBody>
  );
};
