/* eslint-disable no-magic-numbers */
import styled from 'styled-components';
import StyledTextInput from 'components/common/InputField/InputField.styled';
import TelInputStyled from 'components/common/UnionTelInput/UnionTelInput.styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledTextInput.Input} {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  ${TelInputStyled.TextInputWrapper} > span {
    top: 18px;
  }
`;

const TopItem = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const Styled = {
  Container,
  TopItem,
};

export default Styled;
