/**
 * @category Views
 * @packageDocumentation
 */
import React from 'react';
import MobilePageHeader from 'components/layouts/mobile/MobilePageHeader';
import { BurgerMenu } from 'components/mobile/offscreen/BurgerMenu/BurgerMenu';
import MobileOffscreen from 'components/mobile/offscreen/MobileOffscreen';

const OffscreenBurgerMenu = () => (
  <MobileOffscreen>
    <MobilePageHeader />
    <BurgerMenu />
  </MobileOffscreen>
);

export default OffscreenBurgerMenu;
