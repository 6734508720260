import React from 'react';
import { useTranslation } from 'react-i18next';
import wta from 'assets/images/icons/wta.png';
import { CardTypes } from 'components/common/Card/types';
import { Text } from 'components/common/Text/Text';
import { TextSize } from 'components/common/Text/Text.types';
import Styled from 'components/home/LeadingHotelPanel/LeadingHotelPanel.styled';

export const LeadingHotelPanel = () => {
  const [t] = useTranslation();

  return (
    <Styled.Card type={CardTypes.Main} className="flex flex-center flex-column padding-small">
      <div>
        <img className="margin-medium-right" src={wta} alt="wta" />
        <img src={wta} alt="wta" />
      </div>

      <Text size={TextSize.HeadingS} className="margin-small-top margin-small-bottom" tag="h3">
        {t('home.awards.awards', 'World Travels Awards')}
      </Text>
      <Text size={TextSize.Small} tag="div">
        {t('home.awards.leading', "World's Leading hotel booking site")}
      </Text>
    </Styled.Card>
  );
};
