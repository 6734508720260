import React from 'react';

export const placeholderAddress = { maxWidth: '22.5rem', marginBottom: '0.21rem' };
export const placeholderDealTitle = { maxWidth: '22.5rem', height: '2rem', marginBottom: '0.21rem' };
export const placeholderTitle = { maxWidth: '35rem', height: '2rem' };
export const placeholderOverview = { height: '12.2rem' };
export const placeholderDealsSummary = { height: '6.3rem' };
export const placeholderDealPanel = { width: '100%', height: '7.14rem' };
export const dealImage = { maxWidth: '24rem', height: '20rem', width: '100%' };
export const defaultContainer = { width: '100%', height: '100%' };
export const checkoutHotelInfoHeader = { width: '15rem', height: '2rem' };
export const checkoutHotelInfoText = { width: '13.2rem' };
export const placeholderInput = { height: '55px', width: '100%', borderRadius: '5px', margin: 0 };
export const placeholderBookingDetails = { height: '500px', width: '100%' };
export const placeholderImage = { minHeight: 'inherit', margin: 0 };
export const placeholderCheckoutHotelDetails: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
