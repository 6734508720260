import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { CardTypes } from 'components/common/Card/types';
import CustomDatepickerStyle from 'components/datePicker/customDatepicker/CustomDatepicker.styles';
import { Month } from 'components/datePicker/customDatepicker/Month';

interface MobileDatepickerLayoutProps {
  displayedMonths: MonthType[];
  firstDayOfWeek: FirstDayOfWeek;
  setStartMonthRef: (startMonth: HTMLDivElement | undefined) => void;
  nights: number | undefined;
  isDatepickerSubmitDisabled: boolean;
  confirmOk: () => void;
  scrollRef: RefObject<HTMLDivElement>;
}

export const MobileDatepickerLayout = ({
  nights,
  displayedMonths,
  firstDayOfWeek,
  setStartMonthRef,
  isDatepickerSubmitDisabled,
  confirmOk,
  scrollRef,
}: MobileDatepickerLayoutProps) => {
  const { t } = useTranslation();

  return (
    <CustomDatepickerStyle.MobileScrollContainer>
      <CustomDatepickerStyle.MobileMonthContainer ref={scrollRef}>
        {displayedMonths.map((month) => (
          <div className="width-1-1" key={`${month.year}-${month.month}`}>
            <CustomDatepickerStyle.Month>
              <Month
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
                setStartMonthRef={setStartMonthRef}
              />
            </CustomDatepickerStyle.Month>
          </div>
        ))}
      </CustomDatepickerStyle.MobileMonthContainer>
      <CustomDatepickerStyle.MobileConfirmPanel>
        <CustomDatepickerStyle.Card
          type={CardTypes.Main}
          className="container margin-xsmall-top flex padding-small flex-center"
        >
          <Button
            id="datepickerSubmit"
            type="button"
            styleType={ButtonType.Primary}
            className="margin-small-right margin-small-left width-3-4"
            onClick={confirmOk}
            disabled={isDatepickerSubmitDisabled}
          >
            {t(
              'mobile-search.done-nights-plural',
              '{ nights, plural, =1 {Done (one night)} other {Done (# nights)} }',
              { nights: nights || 1 },
            )}
          </Button>
        </CustomDatepickerStyle.Card>
      </CustomDatepickerStyle.MobileConfirmPanel>
    </CustomDatepickerStyle.MobileScrollContainer>
  );
};
