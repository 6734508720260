import styled from 'styled-components';
import { borderColorLightest, veryLightGray } from 'style/variables';

const TopLineStyle = styled.div`
  background-color: ${veryLightGray};
  border-bottom: 1px solid ${borderColorLightest};
  z-index: 10;
  box-sizing: border-box;
`;

const TopLineRelativeContainer = styled.section`
  min-height: 78px;
`;

const TopLineStyleRelative = styled(TopLineStyle)`
  position: relative;
  border-radius: 8px;
`;

const FixedToplineDatepicker = styled.div`
  width: 22rem;
`;

const FixedSearchForm = styled.form<{ gap?: number }>`
  display: flex;
  flex-wrap: nowrap;
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
`;

const FixedWidthSearchfield = styled.div`
  width: 360px;
`;
const FixedWidthDatepicker = styled.div`
  width: 328px;
`;
const FixedWidthPeoplepicker = styled.div`
  width: 240px;
`;
const FixedWidthSearchButton = styled.div`
  width: 224px;
`;

export default {
  TopLineStyle,
  FixedToplineDatepicker,
  FixedSearchForm,
  FixedWidthSearchfield,
  FixedWidthDatepicker,
  FixedWidthPeoplepicker,
  FixedWidthSearchButton,
  TopLineStyleRelative,
  TopLineRelativeContainer,
};
