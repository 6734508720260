/* eslint-disable no-magic-numbers */
import styled from 'styled-components';

const LocationImg = styled.img`
  border-radius: 0.3rem;
`;

export default {
  LocationImg,
};
