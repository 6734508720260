/**
 * @category Search Form
 * @packageDocumentation
 */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import minusIcon from 'assets/images/icons/searchbar/feather-minus-square.svg';
import plusIcon from 'assets/images/icons/searchbar/feather-plus-square.svg';
import Styled from 'components/searchForm/PeoplePicker/PeoplePicker.styled';
import { env } from 'environments/environment';

export interface PeoplePickerPrp {
  adults: number;
  adultChanger: (increase: boolean) => void;
  childrenAges: number[];
  childrenChanger: (increase: boolean) => void;
  setAge: (index: number, age: string) => void;
}

/**
 * Allows to select a count of people.
 */

const PeoplePicker = ({ adults, adultChanger, childrenAges, childrenChanger, setAge }: PeoplePickerPrp) => {
  const [t] = useTranslation();

  return (
    <>
      <div className="flex flex-middle flex-between width-1-1">
        <div className="width-1-2">
          <Trans i18nKey="search-bar.adults">Adults</Trans>
        </div>
        <div className="width-1-2 flex flex-middle flex-between" style={{ maxWidth: '8rem' }}>
          <Styled.Button type="button" onClick={() => adultChanger(false)}>
            <img src={minusIcon} alt="adults minus" />
          </Styled.Button>
          <div>{adults}</div>
          <Styled.Button type="button" onClick={() => adultChanger(true)}>
            <img src={plusIcon} alt="adults plus" />
          </Styled.Button>
        </div>
      </div>

      <hr className="margin-medium-top margin-medium-bottom" />

      <div className="flex flex-middle flex-between width-1-1">
        <div className="width-1-2">
          <Trans i18nKey="search-bar.children">Children</Trans>
        </div>
        <div className="width-1-2 flex flex-middle flex-between" style={{ maxWidth: '8rem' }}>
          <Styled.Button tabIndex={0} type="button" onClick={() => childrenChanger(false)}>
            <img src={minusIcon} alt="children minus" />
          </Styled.Button>
          <div>{childrenAges.length}</div>
          <Styled.Button tabIndex={0} type="button" onClick={() => childrenChanger(true)}>
            <img src={plusIcon} alt="children plus" />
          </Styled.Button>
        </div>
      </div>

      {childrenAges.length > 0 && <hr className="margin-medium-top margin-medium-bottom" />}

      {
        /* eslint-disable react/no-array-index-key */
        childrenAges.map((age, index) => (
          <div key={index} className={`flex flex-middle${index > 0 ? ' margin-medium-top' : ''}`}>
            <div className="width-1-2 margin-small-right">
              <Trans i18nKey="search-bar.child-age" defaults="Child {num} Age" values={{ num: index + 1 }} />
            </div>
            <div className="width-1-2 position-relative flex flex-right">
              <Styled.Select
                value={age}
                onChange={(e) => {
                  setAge(index, e.target.value);
                }}
              >
                {
                  /* eslint-disable react/no-array-index-key */
                  [...Array(env.searchBar.maxChildAge - env.searchBar.minChildAge + 1)].map((_, j) => (
                    <option key={j + env.searchBar.minChildAge} value={env.searchBar.minChildAge + j}>
                      {t(
                        'search-bar.years-old',
                        '{ age, plural, =0 {<1 year old} =1 {1 year old} other {# years old} }',
                        {
                          age: env.searchBar.minChildAge + j,
                        },
                      )}
                    </option>
                  ))
                }
              </Styled.Select>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default PeoplePicker;
