import styled from 'styled-components';
import { brandColor } from 'style/variables';

const AcceptPolicyLink = styled.a`
  color: ${brandColor};
`;

const AcceptTermsLink = styled.a`
  color: ${brandColor};
`;

export default {
  AcceptPolicyLink,
  AcceptTermsLink,
};
