import React, { useCallback, useContext } from 'react';
import burger from 'assets/images/icons/burger.svg';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { PageHeaderLogo } from 'components/header-footer/PageHeaderLogo';
import { MobilePageHeaderStyled } from 'components/layouts/mobile/MobilePageHeader.styled';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';

const MobilePageHeader = () => {
  const { offscreenMode, setOffscreenMode } = useContext(OffscreenContext);
  const onBurgerClicked = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      if (offscreenMode === OffscreenMode.hidden) {
        setOffscreenMode(OffscreenMode.burger);
      } else {
        setOffscreenMode(OffscreenMode.hidden);
      }
    },
    [offscreenMode, setOffscreenMode],
  );

  return (
    <MobilePageHeaderStyled.Container>
      <MobilePageHeaderStyled.Header className="flex flex-middle flex-between width-1-1 padding-small padding-remove-vertical padding-remove-right">
        <PageHeaderLogo />
        <a data-testid="burger-button" id="burgerButton" href="/" className="padding-small" onClick={onBurgerClicked}>
          <img src={burger} alt="Menu" />
        </a>
      </MobilePageHeaderStyled.Header>
    </MobilePageHeaderStyled.Container>
  );
};

export default MobilePageHeader;
