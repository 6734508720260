/**
 * @category Search Form
 * @packageDocumentation
 */
import { useSticky } from 'common/hooks/useSticky';
import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { Text } from 'components/common/Text/Text';
import { TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { OffscreenContext } from 'components/contexts/OffscreenContext';
import { RouteDataContext } from 'components/contexts/RouteDataContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import { CustomDatepicker } from 'components/datePicker/customDatepicker/CustomDatepicker';
import ToplineStyles from 'components/header-footer/TopLineStyles';
import OffscreenMode from 'components/mobile/offscreen/OffscreenMode';
import { OccupancyPicker } from 'components/searchForm/OccupancyPicker/OccupancyPicker';
import { SearchFormStyled } from 'components/searchForm/SearchForm.styled';
import { SuggestionField } from 'components/searchForm/SuggestionField/SuggestionField';
import { SearchButtonLabels } from 'routeList';

export interface SearchFormPrp {
  onAfterSearch?: () => void;
  sticky?: boolean;
  isPinkSearchButton?: boolean;
  showOnlyDatepickerWidget?: boolean;
}

/**
 * Hotel search form
 * @param param {@link SearchFormProps}
 */
export const SearchForm = ({ onAfterSearch, sticky, isPinkSearchButton, showOnlyDatepickerWidget }: SearchFormPrp) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { offscreenMode } = useContext(OffscreenContext);
  const { searchButtonLabel } = useContext(RouteDataContext);
  const ref = useRef<HTMLDivElement>(null);
  const isSticky = useSticky(ref);

  const { submitForm } = useContext(SearchFormContext);

  const submitWithAfterSearch = useCallback(() => {
    hotjarEvent(HotjarEvent.SearchClicked);
    submitForm();
    if (onAfterSearch) {
      onAfterSearch();
    }
  }, [submitForm, onAfterSearch]);

  const searchButton = (
    <SearchFormStyled.SearchButtonWrap>
      <Button
        id="searchSubmitButton"
        styleType={isPinkSearchButton ? ButtonType.Primary : ButtonType.Outlined}
        tabIndex={5}
        onClick={submitWithAfterSearch}
        type="button"
      >
        <Text weight={TextWeight.Bold}>
          {(() => {
            switch (searchButtonLabel) {
              case SearchButtonLabels.updateSearch:
                return t('search-bar.update-search', 'Update Search');
              case SearchButtonLabels.search:
              default:
                return t('search-bar.search', 'Search');
            }
          })()}
        </Text>
      </Button>
    </SearchFormStyled.SearchButtonWrap>
  );

  if (isMobileLayout) {
    return (
      <SearchFormStyled.MobileForm onSubmit={(e) => e.preventDefault()}>
        {offscreenMode !== OffscreenMode.searchDatepicker && (
          <SearchFormStyled.MobileSuggestionField>
            <SuggestionField onEnter={submitWithAfterSearch} />
          </SearchFormStyled.MobileSuggestionField>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileDatePicker>
            <CustomDatepicker showOnlyWidget={showOnlyDatepickerWidget} />
          </SearchFormStyled.MobileDatePicker>
        )}
        {offscreenMode === OffscreenMode.searchDatepicker && (
          <SearchFormStyled.MobileDatePicker>
            <CustomDatepicker showOnlyWidget={showOnlyDatepickerWidget} />
          </SearchFormStyled.MobileDatePicker>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileOccupancyPicker>
            <OccupancyPicker overflow={!sticky} />
          </SearchFormStyled.MobileOccupancyPicker>
        )}
        {(offscreenMode === OffscreenMode.hidden || offscreenMode === OffscreenMode.search) && (
          <SearchFormStyled.MobileSearchButton>{searchButton}</SearchFormStyled.MobileSearchButton>
        )}
      </SearchFormStyled.MobileForm>
    );
  }

  if (sticky === false) {
    return (
      <ToplineStyles.TopLineStyleRelative className="padding-small">
        <ToplineStyles.FixedSearchForm onSubmit={(e) => e.preventDefault()}>
          <ToplineStyles.FixedWidthSearchfield>
            <SuggestionField onEnter={submitWithAfterSearch} />
          </ToplineStyles.FixedWidthSearchfield>
          <ToplineStyles.FixedWidthDatepicker>
            <CustomDatepicker />
          </ToplineStyles.FixedWidthDatepicker>
          <ToplineStyles.FixedWidthPeoplepicker>
            <OccupancyPicker overflow={!sticky} />
          </ToplineStyles.FixedWidthPeoplepicker>
          <ToplineStyles.FixedWidthSearchButton>{searchButton}</ToplineStyles.FixedWidthSearchButton>
        </ToplineStyles.FixedSearchForm>
      </ToplineStyles.TopLineStyleRelative>
    );
  }

  return (
    <ToplineStyles.TopLineRelativeContainer
      className="section padding-remove"
      style={{
        zIndex: 3,
      }}
    >
      <SearchFormStyled.Container ref={ref} isSticky={isSticky}>
        <ToplineStyles.TopLineStyle id="searchPanel" className="padding-small">
          <ToplineStyles.FixedSearchForm onSubmit={(e) => e.preventDefault()}>
            <ToplineStyles.FixedWidthSearchfield>
              <SuggestionField onEnter={submitWithAfterSearch} />
            </ToplineStyles.FixedWidthSearchfield>
            <ToplineStyles.FixedWidthDatepicker>
              <CustomDatepicker />
            </ToplineStyles.FixedWidthDatepicker>
            <ToplineStyles.FixedWidthPeoplepicker>
              <OccupancyPicker overflow={!sticky} />
            </ToplineStyles.FixedWidthPeoplepicker>
            <ToplineStyles.FixedWidthSearchButton>{searchButton}</ToplineStyles.FixedWidthSearchButton>
          </ToplineStyles.FixedSearchForm>
        </ToplineStyles.TopLineStyle>
      </SearchFormStyled.Container>
    </ToplineStyles.TopLineRelativeContainer>
  );
};
