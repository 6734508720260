import React, { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { routes, SearchButtonLabels } from 'routeList';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';

interface Context {
  title: string;
  pageType: string;
  searchButtonLabel: SearchButtonLabels;
  needSearchBar: boolean;
  staticSearchBar: boolean;
  hideCurrencySelector: boolean;
}

export const RouteDataContext = React.createContext<Context>({
  title: window.location.href,
  pageType: routes.home.pageType,
  searchButtonLabel: SearchButtonLabels.search,
  needSearchBar: false,
  staticSearchBar: false,
  hideCurrencySelector: false,
});

export const RouteDataProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const currentRouteData = useMemo(
    () => Object.values(routes).find((routeData) => !!matchPath(pathname, routeData)),
    [pathname],
  );

  const title = useMemo(() => currentRouteData?.title || window.location.href, [currentRouteData]);
  const pageType = useMemo(() => currentRouteData?.pageType || routes.home.pageType, [currentRouteData]);
  const searchButtonLabel = useMemo(
    () => currentRouteData?.searchButtonLabel || SearchButtonLabels.search,
    [currentRouteData],
  );
  const needSearchBar = useMemo(() => currentRouteData?.needSearchBar || false, [currentRouteData]);
  const staticSearchBar = useMemo(() => currentRouteData?.staticSearchBar || false, [currentRouteData]);
  const hideCurrencySelector = useMemo(() => currentRouteData?.hideCurrencySelector || false, [currentRouteData]);

  useEffect(() => {
    LogInfoMemoryStorage.pageType = pageType;
  }, [pageType]);

  return (
    <RouteDataContext.Provider
      value={{
        title,
        pageType,
        searchButtonLabel,
        needSearchBar,
        staticSearchBar,
        hideCurrencySelector,
      }}
    >
      {children}
    </RouteDataContext.Provider>
  );
};
