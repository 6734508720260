/**
 * @category Views
 * @packageDocumentation
 */
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MetaContext, SignInDialogContext } from 'TopContexts';
import mobileHeader from 'assets/images/MobileHeader.jpg';
import { CardTypes } from 'components/common/Card/types';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextColor, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { LayoutContext } from 'components/contexts/LayoutContext';
import DesktopHomeLocations from 'components/home/DesktopHomeLocations';
import { MobileHomeLocations } from 'components/home/MobileHomeLocations';
import { SearchForm } from 'components/searchForm/SearchForm';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS } from 'contexts/MetaProvider';
import Styled from 'style/styles';
import StyledInner from 'views/home/HomeView.styled';

const useHomeMetadata = () => {
  const [t] = useTranslation();
  const { setDescription, setKeywords } = useContext(MetaContext);

  useEffect(() => {
    setDescription(
      t(
        'metadata.home.description',
        'We create unbeatable deals you will not get anywhere else - so you can save your money for an unforgettable travel experience.',
      ),
    );
    setKeywords([
      t('metadata.home.keyword.hotel', 'hotel'),
      t('metadata.home.keyword.hotel-deals', 'hotel deals'),
      t('metadata.home.keyword.hotel-rooms', 'hotel rooms'),
      t('metadata.home.keyword.hotels', 'hotels'),
      t('metadata.home.keyword.lodging', 'lodging'),
      t('metadata.home.keyword.accommodation', 'accommodation'),
      t('metadata.home.keyword.special-deals', 'special deals'),
      t('metadata.home.keyword.weekend-breaks', 'weekend breaks'),
      t('metadata.home.keyword.city-breaks', 'city breaks'),
      t('metadata.home.keyword.deals', 'deals'),
      t('metadata.home.keyword.budget', 'budget'),
      t('metadata.home.keyword.cheap', 'cheap'),
      t('metadata.home.keyword.discount', 'discount'),
      t('metadata.home.keyword.savings', 'savings'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
      t('metadata.home.keyword.near-me-hotel', 'near me hotel'),
      t('metadata.home.keyword.bookings', 'bookings'),
      t('metadata.home.keyword.reservations', 'reservations'),
      t('metadata.home.keyword.hotels-near', 'hotels near'),
    ]);

    return () => {
      setDescription(DEFAULT_DESCRIPTION);
      setKeywords(DEFAULT_KEYWORDS);
    };
  }, [setDescription, setKeywords, t]);
};

export interface HomeViewPrp {
  resetPassword?: boolean;
}

/**
 * Home page
 *
 */
const HomeView = ({ resetPassword }: HomeViewPrp) => {
  const [t] = useTranslation();
  const { isMobileLayout } = useContext(LayoutContext);
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  useHomeMetadata();

  useEffect(() => {
    if (resetPassword) {
      setSignInDialogMode(SignInDialogMode.ResetPassword);
    }
  }, [resetPassword, setSignInDialogMode]);

  return (
    <div>
      {isMobileLayout && (
        <StyledInner.Hero isLight className="flex flex-column flex-center">
          <div className="container">
            <Text
              alignment={TextAlignment.Center}
              id="homeTitle"
              size={TextSize.HeadingM}
              color={TextColor.White}
              className="position-z-index position-relative"
              tag="h1"
            >
              {t('home.main.caption-1', 'Stop spending so much on hotel stays')}
            </Text>
          </div>

          <StyledInner.MobileHeader src={mobileHeader} alt="" />
        </StyledInner.Hero>
      )}

      {!isMobileLayout && (
        <StyledInner.Hero className="flex flex-column flex-center">
          <StyledInner.Greeting>
            <Text
              color={TextColor.Primary}
              alignment={TextAlignment.Center}
              size={TextSize.HeadingL}
              weight={TextWeight.Bold}
              className="margin-small-bottom"
              tag="h1"
            >
              {t('home.main.caption-1', 'Stop spending so much on hotel stays')}
            </Text>
            <StyledInner.Caption2
              size={TextSize.Large}
              alignment={TextAlignment.Center}
              className="margin-auto margin-small-bottom"
            >
              <Trans i18nKey={'home.main.caption-2'}>
                We <strong>CREATE</strong> unbeatable deals you will no get anywhere else - so you can save your money
                for an unforgettable travel experience
              </Trans>
            </StyledInner.Caption2>

            <SearchForm sticky={false} isPinkSearchButton={true} />
          </StyledInner.Greeting>
        </StyledInner.Hero>
      )}

      {isMobileLayout && (
        <Styled.HomeMobileSearch type={CardTypes.Main}>
          <SearchForm sticky={false} isPinkSearchButton={true} showOnlyDatepickerWidget={true} />
        </Styled.HomeMobileSearch>
      )}

      {!isMobileLayout && <DesktopHomeLocations />}
      {isMobileLayout && <MobileHomeLocations />}
    </div>
  );
};

export default HomeView;
