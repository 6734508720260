import React, { useContext } from 'react';
import { MetaContext } from 'TopContexts';
import loaderLogo from 'assets/images/logo/loader-logo.svg';
import loader from 'assets/images/logo/loader.gif';
import Styled from 'components/LoadingScreen/LoadingScreen.styled';
import { env } from 'environments/environment';

interface LoadingScreenProps {
  fullscreen?: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ fullscreen }) => {
  const { isGoogleCrawler } = useContext(MetaContext);

  const loading = (
    <div className="flex flex-column flex-middle align-center margin-auto-vertical">
      <img src={loaderLogo} alt="Logo" />
      <img className="flex width-expand" src={loader} alt="Loading..." />
    </div>
  );

  if (fullscreen) {
    if ((isGoogleCrawler && env.disableLoader.googleCrawler) || (!isGoogleCrawler && env.disableLoader.users)) {
      return null;
    }

    return <Styled.Wrapper>{loading}</Styled.Wrapper>;
  }

  return loading;
};

export default LoadingScreen;
