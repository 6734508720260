import React, { PropsWithChildren } from 'react';
import { BurgerMenuElementStyled } from 'components/mobile/offscreen/BurgerMenuElement.styled';

interface PageHeaderMobileElementProps extends PropsWithChildren<Record<string, unknown>> {
  icon: string;
}

const BurgerMenuElement = ({ icon, children }: PageHeaderMobileElementProps) => (
  <li className="flex">
    <BurgerMenuElementStyled.IconWrapper className="margin-medium-right">
      <img src={icon} alt="" />
    </BurgerMenuElementStyled.IconWrapper>
    {children}
  </li>
);

export default BurgerMenuElement;
