import useCookie from 'common/hooks/useCookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cookieIcon from 'assets/images/icons/cookie.svg';
import { SHOW_COOKIES_BANNER } from 'backend/localStorageKeys';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { Text } from 'components/common/Text/Text';
import { TextColor } from 'components/common/Text/Text.types';
import Styled from 'components/layouts/CookiePanel/CookiePanel.styled';

const BANNER_COOKIE_LIFETIME_DAYS = 365;

export const CookiePanel = () => {
  const [t] = useTranslation();
  const { cookie, updateCookie } = useCookie(SHOW_COOKIES_BANNER, 'none');

  return (
    <>
      {cookie === 'none' && (
        <Styled.Cookie>
          <img className="margin-medium-right" src={cookieIcon} alt="cookie" />
          <div>
            {t(
              'common.misc.cookie-panel',
              'We use cookies on our site, to ensure you will get the best experience, click accept to continue',
            )}
          </div>
          <Button
            styleType={ButtonType.Outlined}
            id="cookieAccept"
            onClick={() => updateCookie(SHOW_COOKIES_BANNER, BANNER_COOKIE_LIFETIME_DAYS)}
          >
            <Text color={TextColor.Primary}>{t('common.misc.accept', 'Accept')}</Text>
          </Button>
        </Styled.Cookie>
      )}
    </>
  );
};
