import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SignInDialogContext } from 'TopContexts';
import { ServerErrorCode } from 'backend/serverError';
import SignInDialogMode from 'components/signin/SignInDialogMode';
import { ModalAlertContext } from 'contexts/ModalAlertProvider';
import { AvailableErrors } from 'errors/errorData';
import { showError } from 'errors/errorHandlers';

const useAuthenticatorErrors = (): AvailableErrors => {
  const { t } = useTranslation();
  const { setSignInDialogMode } = useContext(SignInDialogContext);
  const { setAlert } = useContext(ModalAlertContext);

  const showErrorAndLogin = useCallback(
    (message: string) => setAlert(message, () => setSignInDialogMode(SignInDialogMode.SignIn)),
    [setAlert, setSignInDialogMode],
  );

  return useMemo(
    () => ({
      known: [
        {
          serverCodes: [ServerErrorCode.InvalidCredentials, ServerErrorCode.Unauthorized],
          action: () => {
            showErrorAndLogin(
              t('login-popup.authenticator.error', 'You are no longer logged in, please log in and try again'),
            );
          },
        },
        {
          serverCodes: [ServerErrorCode.ItemNotFound],
          action: () => {
            showErrorAndLogin(t('errors.user-not-found', 'User not found, please log in and try again'));
          },
        },
      ],
      default: {
        action: (errorId, status) =>
          showError(
            errorId,
            status,
            t('login-popup.authenticator.unknown', 'An error has occurred. Please try again later.'),
          ),
      },
    }),
    [showErrorAndLogin, t],
  );
};

export default useAuthenticatorErrors;
