import useValidationCallback from 'common/hooks/useValidationCallback';
import { emailValidationRule } from 'common/utils/validation/emailValidationRule';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SignInDialogContext, ToastContext } from 'TopContexts';
import { useSession } from 'atoms/hooks/useSession';
import { Button } from 'components/common/Button/Button';
import { ButtonType } from 'components/common/Button/Button.types';
import { InputField, InputFieldRef } from 'components/common/InputField/InputField';
import { Text } from 'components/common/Text/Text';
import { TextAlignment, TextSize, TextWeight } from 'components/common/Text/Text.types';
import { ToastType } from 'components/common/Toast/Toast.types';
import { ModalCloseButton } from 'components/common/modal/ModalCloseButton';
import { ModalBody } from 'components/common/modal/ModalSections.styled';
import { processError } from 'errors/errorUtils';
import useRecoveryPasswordErrors from 'errors/useRecoveryPasswordErrors';

export interface RecoverPasswordFormProps {
  initialEmail: string;
  children?: React.ReactNode;
}

export const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({ initialEmail, children }) => {
  const [t] = useTranslation();
  const emailRef = useRef<InputFieldRef>();
  const errors = useRecoveryPasswordErrors();

  const [email, setEmail] = useState<string>(initialEmail);

  const [working, setWorking] = useState(false);

  const { validation } = useValidationCallback({ fields: [emailRef] });

  const setToast = useContext(ToastContext);
  const { forgotPassword } = useSession();
  const { setSignInDialogMode } = useContext(SignInDialogContext);

  const emailChanged = useCallback(
    (changedEmail: string) => {
      setEmail(changedEmail);
    },
    [setEmail],
  );

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail, setEmail]);

  const passwordRecovery = useCallback(() => {
    if (validation(true).length > 0) {
      return;
    }
    setWorking(true);

    forgotPassword(email)
      .then(() => {
        setSignInDialogMode(undefined);
        setToast(
          t('login-popup.recovery-email-sent', 'Please check your email for further instructions'),
          ToastType.Info,
        );
      })
      .catch((reason) => {
        setWorking(false);
        processError(reason, errors);
      });
  }, [validation, forgotPassword, email, setSignInDialogMode, setToast, t, errors]);

  return (
    <ModalBody>
      <ModalCloseButton onClick={() => setSignInDialogMode(undefined)} />

      <Text
        weight={TextWeight.SemiBold}
        alignment={TextAlignment.Center}
        size={TextSize.HeadingL}
        className="margin-remove-top"
        tag="div"
      >
        <Trans i18nKey="login-popup.reset-title">Recover Your Password</Trans>
      </Text>

      <InputField
        id={'id-signin-email'}
        ref={emailRef}
        containerStyle="margin-top"
        placeholder={t('login-popup.email', 'Email Address')}
        required={true}
        inputType={'text'}
        value={email}
        inputMode="email"
        onChange={emailChanged}
        errorMessage={t('login-popup.invalid-email', 'Invalid email')}
        autocomplete={'email'}
        doBasicValidation={true}
        validationRule={emailValidationRule}
        onEnter={passwordRecovery}
        allowHotJarRecording={true}
      />

      <Button styleType={ButtonType.Primary} className="margin-large-top" onClick={passwordRecovery} disabled={working}>
        <Text size={TextSize.Large} weight={TextWeight.SemiBold}>
          <Trans i18nKey="login-popup.recovery-button">Reset password</Trans>
        </Text>
      </Button>

      {children}
    </ModalBody>
  );
};
