import React from 'react';
import Styled from 'components/common/Overlay/Overlay.styled';
import { OverlayTypes } from 'components/common/Overlay/constants';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: OverlayTypes;
}
export const Overlay: React.FC<OverlayProps> = (props) => {
  return <Styled.OverlayStyled {...props} />;
};
