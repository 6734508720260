import styled from 'styled-components';
import { rem } from 'style/functions';
import { black } from 'style/variables';

const fontSize = 12;

const Label = styled.label`
  color: ${black};
  font-size: ${rem(fontSize)};
`;

export default {
  Label,
};
